import { useState, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import {
  AccountErrorCode,
  Address,
  AddressTag,
  AddressTypeEnum,
  CountryCode,
  CreateUserAddressMutation,
  CreateUserAddressMutationVariables,
  UpdateUserAddressMutation,
  useAuth,
} from 'teddly-sdk';
import tw from 'twin.macro';
import { AddressInput } from '@app/user/address';
import StateSelectDropdown from './StateSelectDropdown';
import style from './style.module.scss';
import AutocompleteAddress from './Address/AutocompleteAddress';
import { getDefaultIphone } from '@utils';
import { ids } from '../../../../../cypress/pages/account-setting/AddressFormPage.cy';
import LightbulbIcon from 'public/assets/lightbulb-icon.svg';

import {
  Dialog,
  Button,
  ButtonVariantEnum,
  InputBase,
  Switch,
  Dropdown,
  Chip,
  Avatar,
  AvatarSizeEnum,
} from '@teddly/teddly-ui-components';

import styles from '@styles/form.module.scss';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from '@config/validation';
import {
  UserAddressType,
  useUserAddressesContext,
} from '@context/UserAddressContext';
import classNames from 'classnames';

type ShippingAddressDialogProps = {
  shippingAddress?: UserAddressType;
  isOpen?: boolean;
  onClose?: () => void;
  onSubmit?: (
    address: CreateUserAddressMutationVariables['input'],
    isDefault?: boolean,
    id?: string,
  ) => Promise<UpdateUserAddressMutation | CreateUserAddressMutation | null>;
  loading: boolean;
  backendErrors?: any;
};

export default function ShippingAddressForm({
  shippingAddress,
  isOpen = false,
  onClose = () => null,
  onSubmit = () => null,
  loading,
  backendErrors,
}: ShippingAddressDialogProps) {
  const isNewAddress = !shippingAddress?.id;
  const text = isNewAddress ? 'Add' : 'Edit';
  const [fieldError, setFieldError] = useState(null);
  const { user } = useAuth();
  const {
    refetchAddress,
    availableAddressTags,
    loaded: addressesLoading,
    defaultShippingAddress,
  } = useUserAddressesContext();

  const isEdit: boolean = !!shippingAddress?.id;

  const shippingAddressForEdit: UserAddressType | null = isEdit
    ? shippingAddress
    : null;

  const getInitialTag = () => {
    return (
      shippingAddressForEdit?.tag?.id ||
      (availableAddressTags?.length > 0 ? availableAddressTags[0].id : null)
    );
  };

  const emptyAddressState: AddressInput = {
    firstName: '',
    lastName: '',
    phone: '',
    city: '',
    cityArea: '',
    streetAddress1: '',
    postalCode: '',
    streetAddress2: '',
    country: CountryCode.US,
    countryArea: 'NY',
    note: '',
    tag: getInitialTag(),
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    control,
    formState: { errors, isValid },
  } = useForm<AddressInput>({ mode: 'onChange' });

  const [resultsErrors, setResultsErrors] = useState<
    { field: string; message: string; code: AccountErrorCode }[]
  >([]);

  const [isDefault, setIsDefault] = useState<boolean>(
    shippingAddress?.id === defaultShippingAddress?.id ? true : false,
  );

  const [countryArea, setCountryArea] = useState(
    shippingAddress?.countryArea || emptyAddressState.countryArea,
  );
  const onSelectAddress = (selectAddressInput: AddressInput) => {
    setValue('postalCode', selectAddressInput.postalCode, {
      shouldValidate: true,
    });
    setValue('streetAddress1', selectAddressInput?.streetAddress1, {
      shouldValidate: true,
    });
    setValue('city', selectAddressInput.city, {
      shouldValidate: true,
    });
    setCountryArea(selectAddressInput.countryArea);
  };

  useEffect(() => {
    const error = {};
    if (backendErrors)
      backendErrors.forEach((item) => {
        error[item.field] = item.message;
      });
    setFieldError(error);
  }, [backendErrors]);

  const setInitialFormData = () => {
    if (!shippingAddress?.id) {
      reset(emptyAddressState);
    } else {
      const data: AddressInput = {
        ...shippingAddress,
        tag: (shippingAddress as Address)?.tag?.id,
        country: CountryCode[shippingAddressForEdit?.country?.code],
      };
      reset(data);
    }
    setIsDefault(
      shippingAddress?.id === defaultShippingAddress?.id ? true : false,
    );
    setCountryArea(shippingAddress?.countryArea);
  };

  useEffect(() => {
    setInitialFormData();
  }, [shippingAddress?.id, shippingAddress]);

  const handleClose = () => {
    if (!isEdit) {
      reset(emptyAddressState);
    }
    setResultsErrors([]);
    onClose();
  };

  const validatePostalCode = (value) => {
    if (value) {
      setFieldError('');
      return true;
    }
    return false;
  };

  const getResultsError = (field: string) => {
    return resultsErrors?.find((errorField) => errorField?.field === field);
  };

  const getIsResultsError = (field: string) => {
    return !!getResultsError(field);
  };

  const Submit = async (data: AddressInput) => {
    const response = await onSubmit(
      {
        firstName: data.firstName || shippingAddress?.firstName,
        lastName: data.lastName || shippingAddress?.lastName,
        phone: data.phone || shippingAddress?.phone,
        city: data.city || shippingAddress?.city,
        streetAddress1: data.streetAddress1 || shippingAddress?.streetAddress1,
        postalCode: data.postalCode || shippingAddress?.postalCode,
        streetAddress2: data.streetAddress2 || shippingAddress?.streetAddress2,
        country:
          CountryCode[shippingAddressForEdit?.country?.code] || CountryCode.US,
        countryArea,
        note: data.note,
        tag: data.tag,
      },
      isDefault,
      shippingAddress?.id,
    );
    let errors = [];
    if (isEdit) {
      errors =
        (response as UpdateUserAddressMutation)?.accountAddressUpdate?.errors ||
        [];
    } else {
      errors =
        (response as CreateUserAddressMutation)?.accountAddressCreate?.errors ||
        [];
    }
    if (errors?.length > 0) {
      setResultsErrors(errors);
    }
    if (backendErrors?.length) {
      reset(emptyAddressState);
    }
    refetchAddress();
  };

  const getFormSection = (section: JSX.Element, toLeft?: boolean) => (
    <div
      style={{ width: '100%', justifyContent: toLeft ? 'left' : 'center' }}
      css={{
        ...tw`gap-6 grid grid-cols-1 tablet:flex flex-wrap justify-center 	`,
      }}>
      {section}
    </div>
  );

  const notificationMessage = (
    <div className={style.notificationMessageContainer}>
      <LightbulbIcon className={style.notificationMessageIcon} /> You will
      receive order notifications and delivery status via SMS at this number
    </div>
  );

  const getTagIcon = (tag: AddressTag) => (
    <img src={tag?.icon?.url} alt={tag?.icon?.alt || tag?.name} />
  );

  const getTagById = (id: string) => {
    if (shippingAddressForEdit && id && shippingAddressForEdit?.tag?.id == id) {
      return shippingAddressForEdit.tag;
    }
    return availableAddressTags?.find((tag) => tag?.id == id);
  };

  const getSelectedTag = (): AddressTag => {
    const initialInputTagId = getValues('tag');
    const tag = getTagById(initialInputTagId);
    return tag;
  };

  const getAvailableTags = (): AddressTag[] => {
    const list = [...availableAddressTags];
    if (isEdit && shippingAddressForEdit?.tag) {
      if (shippingAddressForEdit?.tag?.isUnique) {
        list.unshift(shippingAddressForEdit?.tag);
      } else {
        list.splice(list.indexOf(shippingAddressForEdit?.tag), 1);
        list.unshift(shippingAddressForEdit?.tag);
      }
    }
    return list;
  };

  const getError = (errorMessage: string) => {
    if (errorMessage) {
      return <span style={{ color: '#ef2d2d' }}>{errorMessage}</span>;
    }
  };

  const isTheSelectedTag = (tag: AddressTag) =>
    getValues('tag') && tag?.id === getValues('tag');

  useEffect(() => {
    if (!addressesLoading && !getSelectedTag()) {
      setValue('tag', getInitialTag());
    }
  }, [addressesLoading]);

  // const availableTagsForSelect = getAvailableTags()?.filter(tag => tag?.id !== getValues("tag")) || []

  const addressTagDropdown = (
    <Controller
      name="tag"
      control={control}
      rules={{
        required: true,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Chip
          loading={!getSelectedTag()}
          label={getSelectedTag()?.name}
          className={style.addressTagChip}
          leadingIcon={getTagIcon(getSelectedTag())}
          disabled={!getAvailableTags() || getAvailableTags()?.length == 0}
          dropdownItems={getAvailableTags()?.map((tag) => (
            <Dropdown.Item
              key={tag.id}
              className={classNames(
                style.dropdownItem,
                isTheSelectedTag(tag) && style.selectedTagDropdownItem,
              )}
              value={tag.id}
              onClick={() =>
                onChange({
                  target: {
                    value: tag.id,
                  },
                })
              }
              // onClick={() => setSelectedTag(tag)}
              leadingComponent={
                <Avatar
                  iconComponent={getTagIcon(tag)}
                  size={AvatarSizeEnum.SMALL}
                  className={classNames(
                    style.tagAvatar,
                    isTheSelectedTag(tag) && style.selectedTagDropdownItem,
                  )}
                />
              }>
              <div
                className={classNames(
                  style.tagLine,
                  isTheSelectedTag(tag) && style.selectedTagDropdownItem,
                )}>
                {tag.name}
              </div>
            </Dropdown.Item>
          ))}
        />
      )}
    />
  );

  return (
    <form onSubmit={handleSubmit(Submit)}>
      <Dialog
        isOpen={isOpen}
        css={tw`z-40	`}
        title={`${text} Shipping Address`}
        onClose={handleClose}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        footerProps={{
          children: [
            <Button
              data-testid={ids.cancelButton}
              onClick={handleClose}
              title="cancel"
              variant={ButtonVariantEnum.OUTLINE}
            />,
            <Button
              data-testid={ids.submitButton}
              disabled={loading || !isValid || !countryArea}
              type="submit"
              loading={loading}
              onClick={handleSubmit(Submit)}
              title={`${text} Address`}
            />,
          ],
        }}>
        <Dialog.Body>
          <div className={styles.dialogBody}>
            <div
              style={{ maxWidth: '580px' }}
              css={{
                ...tw`gap-6 grid grid-cols-1 tablet:flex flex-wrap justify-center 	`,
              }}>
              {getFormSection(addressTagDropdown, true)}
              {getFormSection(
                <>
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      maxLength: {
                        value: FIRST_NAME_MAX_LENGTH,
                        message: `First name is more than ${FIRST_NAME_MAX_LENGTH} Characters`,
                      },
                      required: true,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        name="firstName"
                        fullWidth
                        label="First Name"
                        data-testid={ids.firstNameInput}
                        value={value}
                        onChange={onChange}
                        css={tw`w-[300px]	tablet:w-[220px] `}
                        defaultValue={shippingAddress?.firstName}
                        message={errors?.firstName?.message}
                        errored={errors?.firstName?.message}
                        footnote={error?.message}
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      maxLength: {
                        value: LAST_NAME_MAX_LENGTH,
                        message: `Last name is more then ${LAST_NAME_MAX_LENGTH} Characters`,
                      },
                      required: true,
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        fullWidth
                        name="lastName"
                        label="Last Name"
                        value={value}
                        data-testid={ids.lastNameInput}
                        onChange={onChange}
                        css={tw`w-[300px]	tablet:w-[220px] `}
                        defaultValue={shippingAddress?.lastName}
                        message={errors?.lastName?.message}
                        errored={errors?.lastName?.message}
                        footnote={error?.message}
                      />
                    )}
                  />
                </>,
              )}
              <PatternFormat
                format={'1(###) ###-####'}
                allowEmptyFormatting={true}
                mask="_"
                title="Phone"
                css={tw`w-[300px]	tablet:w-[255px] `}
                onValueChange={(valueObj) => {
                  const { value } = valueObj;
                  setValue('phone', value);
                }}
                style={{ width: '100%' }}
                defaultValue={getDefaultIphone(shippingAddress?.phone)}
                type="tel"
                data-testid={ids.phoneInput}
                fullWidth={true}
                // footnote={
                //   <div css={tw`w-[300px]	tablet:w-[255px] `}>
                //     This phone number will be used for all order notifications
                //     and delivery status, including items
                //     substituting options (SMS)
                //   </div>
                // }
                // message={errors?.phone?.message}
                // error={errors?.phone?.message}
                {...register('phone', {
                  required: 'Phone is required !',
                  pattern: {
                    value:
                      /\+?\d{1,9}?[-.\s]?\(?\d{1,9}?\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}[-.\s]?\d{1,9}/g,
                    message: 'Please enter valid Phone Number !',
                  },
                })}
                customInput={InputBase}
              />
              {notificationMessage}
              <InputBase
                label="Search for an address"
                fullWidth
                inputComponent={
                  <AutocompleteAddress
                    dataTestId={ids.searchAddressInput}
                    // css={tw`w-full max-w-[742px]`}
                    // className={style.autocomplete}
                    onSelectAddress={onSelectAddress}
                  />
                }
              />
              {getFormSection(
                <>
                  <Controller
                    name="streetAddress1"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        data-testid={ids.streetInput1}
                        disabled={true}
                        fullWidth
                        name="streetAddress1"
                        // css={tw`w-[300px]	tablet:w-[464px] `}
                        errored={!!error || getIsResultsError('streetAddress1')}
                        helperText={
                          error?.message || getIsResultsError('streetAddress1')
                        }
                        footnote={getError(
                          error?.message ||
                            getResultsError('streetAddress1')?.message,
                        )}
                        label="address"
                        value={value}
                        onChange={onChange}
                        type="text"
                      />
                    )}
                  />
                  <Controller
                    name="streetAddress2"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        // css={tw`w-[300px]	tablet:w-[260px] `}
                        fullWidth
                        label="Apt/Suite. (optional)"
                        value={value}
                        data-testid={ids.apartmentInput}
                        onChange={onChange}
                        errored={!!error}
                        helperText={error ? error.message : null}
                        type="text"
                      />
                    )}
                  />
                </>,
              )}
              {getFormSection(
                <>
                  <Controller
                    name="city"
                    control={control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        data-testid={ids.cityInput}
                        disabled
                        label="city"
                        fullWidth
                        // css={tw`w-[300px]	tablet:w-[250px] `}
                        variant="filled"
                        value={value}
                        onChange={onChange}
                        errored={!!error}
                        helperText={error ? error.message : null}
                        type="text"
                      />
                    )}
                  />
                  <Controller
                    name="postalCode"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <InputBase
                        data-testid={ids.zipCodeInput}
                        name="postalCode"
                        disabled={true}
                        fullWidth
                        // css={tw`w-[300px]	tablet:w-[250px] `}
                        label="Zip Code"
                        value={value}
                        onChange={onChange}
                        errored={!!error || getIsResultsError('postalCode')}
                        helperText={
                          error?.message || getIsResultsError('postalCode')
                        }
                        footnote={getError(
                          error?.message ||
                            getResultsError('postalCode')?.message,
                        )}
                      />
                    )}
                  />
                </>,
              )}
              <InputBase
                fullWidth
                // label="state"
                // placeholder={"state"}
                className={style.selectInputBase}
                inputWrapperClassName={style.selectInputWrapperClassName}
                disabled
                inputComponent={
                  <StateSelectDropdown
                    data-testid={ids.stateInput}
                    deleteSelectSign={true}
                    // disabled={true}
                    tw=""
                    value={countryArea}
                    onChange={(area) => {
                      setCountryArea(area);
                    }}
                  />
                }
              />
              <Controller
                name="note"
                control={control}
                defaultValue=""
                rules={{ required: false }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    name="note"
                    fullWidth
                    data-testid={ids.noteInput}
                    // css={tw`w-[300px]	tablet:w-[750px] `}
                    label="access code/note"
                    value={value}
                    onChange={onChange}
                    errored={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
              <div className={styles.line}>
                <Switch
                  style={{ backgroundColor: 'blue' }}
                  id="isDefaultShippingAddress"
                  data-testid={ids.makeDefaultToggle}
                  checked={isDefault}
                  label="Make Default"
                  onChange={() => setIsDefault(!isDefault)}
                />
              </div>
            </div>
          </div>
        </Dialog.Body>
      </Dialog>
    </form>
  );
}
