import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { IDialogIDs, IDialog } from '../../interfaces/IDialog.cy';
import TestObjectBase from '../TestObjectBase';

const {
  buttons: btn,
  namedElements: elm,
  componenets,
} = utilsData.elementsDataTestIDs;

export class DialogIDs implements IDialogIDs {
  headerID: string;
  titleID: string;
  bodyID: string;
  footerID: string;
  snackbarID: string;
  closeButtonID: string;
  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${componenets.dialogs.title}`;
    this.headerID = TestIDFormat.element(this.fullPrefix, elm.header);
    this.titleID = TestIDFormat.element(this.fullPrefix, elm.title);
    this.bodyID = TestIDFormat.element(this.fullPrefix, elm.body);
    this.footerID = TestIDFormat.element(this.fullPrefix, elm.footer);
    this.snackbarID = TestIDFormat.element(this.fullPrefix, elm.snackbar);
    this.closeButtonID = TestIDFormat.button(this.fullPrefix, btn.close);
  }
}

export class Dialog<T extends DialogIDs>
  extends TestObjectBase<T>
  implements IDialog
{
  constructor(DialogIDs) {
    super(DialogIDs);
  }

  get header() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.headerID));
  }

  get title() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.titleID));
  }

  get body() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.bodyID));
  }

  get footer() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.footerID));
  }

  get snackbar() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.snackbarID));
  }

  get closeButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.closeButtonID));
  }
}
