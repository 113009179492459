import { gql } from 'graphql-tag';

const fragment = `
fragment Price on TaxedMoney {
  gross {
    amount
    currency
    __typename
  }
  net {
    amount
    currency
    __typename
  }
  __typename
}

fragment ProductVariantFragment on ProductVariant {
  id
  name
  sku
  quantityAvailable
  isFastDelivery
  isInStock
  pricing {
    onSale
    priceUndiscounted {
      ...Price
      __typename
    }
    price {
      ...Price
      __typename
    }
    __typename
  }
  attributes {
    attribute {
      id
      name
      slug
      __typename
    }
    values {
      id
      name
      value: name
      __typename
    }
    __typename
  }
  product {
    id
    name
    slug
    thumbnail {
      url
      alt
      __typename
    }
    thumbnail2x: thumbnail(size: 510) {
      url
      __typename
    }
    productType {
      id
      isShippingRequired
      __typename
    }
    metadata {
      key
      value
      __typename
    }
    attributes {
      attribute {
        id
        name
        slug
        __typename
      }
      values {
        id
        name
        value: name
        __typename
      }
      __typename
    }
    __typename
  }
  __typename
}

fragment CheckoutLineFragment on CheckoutLine {
  id
  quantity
  note
  isInclude
  order {
    id
    number
  }
  totalPrice {
    ...Price
    __typename
  }
  variant {
    ...ProductVariantFragment
    __typename
  }
  __typename
}

fragment AddressFragment on Address {
  id
  firstName
  lastName
  companyName
  streetAddress1
  streetAddress2
  city
  postalCode
  country {
    code
    country
    __typename
  }
  countryArea
  phone
  isValidToShipping
  isDefaultBillingAddress
  isDefaultShippingAddress
  __typename
}

fragment ShippingMethodFragment on ShippingMethod {
  id
  name
  isFastDelivery
  price {
    currency
    amount
    __typename
  }
  __typename
}

fragment PaymentGatewayFragment on PaymentGateway {
  id
  name
  config {
    field
    value
    __typename
  }
  currencies
  __typename
}

fragment CheckoutFragment on Checkout {
  token
  id
  totalPrice {
    ...Price
    __typename
  }
  editingOrder {
    id
    number
  }
  subtotalPrice {
    ...Price
    __typename
  }
  additionalPrice {
    ...Price
    __typename
  }
  billingAddress {
    ...AddressFragment
    __typename
  }
  shippingAddress {
    ...AddressFragment
    __typename
  }
  email
  availableShippingMethods {
    ...ShippingMethodFragment
    __typename
  }
  shippingMethod {
    ...ShippingMethodFragment
    __typename
  }
  shippingPrice {
    ...Price
    __typename
  }
  lines {
    ...CheckoutLineFragment
    __typename
  }
  isShippingRequired
  discount {
    currency
    amount
    __typename
  }
  availableOrdersForEdit {
    order {
      id
      number
    }
    totalCount
  }
  discountName
  translatedDiscountName
  voucherCode
  availablePaymentGateways {
    ...PaymentGatewayFragment
    __typename
  }
  __typename
}

fragment CheckoutErrorFragment on CheckoutError {
  code
  field
  message
  __typename
}

`;

export const CREATE_CHECKOUT = gql`
  ${fragment}
  mutation CreateCheckout($checkoutInput: CheckoutCreateInput!) {
    checkoutCreate(input: $checkoutInput) {
      errors: checkoutErrors {
        ...CheckoutErrorFragment
        __typename
      }
      checkout {
        ...CheckoutFragment
        __typename
      }
      __typename
    }
  }
`;

export const UPDATE_CHECKOUT = gql`
  ${fragment}
  mutation UpdateCheckoutLines($checkoutId: ID!, $lines: [CheckoutLineInput]!) {
    checkoutLinesUpdate(checkoutId: $checkoutId, lines: $lines) {
      checkout {
        ...CheckoutFragment
      }
      checkoutErrors {
        ...CheckoutErrorFragment
      }
    }
  }
`;
// export const AVAILABLE_TIME_SLOT = gql`
//   query getAvailableTimeSlot($shippingMethodId: ID!,$channelId:ID!) {
//     avalableTimeslot(shippingMethodId:$shippingMethodId,channelId:$channelId) {
//       timeslots{
//         date
//         timeslotMethod{
//           id 
//           startHour
//           endHour
//           weekday 
//           durationMinutes
//         } 
//         lastTimeToOrder
//       }
//     acceptable
//     }
//   }
// `;
