import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAccountUpdate, useAuth } from 'teddly-sdk';
import { AccountUpdateVariables } from 'teddly-sdk/lib/mutations/gqlTypes/AccountUpdate';
import AgeRestrictionForm from '../../forms/AgeRestriction/AddBirthdateForm';
import { addZeroSuffix, getStyledDate } from '@utils';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useCheckoutPageContext } from '../../../../../context/CheckoutPageContext';
import { MIN_AGE_ALCOHOL } from '../../../../../config/validation/index';
import { UserInfoBlock, Button } from '@teddly/teddly-ui-components';

export default function AgeRestrictionStep({ title = null }) {
  const [birthdate, setBirthdate] = useState<string>();
  const [accountUpdate] = useAccountUpdate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isChange, setIsChange] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { setIsAgeValid, setErrorMessage: setCheckoutErrorMessage } =
    useCheckoutPageContext();
  const { user } = useAuth();
  const { checkout } = useCheckoutFlowContext();
  const isAlcoholWarning = checkout?.isAlcoholWarning === true ? true : false;

  const validateBirthdate = (date) => {
    const momentDate = moment(date, 'YYYY-MM-DD');
    if (momentDate.isAfter(moment())) {
      setErrorMessage('Cannot enter future birthdate.');
      setLoading(false);

      return false;
    }
    if (!momentDate.isValid()) {
      setErrorMessage('Please enter a valid birthdate.');
      setLoading(false);

      return false;
    }
    setErrorMessage(undefined);

    return true;
  };

  const handleSubmit = (event) => {
    try {
      setLoading(true);
      const date = `${event.year}-${addZeroSuffix(event.month.toString())}-${addZeroSuffix(event.day.toString())}`;
      if (!validateBirthdate(date.toString())) {
        setIsAgeValid(false);
        return;
      }
      const vars: AccountUpdateVariables = {
        input: {
          birthdate: moment.utc(date).toISOString().split('T')[0],
        },
      };
      accountUpdate(vars)
        .then(({ data }) => {
          if (data && data.user?.id) {
            setBirthdate(getStyledDate(data.user.birthdate));
            setLoading(false);
            setIsChange(false);
            setIsAgeValid(isValidAgeForAlcohol(data.user.birthdate));
          }
        })
        .catch((e) => {});
    } catch (e) {
      console.info('update birthdate exception', e);
    }
  };
  const isValidAgeForAlcohol = (date: Date) => {
    const age = getTheAge(date);
    const isAgeValid = age >= MIN_AGE_ALCOHOL;
    if (!isAgeValid) {
      setCheckoutErrorMessage(
        'It is not possible to buy products that contain alcohol under the age of : ' +
          MIN_AGE_ALCOHOL.toString(),
      );
    } else {
      setCheckoutErrorMessage('');
    }
    return isAgeValid;
  };
  const getTheAge = (date: Date) => {
    return moment().diff(date, 'years', false);
  };

  useEffect(() => {
    if (isAlcoholWarning && !birthdate) {
      setIsAgeValid(false);
    }
    if (!isAlcoholWarning) {
      setIsAgeValid(true);
    }
  }, [birthdate, isChange, isAlcoholWarning]);

  if (!isAlcoholWarning) {
    return <></>;
  }
  return birthdate && !isChange ? (
    <UserInfoBlock
      title={title}
      isWithShading
      description={moment(new Date(birthdate)).format('LL')}
      selected
      buttons={[
        <Button
          key={'change-birthdate'}
          title="Change"
          onClick={() => {
            setIsChange(true);
          }}
        />,
      ]}
    />
  ) : (
    <AgeRestrictionForm
      birthdate={user?.birthdate?.toString()}
      loading={isLoading}
      onSubmit={handleSubmit}
      error={errorMessage}
    />
  );
}
