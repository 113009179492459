import { useEffect } from 'react';

export default function useDisableScroll(trigger: boolean) {
  useEffect(() => {
    if (trigger) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [trigger]);
}
