import { usePageLayoutContext } from '@context/PageLayoutContext';
import { useChannelContext } from '@context/ChannelContext';
import { getRootCategoriesBaseFragments } from '@services/categories/api';
import { getServerAssetsImageUrl } from '@utils';
import { useRoutesContext } from './RoutesContext';
import React, { useState } from 'react';
import {
  HomePageSectionsQuery,
  HomePageStateType,
  ScrollableItemType,
  useAuth,
  useHomePageSections,
} from 'teddly-sdk';

const sectionsPerPage = 10;

type BodyFooterInformationPropsType = {
  navigationLists?: {
    title: string;
    children: { name: string; onClick: () => void }[];
  }[];
  appStoreIconUrl: string;
  googlePlayIconUrl: string;
  facebookIconUrl: string;
  whatsappIconUrl: string;
  instagramIconUrl: string;
  onAppStoreClick: () => void;
  onGooglePlayClick: () => void;
  onFacebookClick: () => void;
  onInstagramClick: () => void;
  onWhatsappClick: () => void;
  onHelpClick: () => void;
};

const HomePageContext = React.createContext<{
  bodyFooterInformationProps: BodyFooterInformationPropsType;
  isSearchFieldVisible: boolean;
  setIsSearchFieldVisible: (value: boolean) => void;
  stateType: HomePageStateType;
  setStateType: (newStateType: HomePageStateType) => void;
  data: HomePageSectionsQuery['homePageSections'];
  loading: boolean;
  loadMore: () => void;
  isFirstPageLoading: boolean;
}>(null);

export function HomePageProvider({ children }: { children: React.ReactNode }) {
  const { authenticated } = useAuth();
  const { pages, navigateTo, isCurrentPage } = useRoutesContext();
  const { selectedChannel, isChannelSelected } = useChannelContext();

  const {
    isLoading: isPageLoading,
    setLoading: setPageLoading,
  } = usePageLayoutContext();
  const [isSearchFieldVisible, setIsSearchFieldVisible] =
    React.useState<boolean>(true);
  const [stateType, setStateType] = React.useState<HomePageStateType>(
    HomePageStateType.EXPLORE,
  );
  const [bodyFooterInformationProps, setBodyFooterInformationProps] =
    React.useState<BodyFooterInformationPropsType>(null);

  const { data, loading, loadMore } = useHomePageSections(
    { first: sectionsPerPage, filter: { stateType: stateType } },
    { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true },
  );

  const [loadMoreAfter, setLoadMoreAfter] = useState<string>(null);

  const loadMoreSections = () => {
    if (data?.pageInfo?.endCursor && data?.pageInfo?.hasNextPage) {
      const after = data?.pageInfo?.endCursor;
      loadMore({
        after: after,
      });
      setLoadMoreAfter(after);
    }
  };

  const getListsData = (
    categoriesList: BodyFooterInformationPropsType['navigationLists'][0]['children'],
  ): BodyFooterInformationPropsType['navigationLists'] => {
    const shopping = {
      title: 'Shopping',
      children: categoriesList,
    };
    const features = {
      title: 'Features',
      children: [
        {
          name: 'Shopping List',
          onClick: authenticated
            ? () =>
                navigateTo({ route: pages.SHOPPING_LISTS, showLoader: true })
            : () => null,
        },
        {
          name: 'My Orders',
          onClick: authenticated
            ? () => navigateTo({ route: pages.ORDER_HISTORY, showLoader: true })
            : () => null,
        },
      ],
    };
    const service = {
      title: 'Service',
      children: [
        // {
        //   name: 'Customer Service',
        //   onClick: () => navigateTo({ route: pages.ORDER_HISTORY }),
        // },
        {
          name: 'FAQ',
          onClick: () => navigateTo({ route: pages.FAQ }),
        },
        {
          name: 'Contact us',
          onClick: () => navigateTo({ route: pages.CONTACT_US }),
        },
        {
          name: 'Terms & Privacy',
          onClick: () => navigateTo({ route: pages.TERMS_PRIVACY }),
        },
        {
          name: 'Return policy',
          onClick: () => navigateTo({ route: pages.RETURN_POLICY }),
        },
      ],
    };
    if (authenticated) {
      service.children.unshift({
        name: 'Account Settings',
        onClick: () => navigateTo({ route: pages.MY_ACCOUNT }),
      });
    }

    return [shopping, features, service];
  };

  React.useEffect(() => {
    updateBodyFooterInformationProps();
  }, [selectedChannel?.id]);

  const navigateToApp = (url: string) => (window.location.href = url);
  const whatsappUrl = `https://api.whatsapp.com/send/?phone=${
    selectedChannel?.botPhone
      ? selectedChannel?.botPhone
      : process.env.NEXT_PUBLIC_WHATSAPP_PHONE
  }`;

  const updateBodyFooterInformationProps = async () => {
    getRootCategoriesBaseFragments().then((response) => {
      const data = response.map(
        (
          category,
        ): BodyFooterInformationPropsType['navigationLists'][0]['children'][0] => ({
          name: category.name,
          onClick() {
            navigateTo({ route: `/${category.slug}` });
          },
        }),
      );
      const lists = getListsData(data);
      setBodyFooterInformationProps({
        navigationLists: lists,
        appStoreIconUrl: getServerAssetsImageUrl('apple_logo.svg'),
        googlePlayIconUrl: getServerAssetsImageUrl('Google_Play_logo.svg'),
        facebookIconUrl: getServerAssetsImageUrl('Facebook_logo.svg'),
        whatsappIconUrl: getServerAssetsImageUrl('whatsapp_logo.svg'),
        instagramIconUrl: getServerAssetsImageUrl('instagram_logo.svg'),
        onAppStoreClick: () =>
          navigateTo({
            route: 'https://apps.apple.com/us/app/teddly/id6450178468',
            options: { scroll: false },
          }),
        onGooglePlayClick: () =>
          navigateTo({
            route:
              'https://play.google.com/store/apps/details?id=com.teddly.store&pcampaignid=web_share',
            options: { scroll: false },
          }),
        onFacebookClick: () =>
          navigateTo({
            route: 'https://www.facebook.com/profile.php?id=61552579828579',
            options: { scroll: false },
          }),
        onInstagramClick: () =>
          navigateTo({
            route: 'https://www.instagram.com/teddly_app/',
            options: { scroll: false },
          }),
        onWhatsappClick: () =>
          navigateTo({ route: whatsappUrl, options: { scroll: false } }),
        onHelpClick: () =>
          navigateTo({ route: whatsappUrl, options: { scroll: false } }),
      });
    });
  };

  const isFirstPageLoading =
    isCurrentPage(pages.HOME) &&
    !loadMoreAfter &&
    (!data ||
      data?.edges?.length < 1 ||
      (isChannelSelected &&
        data.edges[0].node.channel.id !== selectedChannel?.id));

  const getStartRequestIndex = () => {
    if (loadMoreAfter) {
      const lastNodeOfPreviousRequest = data?.edges?.find(
        (node) => node?.cursor === loadMoreAfter,
      );
      const lastIndexOfPreviousRequest = data?.edges?.indexOf(
        lastNodeOfPreviousRequest,
      );
      return lastIndexOfPreviousRequest + 1;
    }
    return sectionsPerPage;
  };

  const isProductsSectionInLastRequest = (): boolean => {
    const dataLength = data?.edges?.length;
    const startIndexOfThisRequest = !loadMoreAfter ? 0 : getStartRequestIndex();
    const lastRequestSections = data?.edges?.slice(
      startIndexOfThisRequest,
      dataLength,
    );

    const isProductsSectionInLastRequest = !!lastRequestSections?.find(
      (node) =>
        node?.node?.scrollableSection?.itemsType === ScrollableItemType.PRODUCT,
    );
    return isProductsSectionInLastRequest;
  };

  React.useEffect(() => {
    setPageLoading(loading);
  }, [loading]);

  // React.useEffect(() => {
  //   if (loading) {
  //     setPageLoading(loading);
  //   } else if (!isProductsSectionInLastRequest()) {
  //     setPageLoading(false);
  //   }
  // }, [loading]);

  return (
    <HomePageContext.Provider
      value={{
        isSearchFieldVisible: isSearchFieldVisible,
        setIsSearchFieldVisible: setIsSearchFieldVisible,
        stateType: stateType,
        setStateType: setStateType,
        bodyFooterInformationProps,
        data,
        loading,
        loadMore: loadMoreSections,
        isFirstPageLoading,
      }}>
      {children}
    </HomePageContext.Provider>
  );
}

export const useHomePageContext = () => {
  const context = React.useContext(HomePageContext);

  if (!context)
    throw new Error('HomePageContext was used outside of the HomePageProvider');

  return context;
};
