import { NewVariant } from '@interfaces';

//Cart Context
export enum CartActions {
  ADD,
  ADD_BULK,
  UPDATE,
  REMOVE,
  SUBTRACT,
}

export interface LinesQueueProps {
  variant: NewVariant;
  reqId: number;
  typeAction: CartActions;
  isHandled: boolean;
}

export interface CartError {
  code?: string;
  field?: string;
  message?: string;
}
