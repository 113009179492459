import { Button, ButtonVariantEnum } from '@teddly/teddly-ui-components';
import AppleLogin from 'react-apple-login';
import AppleIcon from '@mui/material/Icon';
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { isIOS } from '@utils/platform';
import styles from './SocialLogin.module.scss';
import { getServerAssetsImageUrl } from '@utils';

type Props = {
  responseGoogle?: (data: any) => void;
  responseFacebook?: (data: any) => void;
  responseApple?: (data: any) => void;
  setLoadingMode?: (data: boolean) => void;
  googleButtonTestid: string;
  appleButtonTestid: string;
};

const emptyFn = () => null;
const redirectURI =
  typeof window !== 'undefined' ? window?.location?.origin : '';
const googleClientID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
const facebookAppId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
const appleClientId: string = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID;
const redirectApplePostURI = process.env.NEXT_PUBLIC_APPLE_REDIRECT_URI;

export default function SocialLogin({
  responseGoogle = emptyFn,
  responseFacebook = emptyFn,
  responseApple = emptyFn,
  googleButtonTestid,
  appleButtonTestid,
  setLoadingMode,
}: Props) {
  const { query } = useRouter();
  const isOopUpLoginMode = !isIOS();
  const { apple_code, first_name, last_name, code: googleCode } = query;
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      setLoadingMode(true);
      await responseGoogle(response);
      setLoadingMode(false);
    },
    onNonOAuthError: (response) => {
      setLoadingMode(false);
    },
    onError: (response) => {
      responseGoogle(response);
    },
    ux_mode: isOopUpLoginMode ? 'popup' : 'redirect',
    redirect_uri: redirectURI,
    flow: 'auth-code',
  });

  useEffect(() => {
    const socialVerification = async () => {
      if (apple_code) {
        setLoadingMode(true);
        await responseApple({
          authorization: { code: apple_code },
          user: { name: { firstName: first_name, lastName: last_name } },
        });
        setLoadingMode(false);
      }
      if (googleCode) {
        setLoadingMode(true);
        await responseGoogle({ code: googleCode });
        setLoadingMode(false);
      }
    };
    socialVerification();
  }, []);

  return (
    <>
      {' '}
      <Button
        data-testid={googleButtonTestid}
        fullWidth
        title={'Continue with Google'}
        icon={
          <img
            className={styles.iconClassName}
            src={getServerAssetsImageUrl('google-icon.png')}
          />
        }
        onClick={() => {
          loginWithGoogle();
          if (!isOopUpLoginMode) {
            setLoadingMode(true);
          }
        }}
        variant={ButtonVariantEnum.OUTLINE}>
        {/* <div style={{ fontWeight: 'normal' }}>Continue with Google</div> */}
      </Button>
      {appleClientId && appleClientId.includes('auth') && (
        <>
          <AppleLogin
            redirectURI={redirectApplePostURI}
            clientId={appleClientId}
            usePopup={false}
            callback={responseApple} // Catch the response
            scope="email name"
            responseMode="form_post"
            render={(
              renderProps, //Custom Apple Sign in Button
            ) => (
              <Button
                data-testid={appleButtonTestid}
                style={{
                  border: '1px solid black',
                }}
                onClick={renderProps.onClick}
                fullWidth
                variant={ButtonVariantEnum.OUTLINE}
                title={'Continue with Apple'}
                icon={
                  <img
                    className={styles.iconClassName}
                    src={getServerAssetsImageUrl('apple-icon.png')}
                  />
                }>
                {/* <AppleIcon />
                <div style={{ fontWeight: 'normal', marginRight: '7px' }}>
                  Continue with Apple
                </div> */}
              </Button>
            )}
          />
        </>
      )}
    </>
  );
}
