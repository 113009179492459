import { StripeCardData } from '@components/Pages/Checkout/hooks';
import { ICheckoutModel } from 'teddly-sdk/lib/helpers';

const descriptionToTitle = (text: string) => {
  if (!text) {
    return '';
  }
  const words = text?.split(' ');

  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i].substr(1)?.toLowerCase();
  }

  return words?.join(' ');
};

export const getAddressDescription = (address: ICheckoutModel['shippingAddress']) => {
  return `${descriptionToTitle(address.streetAddress1)}, ${descriptionToTitle(address.city)}, ${address.countryArea}, ${
    address.postalCode
  }`;
};

export const getPaymentDescription = (cardData: StripeCardData) => {
  const card = cardData?.creditCardInfo;
  return `${card?.brand}, **** ${card?.lastDigits}, expires on ${card?.expMonth}/
      ${card?.expYear}`;
};
