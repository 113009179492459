import { useRef } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { AddressInput, CountryCode } from 'teddly-sdk';

type Props = {
  onSelectAddress: (address: AddressInput) => void;
  className?: string;
  label?: string;
  inputRef?: any;
  dataTestId: any;
};

const AutocompleteAddress = ({ onSelectAddress, className, label, dataTestId, inputRef}: Props) => {
  const GOOGLE_MAPS_API_KEY =
    process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || 'AIzaSyBTg5B9Ot6iAIqELEvoN7H4ruCeoCucWhI';
    

  const onSelect = (results): void => {
    let listAddress: any = {};
    if (!results?.address_components) {
      return;
    }
    results?.address_components?.map((item: any) => {
      listAddress[item?.types[0]] = item;
    });
    extractAddress(listAddress);
  };

  const extractAddress = (listAddress: any[]): void => {
    const address: AddressInput = {
      city:
        getField(listAddress['sublocality_level_1']?.long_name) ||
        getField(listAddress['locality']?.long_name),
      postalCode: getField(listAddress['postal_code']?.long_name),
      streetAddress1: `${getField(
        listAddress['street_number']?.long_name
      )} ${getField(listAddress['route']?.long_name)}`,
      countryArea: getField(
        listAddress['administrative_area_level_1']?.short_name
      ),
    };
    onSelectAddress(address);
  };
  const getField = (field: string | undefined) => {
    return field ? field : '';
  };
  return (
    <>
      <Autocomplete
        data-testid={dataTestId}
        placeholder={label}
        className={className}
        apiKey={GOOGLE_MAPS_API_KEY}
        ref={inputRef}
        onPlaceSelected={(place) => {
          if (place) {
            onSelect(place);
          }
        }}
        options={{
          componentRestrictions: { country: [CountryCode.US] },
          fields: ['address_components'],
          types: ['address'],
        }}
      />
    </>
  );
};

export default AutocompleteAddress;
