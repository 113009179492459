import { useEffect, useState } from 'react';

import { css } from '@emotion/react';
import tw from 'twin.macro';

import {
  CheckoutPageContext,
  useCheckoutPageContext,
} from '../../../../context/CheckoutPageContext';
import {
  StoredPaymentSources_me_storedPaymentSources,
  StoredPaymentSources_me_storedPaymentSources_creditCardInfo,
} from 'teddly-sdk/lib/queries/gqlTypes/StoredPaymentSources';
import { deletePaymentSource } from '@app/payment';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';
import { PaymentGatewayType } from '@app/payment/types';
import { useNetworkState } from '@hooks/useNetworkState';
import PaymentMethodsList from '@components/Pages/Checkout/components/PaymentMethodsList';
import { Dialog, Button } from '@teddly/teddly-ui-components';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { StripeCardData } from '../hooks';

type PickMethodProps = {
  cards?: StripeCardData[];
  isOpen?: boolean;
  onClose?: () => void;
  onChangeCard?: (cardData: StripeCardData) => void;
  onDeleteCard?: () => void;
  paymentGateway: PaymentGatewayType;
};

export default function PickPaymentMethod({
  cards = [],
  onClose,
  onChangeCard,
  onDeleteCard,
  isOpen,
  paymentGateway,
}: PickMethodProps) {
  const { selectedCardInfo, openAddPaymentForm, closeCurrentDialog } =
    useCheckoutPaymentContext();

  const [newSelectedCard, setNewSelectedCard] = useState<StripeCardData>();

  const { state, setLoading, setCompleted, setIdle, setError } =
    useNetworkState();

  const { addSnackbar, closeSnackbar } = usePageLayoutContext();

  useEffect(() => {
    if (cards && state.status == 'loading') {
      setCompleted(true);
    }
  }, [cards]);

  useEffect(() => {
    if (state.status == 'error') {
      addSnackbar({
        id: 'error',
        onClose: () => closeSnackbar('error'),
        message: state.error,
        variant: SnackbarVariant.ERROR,
      });
    }
  }, [state.status]);
  const changeCardHandler = (event) => {
    event.stopPropagation();
    if (newSelectedCard) {
      onChangeCard(newSelectedCard);
    }
    closeCurrentDialog();
  };

  const selectMethodHandler = async (method) => {
    setNewSelectedCard(method);
  };
  const onDeleteCardHandler = async () => {
    setLoading();
    try {
      await onDeleteCard();
    } catch (e) {
      setError(e);
    }
  };

  const close = () => {
    onClose();
    setNewSelectedCard(selectedCardInfo);
  };

  useEffect(() => {
    if (!newSelectedCard && selectedCardInfo) {
      setNewSelectedCard(selectedCardInfo);
    }
  }, [selectedCardInfo]);

  return (
    <>
      {cards && (
        <form>
          <Dialog
            title=" Pick Preferred Card"
            isOpen={isOpen}
            onClose={close}
            disableBackdropClick={false}
            disableEscapeKeyDown={false}
            footerProps={{
              children: [
                <Button
                  variant={'outline'}
                  onClick={openAddPaymentForm}
                  title={'Add New'}
                />,
                <Button
                  onClick={(event) => {
                    closeCurrentDialog();
                    changeCardHandler(event);
                  }}
                  title={'Change Card'}
                />,
              ],
            }}>
            <div style={{ maxWidth: 'inherit' }}>
              <PaymentMethodsList
                cards={cards}
                onDeleteCard={onDeleteCardHandler}
                paymentGateway={paymentGateway}
                selectedMethodId={newSelectedCard?.creditCardInfo?.cardId}
                onSelectCard={selectMethodHandler}
              />
            </div>
          </Dialog>
        </form>
      )}
    </>
  );
}
