import * as Sentry from '@sentry/react';

class Loggers {
  constructor() {}

  public error(message: string): void {
    Sentry.withScope(function (scope) {
      Sentry.captureException(message);
    });
  }
  public message(message: string): void {}
  public toBeDone(massege: string) {
    // Sentry.withScope(function (scope) {
    //   Sentry.?????();
    // });
  }
}

const loggers = new Loggers();

export default loggers;
