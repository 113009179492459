import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';

const {
  buttons: btn,
  namedElements: elm,
  inputFields: input,
  componenets,
} = utilsData.elementsDataTestIDs;

export class HeaderIDs {
  logoID: string;
  progressBarID: string;
  arrowBackButtonID: string;
  pickTimeSlotButtonID: string;
  searchButtonID: string;
  loginButtonID: string;
  accountActionsButtonID: string;
  cartButtonID: string;
  searchInputFieldID: string;
  protected fullPrefix: string;

  constructor() {
    this.fullPrefix = componenets.navigation.header;

    this.logoID = TestIDFormat.element(this.fullPrefix, elm.logo);
    this.progressBarID = TestIDFormat.element(
      this.fullPrefix,
      componenets.loaders.progressBar,
    );
    this.arrowBackButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.arrowBack,
    );
    this.pickTimeSlotButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.pickDeliveryTimeSlot,
    );
    this.searchButtonID = TestIDFormat.button(this.fullPrefix, btn.search);
    this.loginButtonID = TestIDFormat.button(this.fullPrefix, btn.logIn);
    this.accountActionsButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.accountActions,
    );
    this.cartButtonID = TestIDFormat.button(this.fullPrefix, btn.cart);
    this.searchInputFieldID = TestIDFormat.inputField(
      this.fullPrefix,
      input.search,
    );
  }
}

export class Header extends TestObjectBase<HeaderIDs> {
  constructor() {
    super(HeaderIDs);
  }

  get logo() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.logoID));
  }
  get progressBar() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.progressBarID));
  }

  get arrowBackButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.arrowBackButtonID));
  }

  get pickTimeSlotButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.pickTimeSlotButtonID));
  }

  get searchButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.searchButtonID));
  }

  get loginButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.loginButtonID));
  }

  get accountActionsButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.accountActionsButtonID));
  }

  get cartButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.cartButtonID));
  }

  get searchInputField() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.searchInputFieldID));
  }
}
