import { useCallback, useState } from 'react';

import {
  AddressInput,
  AddressTypeEnum,
  UpdateUserAddressMutation,
  UpdateUserAddressMutationVariables,
} from 'teddly-sdk';
// import { UpdateUserAddressVariables, UpdateUserAddress_accountAddressUpdate } from 'teddly-sdk/lib/mutations/gqlTypes/UpdateUserAddress';

import { Address } from '@app/user/address';
import { useUserAddressesContext } from '@context/UserAddressContext';

import ShippingAddressForm from '../../components/ShippingAddressForm';
import { CurrentOpenDialog } from '../../../../../context/CheckoutPageContext';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';

type EditAddressFormProps = {
  isAccountSettings?: boolean;
  addressType: AddressTypeEnum;
};

export default function EditAddressForm({
  isAccountSettings,
  addressType,
}: EditAddressFormProps) {
  const { updateAddress } = useUserAddressesContext();

  const {
    setSelectedShippingAddress: setSelectedAddress,
    closeCurrentDialog,
    openPickAddressForm,
    selectedShippingAddress: currentSelectedAddress,
    currentOpenDialog,
    addressToEdit,
    isShippingLoading,
    setIsShippingLoading,
  } = useCheckoutShippingAddressContext();

  const { addSnackbar, closeSnackbar } = usePageLayoutContext();

  const handleSubmit = async (
    address: AddressInput,
    isDefault: boolean,
    id: string,
  ): Promise<UpdateUserAddressMutation | null> => {
    try {
      setIsShippingLoading(true);
      const vars: UpdateUserAddressMutationVariables = {
        id,
        input: address,
        type: addressType,
        isDefault,
      };
      const response = await updateAddress(vars);
      const data = response.accountAddressUpdate;
      if (data && data?.data && data?.user?.id) {
        setSelectedAddress(
          data?.data,
          // data.user?.addresses?.find((add) => add.id === addressToEdit.id)
        );
        addSnackbar({
          id: 'completed',
          onClose: () => closeSnackbar('completed'),
          message: 'Delivery Address is updated successfully!',
          variant: SnackbarVariant.SUCCESS,
        });
        closeCurrentDialog();
      } else if (data && data.errors && data.errors.length) {
        addSnackbar({
          id: 'error',
          onClose: () => closeSnackbar('error'),
          message: 'Something went wrong',
          variant: SnackbarVariant.ERROR,
        });
      }
      setIsShippingLoading(false);
      return response;
    } catch (e) {
      addSnackbar({
        id: 'error',
        onClose: () => closeSnackbar('error'),
        message: 'Something went wrong',
        variant: SnackbarVariant.ERROR,
      });
      return e;
    } finally {
      setIsShippingLoading(false);
    }
  };

  return (
    <>
      {addressToEdit && (
        <ShippingAddressForm
          shippingAddress={addressToEdit}
          isOpen={currentOpenDialog === CurrentOpenDialog.EditAddressForm}
          onClose={() => {
            closeCurrentDialog();
            if (!isAccountSettings) openPickAddressForm();
          }}
          onSubmit={handleSubmit}
          loading={isShippingLoading}
          backendErrors={[]}
        />
      )}
    </>
  );
}
