import { useState } from 'react';

import { useAuth } from 'teddly-sdk';

import ForgotPasswordDialog from '@components/Organisms/ForgotPasswordDialog';

import {
  PageDialog,
  SnackbarVariant,
  usePageLayoutContext,
} from '../../../../context/PageLayoutContext';

export default function ForgotPasswordFormController() {
  const { resetPasswordRequest } = useAuth();
  const {
    openDialog: openDialog,
    closeDialog,
    openLoginDialog,
    addSnackbar,
    closeSnackbar,
  } = usePageLayoutContext();
  const [fieldErrorMessages, setFieldErrorMessages] = useState<
    Record<string, string>
  >({});

  const handleForgotPassword = async ({ email }) => {
    const redirectUrl = `${window.location.origin}/?openDialog=${PageDialog.resetPassword}`;
    const res = await resetPasswordRequest(email.toLowerCase(), redirectUrl);
    const error = res?.dataError?.error;

    if (error && error.length) {
      const errorObj = {};
      error.forEach((item) => {
        errorObj[item.field] = item.message;
      });
      setFieldErrorMessages(errorObj);
    } else if (res?.functionError) {
      console.error(res.functionError);
      addSnackbar({
        id: 'error',
        onClose: () => closeSnackbar('error'),
        message: 'Something went wrong. Please try again',
        variant: SnackbarVariant.ERROR,
      });
    } else {
      addSnackbar({
        id: 'success',
        onClose: () => closeSnackbar('success'),
        message: 'Please check your email for password reset link!',
        variant: SnackbarVariant.SUCCESS,
      });
      closeDialog();
    }
  };

  return (
    <>
      <ForgotPasswordDialog
        isOpen={openDialog === PageDialog.forgotPassword}
        fieldErrorMessages={fieldErrorMessages}
        forgotPassword={handleForgotPassword}
        onClose={closeDialog}
        onLoginClick={openLoginDialog}
      />
    </>
  );
}
