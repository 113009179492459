import React, { useState } from 'react';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { DetailHeading } from '../styled';
import { Switch, UserInfoBlock, List } from '@teddly/teddly-ui-components';
import useWindowDimensions from '@hooks/useWindowDimension';
import styles from '../style.module.scss'

type Props = {
  setIsLoading: (isLoading: boolean) => void;
  itemSubstitutionDataTestId: string;
  toivelingDataTestId: string;
  specialRequestSectionDataTestId: string;
};

export default function SpecialRequest({
  setIsLoading,
  specialRequestSectionDataTestId,
  itemSubstitutionDataTestId,
  toivelingDataTestId,
}: Props) {
  const { checkout, updateCheckout } = useCheckoutFlowContext();

  const itemSubstitutionChangeHandler = async () => {
    setIsLoading(true);
    await updateCheckout({
      checkoutId: checkout?.id,
      itemSubstitution: !checkout?.itemSubstitution,
    });
    setIsLoading(false);
  };

  const toivelingChangeHandler = async () => {
    setIsLoading(true);
    await updateCheckout({
      checkoutId: checkout?.id,
      toivelingItems: !checkout?.toivelingItems,
    });
    setIsLoading(false);
  };
  const { isLargeTablet } = useWindowDimensions();


  const toivelingDescription = `Toiveling Options *
  

  There are some items in your cart which may require Toiveling - whould you like them Toiveled?
  If you choose to Toivel a product, it cannot be returned.
  Unfortunately, we cannot Toivel items which contain electrical components.`;

  return (
    <UserInfoBlock
      boldTitle
      title={'Special requests'}
      isWithShading
      description={
        <List isRowDirection={!isLargeTablet} className={isLargeTablet ? styles.specialRequestsList : ""}>
          <List.Item key={'itemSubstitution'}>
            <Switch
              dsta-testid={itemSubstitutionDataTestId}
              dataTestId={itemSubstitutionDataTestId}
              id="itemSubstitution"
              label="Item Substitution?"
              checked={checkout?.itemSubstitution}
              onChange={itemSubstitutionChangeHandler}
            />
          </List.Item>
          {checkout?.isToiveling ? (
            <List.Item key={'toiveling'}>
              <Switch
                data-testid={toivelingDataTestId}
                dataTestId={toivelingDataTestId}
                id="toiveling"
                checked={checkout?.toivelingItems}
                onChange={toivelingChangeHandler}
                label="Toiveling?"></Switch>
            </List.Item>
          ) : (
            <div />
          )}
        </List>
      }
    />
  );
}
