import Utils from '../../utils/utils.cy';

//moved to testIDs
export const ids = {
  arrowBackButton: 'arrow-back-button', // the header arrow back is not in use
  teddlyLogoButton: 'teddly-logo-button',
  selectDeliveryTimeSlotButton: 'select-delivery-time-slot-button',
  mainSearchInput: 'main-search-input', // the header search input is not taken from here
  accountActionsButton: 'account-actions',
  cartButton: 'check-out-button',
};

export const pagePathNames = {
  homePage: '/',
  groceryPage: '/grocery',
  housholdPage: '/household',
  pharmacyPage: '/pharmacy',
  judaicaPage: '/judaica',
  clothesAndToysPage: '/clothes-and-toys',
  storesPage: '/stores',
  myPopularItemsPage: '/my-popular-items',
  myShoppingListsPage: '/shopping-lists',
  myAccountSettingPage: '/my-account',
  returnPolicyPage: '/return-policy',
  privacyPolicyPage: '/terms-privacy',
  checkoutPage: '/checkout',
  orderHistoryPage: '/order-history',
  orderDetailsPage: '/order-details',
};

export default class HeaderPage {
  get arrowBackButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.arrowBackButton));
  }

  get teddlyLogoButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.teddlyLogoButton));
  }

  get checkoutButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cartButton));
  }

  get selectDeliveryTimeSlotButton() {
    return cy.get(
      Utils.formatDataTestIdSelector(ids.selectDeliveryTimeSlotButton),
    );
  }

  get mainSearchInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.mainSearchInput));
  }

  get accountActionsButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.accountActionsButton));
  }

  clickAccountActionsButton() {
    return this.accountActionsButton.click({ force: true, multiple: true });
  }

  assertAccountActions(expectedMessage) {
    return this.accountActionsButton.contains(expectedMessage, {
      matchCase: false,
    });
  }

  clickArrowBackButton() {
    return this.arrowBackButton.click({ force: true });
  }

  clickCartButton() {
    return this.checkoutButton.click({ force: true });
  }
  clickselectDeliveryTimeSlotButton() {
    return this.selectDeliveryTimeSlotButton.click({ force: true });
  }

  clickHomeButton() {
    return this.teddlyLogoButton.click({ force: true });
  }
}
