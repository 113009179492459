import aa from "search-insights";

export enum ProductEvents {
    ADDED_TO_CART = "added to cart",
    ADDED_TO_SHOPPING_LIST = "added to shopping list"
}

interface ConvertedEventProps {
    objectID: string;
    queryID: string;
    userId: string;
    indexName: string;
    eventName: ProductEvents;
}

export const sendConvertedEvent = ({ eventName, indexName, objectID, queryID, userId }: ConvertedEventProps) => {
    aa('init', {
        appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
        apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    })
    aa('convertedObjectIDsAfterSearch', {
        userToken: userId,
        eventName: eventName,
        index: indexName,
        queryID: queryID,
        objectIDs: [objectID]
    })
}

