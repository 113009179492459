const index = {
  account: {
    new: 'account/new.json',
    existing: 'account/existing.json',
    nonExisting: 'account/non-existing.json',
    invalid: 'account/invalid.json',
    noOrders: 'account/no-orders.json',
  },
  channel: {
    crownHeights: 'delivery-address/crown-heights.json',
    connecticut: 'delivery-address/connecticut.json',
    flatbush: 'delivery-address/flatbush.json',
    dallas: 'delivery-address/dallas.json',
  },
  loginStates: {
    emptyCart: 'login-states/empty-cart.json',
    emptyOrders: 'login-states/empty-orders.json',
    addressUser: 'login-states/address-user.json',
  },
  deliveryAddress: {
    easternParkway: 'delivery-address/eastern-parkway.json',
  },
  paymentsMethods: {
    valid: 'payments-methods/valid.json',
  },
};

export default index;
