import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { SaleorCheckoutAPI } from 'teddly-sdk/lib/api';
import { useCheckout } from 'teddly-sdk';

const CheckoutFlowContext = createContext<Partial<SaleorCheckoutAPI>>(null);

export function CheckoutFlowProvider({ children }: { children: ReactNode }) {
  const checkoutAPI = useCheckout();
  const [checkoutApiState, setCheckoutApiState] =
    useState<SaleorCheckoutAPI | null>(checkoutAPI);

  useEffect(() => {
    const handleCheckoutChange = (newCheckout: SaleorCheckoutAPI) => {
      setCheckoutApiState({ ...newCheckout } as SaleorCheckoutAPI);
    };

    checkoutAPI.events.on('checkoutChanged', handleCheckoutChange);
    return () => {
      checkoutAPI.events.off('checkoutChanged', handleCheckoutChange);
    };
  }, [checkoutAPI]);

  return (
    <CheckoutFlowContext.Provider value={checkoutApiState}>
      {children}
    </CheckoutFlowContext.Provider>
  );
}

//   const checkout = useCheckout();

//   return (
//     <CheckoutFlowContext.Provider value={{ ...checkout }}>
//       {children}
//     </CheckoutFlowContext.Provider>
//   );
// }

export function useCheckoutFlowContext() {
  const context = useContext(CheckoutFlowContext);

  if (!context)
    throw new Error(
      'CheckoutFlowContext was used outside of the CheckoutFlowProvider',
    );

  return context;
}
