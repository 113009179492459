import { gql } from 'graphql-tag';

export const CREATE_PAYMNET_SOURCE = gql`
  mutation CreatePaymentSource(
    $paymentGatewayType: PaymentGatewayType!
    $paymentSourceInput: PaymentSourceInput!
  ) {
    createPaymentSource(
      paymentGateway: $paymentGatewayType
      input: $paymentSourceInput
    ) {
      success
      paymentSourceErrors {
        message
        field
        code
        __typename
      }
      __typename
    }
  }
`;

export const DELETE_PAYMNET_SOURCE = gql`
  mutation DeletePaymentSource(
    $paymentGateway: PaymentGatewayType!
    $paymentMethodId: String!
  ) {
    deletePaymentSource(
      paymentGateway: $paymentGateway
      paymentMethodId: $paymentMethodId
    ) {
      success
      paymentSourceErrors {
        message
        field
        code
        __typename
      }
      __typename
    }
  }
`;
