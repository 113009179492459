/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import PageLayoutPage from '../page-layout/PageLayoutPage.cy';

export const ids = {
  title: 'title',
  itemDetails: 'item-details',
  filtersButton: 'filters-button',
  collapsibleFilter: '-collapsible-filter', // sort-by, brand, vendor
  clearButton: 'clear-button',
  saveButton: 'save-button',
};

export default class SearchResultsPage {
  url: string;

  pageLayoutPage = new PageLayoutPage();

  get vendorCollapsibleFilter() {
    return cy.get(`[data-testid="vendor-collapsible-filter"]`);
  }
  get brandCollapsibleFilter() {
    return cy.get(`[data-testid="brand-collapsible-filter"]`);
  }
  get sortByCollapsibleFilter() {
    return cy.get(`[data-testid="sort by-collapsible-filter"]`);
  }
  get supervisionCollapsibleFilter() {
    return cy.get(`[data-testid="supervision-collapsible-filter"]`);
  }

  get subCategories() {
    return cy.get(`[data-testid="chip"]`);
  }
  get headerCloseButton() {
    return cy.get(`[data-testid="header-close-icon"]`);
  }

  get itemDetails() {
    return cy.get(Utils.formatDataTestIdSelector(ids.itemDetails));
  }
  get clearButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.clearButton));
  }

  get saveButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.saveButton));
  }

  get filtersButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.filtersButton));
  }

  get searchInput() {
    return this.pageLayoutPage.searchInput;
  }

  get title() {
    return cy.get(Utils.formatDataTestIdSelector(ids.title));
  }

  get firstSuggetion() {
    return cy.get('.pac-item');
  }

  clickFiltersButton() {
    return this.filtersButton.click({ force: true });
  }
  clickHeaderCloseButton() {
    return this.headerCloseButton.click({ force: true, multiple: true });
  }
  clickClearButton() {
    return this.clearButton.click({ force: true });
  }
  clickSaveButton() {
    return this.saveButton.click({ force: true });
  }

  clickFirstSuggestion() {
    return this.filtersButton.click({ force: true });
  }
}
