import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { useCart } from 'teddly-sdk';
import { SaleorCartAPI } from 'teddly-sdk/lib/api';

const CartFlowContext = createContext<Partial<SaleorCartAPI>>(null);

export function CartFlowProvider({ children }: { children: ReactNode }) {
  const cartAPI = useCart();
  const [cartApiState, setCartApiState] = useState<SaleorCartAPI | null>(
    cartAPI,
  );

  useEffect(() => {
    const handleCartChange = (newCart: SaleorCartAPI) => {
      setCartApiState({ ...newCart } as SaleorCartAPI);
    };

    cartAPI.events.on('cartChanged', handleCartChange);
    return () => {
      cartAPI.events.off('cartChanged', handleCartChange);
    };
  }, [cartAPI]);

  return (
    <CartFlowContext.Provider value={cartApiState}>
      {children}
    </CartFlowContext.Provider>
  );
}

export function useCartFlowContext() {
  const context = useContext(CartFlowContext);
  if (!context)
    throw new Error(
      'useCheckoutFlow should be used within CheckoutFlowContext',
    );

  return context;
}