import { PaymentGatewayType } from '@app/payment/types';

import { NewPaymentDetails } from '@interfaces';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import {
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from '@stripe/stripe-js';
import { AuthorizeNetAuthData } from '@components/Pages/Checkout/context/checkout-flow';
import { OrdersByUser } from 'teddly-sdk/lib/queries/gqlTypes/OrdersByUser';
import { ICheckoutModel } from 'teddly-sdk/lib/helpers';

export const getAvailablePaymentGateway = (
  availablePaymentGateways: ICheckoutModel['availablePaymentGateways']
): PaymentGatewayType => {
  if (!availablePaymentGateways || availablePaymentGateways?.length == 0) {
    return null;
  }
  const gatewayName = availablePaymentGateways?.[0]?.name.toUpperCase().split('.');
  if (!gatewayName) {
    return null;  
  }
  const indexOfPaymentGateway = Object.values(PaymentGatewayType).indexOf(
    gatewayName[0] as unknown as PaymentGatewayType
  );
  const paymentGateway = Object.keys(PaymentGatewayType)[
    indexOfPaymentGateway
  ] as PaymentGatewayType;
  return paymentGateway;
};

export  const getValidPaymentFrom = (
  payments: OrdersByUser['me']['orders']['edges'][0]['node']['payments']
) => {
  return payments?.find((p) => p.creditCard != null);
};

export async function authenticateCardOnAuthorizeNet(
  cardInfo: NewPaymentDetails,
  authData: AuthorizeNetAuthData
): Promise<any> {
  const cardData = {
    cardNumber: cardInfo.cardNumber,
    month: cardInfo.expiryDate.substring(0, 2),
    year: cardInfo.expiryDate.substring(2, 4),
    cardCode: cardInfo.cvv,
  };
  return new Promise((resolve, reject) => {
    Accept.dispatchData(
      { authData, cardData },
      async ({ messages, opaqueData }) => {
        if (messages.resultCode === 'Error') {
          reject(messages.message);
        } else {
          const token = opaqueData.dataValue;
          resolve(opaqueData);
        }
      }
    );
  });
}

export const authenticateCardOnStripe = ({
  card,
  billingAddress,
  stripe,
}: {
  card?: StripeCardElement | StripeCardNumberElement;
  cardInputData?: NewPaymentDetails;
  billingAddress?: IAddress;
  stripe: Stripe;
}) => {
  return stripe.createToken(card, {
    address_city: billingAddress.city,
    address_zip: billingAddress.postalCode,
    address_country: billingAddress.country.country,
    name: `${billingAddress.firstName} ${billingAddress.lastName}`,
    address_line1: billingAddress.streetAddress1,
    address_line2: billingAddress.streetAddress2,
    address_state: billingAddress.countryArea,
  });
};
