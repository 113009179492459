import gql from 'graphql-tag';
import { AvailableTimeslotsQuery, CheckoutCreateInput, CheckoutLineInput } from 'teddly-sdk';
import { ICheckoutModel } from 'teddly-sdk/lib/helpers';

import { getTeddlySdkApi } from '@config/teddly-sdk';

import { CREATE_CHECKOUT, UPDATE_CHECKOUT } from './fragments';
import { GraphQLError } from 'graphql';

export async function createCheckout(
  input: CheckoutCreateInput
): Promise<{ errors: Error[]; data: ICheckoutModel }> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  try {
    const {
      data: { checkoutCreate },
      errors: checkoutErrors,
    } = await teddlyApolloClient.mutate({
      mutation: CREATE_CHECKOUT,
      variables: { checkoutInput: input },
    });

    if (checkoutErrors && checkoutErrors.length > 0) {
      throw checkoutErrors[0];
    } else if (checkoutCreate?.errors && checkoutCreate.errors.length) {
      throw checkoutCreate.errors[0];
    }

    return {
      errors: null,
      data: checkoutCreate.checkout,
    };
  } catch (e) {
    return { errors: [e], data: null };
  }
}

export async function updateCheckoutLines(
  checkoutId: string,
  lines: CheckoutLineInput[]
): Promise<{ errors: Error[]; data: ICheckoutModel }> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  try {
    const {
      data: { checkoutLinesUpdate },
      errors: checkoutErrors,
    } = await teddlyApolloClient.mutate({
      mutation: UPDATE_CHECKOUT,
      variables: { checkoutId, lines },
    });
    if (checkoutErrors && checkoutErrors.length > 0) {
      throw checkoutErrors[0];
    } else if (
      checkoutLinesUpdate.checkoutErrors &&
      checkoutLinesUpdate.checkoutErrors.length
    ) {
      throw checkoutLinesUpdate.checkoutErrors[0];
    }

    return {
      errors: null,
      data: checkoutLinesUpdate.checkout,
    };
  } catch (e) {
    return { errors: [e], data: null };
  }
}

export async function deleteCheckoutLine(
  checkoutId: string,
  lineId: string
): Promise<void> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  try {
    await teddlyApolloClient.mutate({
      mutation: gql`
        mutation DeleteCheckoutLine($checkoutId: ID!, $lineId: ID!) {
          checkoutLineDelete(checkoutId: $checkoutId, lineId: $lineId) {
            checkout {
              id
            }
            checkoutErrors {
              field
              message
            }
          }
        }
      `,
      variables: { checkoutId, lineId },
    });
  } catch (e) {
    console.error(e);
  }
}

export async function deleteCheckoutLines(
  checkoutId: string,
  lineIds: string[]
): Promise<void> {
  await Promise.all(
    lineIds.map(async (lineId) => {
      return deleteCheckoutLine(checkoutId, lineId);
    })
  );
}

export interface TimeslotMethod{
  id?: string;
  startHour: string;
  endHour: string;
  weekday: string;
  durationMinutes: number;
  __typename: string;
}

export type Timeslot = AvailableTimeslotsQuery['availableTimeslot'][0]

export interface getAvailableTimeSlot_data{
  acceptable: boolean;
  timeslots: Timeslot[];
}

interface getAvailableTimeSlot_response{
  errors: GraphQLError[];
  data: getAvailableTimeSlot_data
}

// export async function getAvailableTimeSlot(
//   shippingMethodId: string,
//   channelId:string
// ): Promise<getAvailableTimeSlot_response> {
  
//   const { teddlyApolloClient } = await getTeddlySdkApi();
//   try {
//     const {
//       data: dataReturn,
//       errors: dataError,
//     } = await teddlyApolloClient.query({
//       fetchPolicy: "no-cache",
//       query: AVAILABLE_TIME_SLOT,
//       variables: { shippingMethodId, channelId },
//     });

//     if (dataError && dataError.length > 0) {
//       throw dataError[0];
//     } 
    
//     return {
//       errors: null,
//       data: dataReturn?.avalableTimeslot,
//     };
//   } catch (e) {
//     return { errors: [e], data: null };
//   }
// }
