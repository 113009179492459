import { useCartContext } from '@context/CartContext';
import styles from '../OrderDetailsPage.module.scss';
import {
  Dialog,
  Button,
  ButtonVariantEnum,
  ButtonColorEnum,
} from '@teddly/teddly-ui-components';
import ExitIcon from 'public/assets/exit_to_app.svg';
import { useState } from 'react';
import { CheckoutTypeEnum } from 'teddly-sdk';
import { usePageLayoutContext } from '@context/PageLayoutContext';

export default function ExitEditOrderModeDialog({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { changeCheckoutType } = useCartContext();
  const { dispatch } = usePageLayoutContext();
  const [
    isExitEditOrderModeButtonLoading,
    setIsExitEditOrderModeButtonLoading,
  ] = useState(false);
  const onExitEditOrderMode = async () => {
    setIsExitEditOrderModeButtonLoading(true);
    await changeCheckoutType(CheckoutTypeEnum.DEFAULT);
    setIsExitEditOrderModeButtonLoading(false);
    dispatch({ type: 'TOGGLE_CART', value: false });
    onClose();
  };

  const content = (
    <div className={styles.editOrderStartContent}>
      {`Are you sure you want to Exit Edit Mode,\nyour changes won't be saved.`}
    </div>
  );
  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Dialog.Footer>
        <>
          <Button
            title="Keep editing"
            variant={ButtonVariantEnum.NEGATIVE}
            onClick={onClose}
            disabled={isExitEditOrderModeButtonLoading}
          />
          <Button
            variant={ButtonVariantEnum.FILL}
            title={'Exit'}
            color={ButtonColorEnum.ERROR}
            onClick={onExitEditOrderMode}
            loading={isExitEditOrderModeButtonLoading}
            disabled={isExitEditOrderModeButtonLoading}
          />
        </>
      </Dialog.Footer>
      <Dialog.Header
        title={
          <div className={styles.editOrderStartDialogHeaderTitle}>
            <ExitIcon fill={'black'} />
            {'Exit Edit Mode'}
          </div>
        }
      />
      <Dialog.Body>{content}</Dialog.Body>
    </Dialog>
  );
}
