import { css } from '@emotion/react';
import { RegionDropdown } from 'react-country-region-selector';
import styles from './style.module.scss';
import { ids as addressIds } from '../../../../../cypress/pages/account-setting/AddressFormPage.cy'
import { Props } from 'react-responsive-carousel/lib/ts/components/Thumbs';
export default function StateSelectDropdown({
  value,
  onChange,
  disabled = false,
  deleteSelectSign = false,
  title,
  ...otherProps
}: {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  deleteSelectSign?:boolean;
  title?: string;
  
}) {
  return (
    <RegionDropdown
      data-testid={addressIds.stateInput}
      disabled={disabled}
      classes={
        deleteSelectSign
          ? styles.stateSelectWithOutSelectSign
          : styles.stateSelect
      }
      defaultOptionLabel={title ? title : "State"}
      country="US"
      countryValueType="short"
      value={value}
      onChange={onChange}
      valueType="short"
    />
  );
}
