import { ErrorMessage } from '../styled';
import PickPaymentMethod from '../PickPaymentMethod';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';

import {
  UserInfoBlock,
  Button,
  ButtonVariantEnum,
} from '@teddly/teddly-ui-components';
import { getPaymentDescription } from '@utils/descriptions';
import { usePaymentGateway } from '../../hooks/payments';

export default function PaymentMethodStep({
  title,
  paymentMethodSectionDataTestId,
  paymentMethodChangeButtonDataTestId,
}: {
  title: string;
  paymentMethodChangeButtonDataTestId: string;
  paymentMethodSectionDataTestId: string;
}) {
  const {
    changeCardHandler,
    deleteCardHandler,
    closeCurrentDialog,
    openAddPaymentForm,
    openPickPaymentForm,
    selectedCardInfo,
    cards,
    isPaymentValid,
    paymentSourceError,
    isOpenPickPaymentForm,
    paymentBillingError,
    isPaymentLoading,
  } = useCheckoutPaymentContext();

  const onOpenAddAddressFormClick = () => {
    if (cards && cards?.length > 0) {
      openPickPaymentForm();
    } else {
      openAddPaymentForm();
    }
  };

  const { paymentGateway, renderPaymentForm } = usePaymentGateway();

  return (
    <div>
      {renderPaymentForm()}
      <div>
        {selectedCardInfo && (isPaymentValid || isPaymentLoading) ? (
          <UserInfoBlock
            isWithShading
            dataTestId={paymentMethodSectionDataTestId}
            title={'Payment Method'}
            selected
            description={getPaymentDescription(selectedCardInfo)}
            buttons={[
              <Button
                key={'paymentMethodChangeButton'}
                data-tsetid={paymentMethodChangeButtonDataTestId}
                title="Change"
                loading={isPaymentLoading}
                disabled={isPaymentLoading}
                variant={ButtonVariantEnum.OUTLINE}
                onClick={openPickPaymentForm}
              />,
            ]}
          />
        ) : (
          <UserInfoBlock
            isWithShading
            data-testid={paymentMethodSectionDataTestId}
            title={title}
            onClick={onOpenAddAddressFormClick}
            loading={isPaymentLoading}
            disabled={isPaymentLoading}
          />
        )}
        {paymentSourceError && (
          <ErrorMessage>{paymentSourceError}</ErrorMessage>
        )}
        {paymentBillingError && (
          <ErrorMessage>{paymentBillingError}</ErrorMessage>
        )}
      </div>
      <PickPaymentMethod
        isOpen={isOpenPickPaymentForm && !!paymentGateway}
        onClose={closeCurrentDialog}
        cards={cards}
        onChangeCard={changeCardHandler}
        onDeleteCard={deleteCardHandler}
        paymentGateway={paymentGateway}
      />
    </div>
  );
}
