import { css } from '@emotion/react';
import styled from '@emotion/styled';
import tw from 'twin.macro';

const baseTextStyle = css(tw`font-poppins text-navy ml-2 tablet:ml-0`);
const colorDark = css(tw`text-dark`);

export const BodyText16 = styled.span`
  ${baseTextStyle};
`;

export const BodyText14 = styled(BodyText16)`
  font-size: 14px;
`;

export const ButtonText = styled(BodyText14)`
  line-height: 1.36;
  letter-spacing: 1.34px;
  font-weight: bold;
`;

export const CaptionText = styled(BodyText14)`
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 0.4px;
`;

export const OverlineText = styled(CaptionText)`
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 1.5px;
`;

/**
 * font-size: 16px
 */
export const Subtitle = styled(BodyText16)`
  font-weight: 600;
  line-height: 1.56;
  letter-spacing: 0.14px;
`;

/**
 * font-size: 14px
 */
export const SubtitleSmall = styled(BodyText14)`
  font-weight: 600;
  line-height: 1.71;
  letter-spacing: 0.1px;
`;

export const Heading1 = styled.h1`
  ${baseTextStyle};
  font-size: 96px;
  font-weight: 300;
  line-height: 1.51;
  letter-spacing: -1.54px;
`;

export const Heading2 = styled.h2`
  ${baseTextStyle};
  font-size: 60px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: -0.48px;
`;

export const Heading3 = styled.h3`
  ${baseTextStyle};
  font-size: 48px;
  line-height: 1.5;
`;

export const Heading4 = styled.div`
  ${baseTextStyle};
  font-size: 18px;//retailin
  font-weight: 600;
  line-height: 1.5;
  margin: 24px 0px 8px 0px;
  letter-spacing: -0.48px;
  ${colorDark};
  @media (min-width:1000px) {//retailin
    font-size: 32px;//retailin
  }
`;

export const Heading5 = styled.h5`
  ${baseTextStyle};
  font-size: 24px;
  line-height: 1.46;
`;

export const Heading6 = styled.h6`
  ${baseTextStyle};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.15px;
`;

export const LinkTextLight = styled(BodyText16)(tw`
font-medium
  text-bbbbbb
`);

export const LinkTextDark = styled(BodyText16)(tw`
font-medium
  text-gray-aaa
`);

export const FormHeading = styled.h1(tw`
  font-poppins
  text-2.5xl
  text-dark
  tracking-tight
  font-semibold
  text-center
`);

export const FormSubHeading = styled.h2(tw`
  font-poppins
  text-dark
  font-medium
`);
