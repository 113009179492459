import {
  useContext,
  createContext,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { CurrentOpenDialog } from './CheckoutPageContext';
import { useCheckoutFlowContext } from './CheckoutFlowContext';
import { CheckoutFragment, DeliveryTypeEnum, useAuth } from 'teddly-sdk';
import { ICheckoutModel, ICheckoutAddress } from 'teddly-sdk/lib/helpers';
import {
  UserAddressType,
  useUserAddressesContext,
} from '@context/UserAddressContext';
import { useChannelContext } from '@context/ChannelContext';
import { useRoutesContext } from './RoutesContext';

const CheckoutShippingAddressContext = createContext<{
  availableShippingMethods: ICheckoutModel['availableShippingMethods'];
  setSelectedShippingMethod: (
    shippingMethod: ICheckoutModel['availableShippingMethods'][0],
  ) => Promise<void>;
  selectedShippingAddress: ICheckoutAddress;
  selectedShippingMethod: ICheckoutModel['shippingMethod'];
  openPickAddressForm: () => void;
  closeCurrentDialog: () => void;
  currentOpenDialog: CurrentOpenDialog;
  saveShippingError: string;
  shippingMethodError;
  openAddAddressForm: () => void;
  isCheckoutShippingAddressValid: boolean;
  openEditAddressForm: (addressToEdit: UserAddressType) => void;
  setSelectedShippingAddress: (address: ICheckoutAddress) => Promise<void>;
  isAddAddressDialogOpen: boolean;
  isPickAddressDialogOpen: boolean;
  isAddressDialogOpen: boolean;
  addressToEdit?: UserAddressType;
  isShippingLoading: boolean;
  setIsShippingLoading: (isLoading: boolean) => void;
  isValidationShippingAddressField: (field: string) => boolean;
}>(null);

export function CheckoutShippingAddressProvider({
  children,
}: {
  children: ReactNode;
}) {
  // contexts
  const { userAddresses, defaultShippingAddress } = useUserAddressesContext();
  const { checkout, setShippingMethod, setShippingAddress } =
    useCheckoutFlowContext();
  const { selectedChannel } = useChannelContext();
  const { user } = useAuth();
  const { pages, isCurrentPage, navigateTo } = useRoutesContext();

  // states

  const [isCheckoutShippingAddressValid, setIsCheckoutShippingAddressValid] =
    useState<boolean>(false);
  const [isShippingLoading, setIsShippingLoading] = useState(false);
  const [currentOpenDialog, setCurrentOpenDialog] =
    useState<CurrentOpenDialog>(null);
  const [shippingMethodError, setShippingMethodError] = useState();

  const [addressToEdit, setAddressToEdit] = useState<UserAddressType>(null);

  const [saveShippingError, setSaveShippingError] = useState('');
  const selectedShippingAddress = checkout?.shippingAddress;

  // requests

  const runChangeShippingAddressRequest = async (address: ICheckoutAddress) => {
    setIsShippingLoading(true);
    try {
      if (!address?.isValidToShipping) {
        setSaveShippingError('this address is not valid to shipping');
        setIsShippingLoading(false);
        return;
      }
      const { dataError, functionError, data } = await setShippingAddress(
        address,
        user.email,
      );
      if (dataError || functionError) {
        setSaveShippingError(
          dataError?.error ||
            functionError?.error ||
            'Something went wrong while saving the shipping address',
        );
      } else {
        // const requestAddressChannel = selectedShippingAddress?.channel?.id
        // const responseAddressChannel = data?.shippingAddress?.channel?.id
        // debugger
        // if(requestAddressChannel && requestAddressChannel == responseAddressChannel && requestAddressChannel !== selectedChannel?.id ){
        // }
      }
    } catch (e) {
      console.error('console.error in catch', e);
      setSaveShippingError(e.toString());
    } finally {
      setIsShippingLoading(false);
    }
  };
  const runChangeShippingMethodRequest = async (
    shippingMethod: CheckoutFragment['shippingMethod'],
  ) => {
    if (shippingMethod.id == checkout?.shippingMethod?.id) {
      setIsCheckoutShippingAddressValid(true);
      return;
    }
    setIsShippingLoading(true);
    setShippingMethod(shippingMethod?.id)
      .then(({ dataError, functionError, data }) => {
        if (dataError || functionError) {
          setShippingMethodError(
            dataError?.error ||
              functionError?.error ||
              'Something went wrong setting shipping method',
          );
        } else {
          setIsCheckoutShippingAddressValid(true);
        }
      })
      .catch((e) => {
        console.error(e);
        setShippingMethodError(e.toString());
      })
      .finally(() => {
        setIsShippingLoading(false);
      });
  };

  const changeSelectedShippingAddress = async (address: ICheckoutAddress) => {
    const isChannelChanged = address?.channel?.id !== selectedChannel?.id;
    await runChangeShippingAddressRequest(address);
    if (isChannelChanged && !isCurrentPage(pages.HOME)) {
      await navigateTo({ route: pages.HOME });
    }
  };

  useEffect(() => {
    if (
      checkout?.id &&
      !!selectedChannel &&
      checkout?.deliveryType === DeliveryTypeEnum.SHIPPING
    ) {
      const currentChannelAddresses = userAddresses?.filter(
        (ad) => ad?.channel?.id == selectedChannel?.id,
      );
      const isWithAddresses =
        currentChannelAddresses && currentChannelAddresses?.length > 0;
      const addressForAdd = defaultShippingAddress?.isValidToShipping
        ? defaultShippingAddress
        : currentChannelAddresses[0];

      if (isWithAddresses && !checkout?.shippingAddress) {
        const addressForSelect = addressForAdd?.isValidToShipping
          ? addressForAdd
          : null;
        changeSelectedShippingAddress(addressForSelect);
      } else if (
        checkout?.shippingAddress &&
        !checkout?.shippingAddress?.isValidToShipping
      ) {
        const addressForSelect = addressForAdd?.isValidToShipping
          ? addressForAdd
          : null;
        changeSelectedShippingAddress(addressForSelect);
      }
    }
  }, [userAddresses?.length, checkout?.shippingAddress]);

  const isValidationShippingAddressField = (field: string) => {
    if (!selectedShippingAddress[field]) {
      return false;
    }
    return true;
  };

  const setDefaultShippingMethod = () => {
    if (
      !checkout?.availableShippingMethods ||
      checkout?.availableShippingMethods.length == 0
    )
      return;
    if (checkout?.availableShippingMethods.length === 1) {
      runChangeShippingMethodRequest(checkout?.availableShippingMethods[0]);
    } else {
      const regularMethods: CheckoutFragment['availableShippingMethods'] =
        checkout?.availableShippingMethods.filter(
          (v, index) => index < checkout?.availableShippingMethods.length - 1,
        );
      if (regularMethods.find((v) => v.id == checkout?.shippingMethod?.id)) {
        runChangeShippingMethodRequest(
          regularMethods.find(
            (v) => v.isFastDelivery == checkout?.shippingMethod.isFastDelivery,
          ),
        );
      } else {
        runChangeShippingMethodRequest(regularMethods[0]);
      }
    }
  };

  useEffect(() => {
    setDefaultShippingMethod();
  }, [checkout?.availableShippingMethods]);

  const closeCurrentDialog = () => setCurrentOpenDialog(CurrentOpenDialog.None);

  const isAddAddressDialogOpen =
    currentOpenDialog === CurrentOpenDialog.AddAddressForm;
  const isPickAddressDialogOpen =
    currentOpenDialog === CurrentOpenDialog.PickAddress;
  const isAddressDialogOpen = isAddAddressDialogOpen || isPickAddressDialogOpen;

  const openAddAddressForm = () =>
    setCurrentOpenDialog(CurrentOpenDialog.AddAddressForm);

  const openPickAddressForm = () =>
    setCurrentOpenDialog(CurrentOpenDialog.PickAddress);
  const openEditAddressForm = (address: UserAddressType) => {
    setCurrentOpenDialog(CurrentOpenDialog.EditAddressForm);
    setAddressToEdit(address);
  };

  return (
    <CheckoutShippingAddressContext.Provider
      value={{
        availableShippingMethods: checkout?.availableShippingMethods,
        setSelectedShippingMethod: runChangeShippingMethodRequest,
        openEditAddressForm: openEditAddressForm,
        setSelectedShippingAddress: changeSelectedShippingAddress,
        selectedShippingAddress: selectedShippingAddress,
        selectedShippingMethod: checkout?.shippingMethod,
        openPickAddressForm: openPickAddressForm,
        closeCurrentDialog: closeCurrentDialog,
        currentOpenDialog: currentOpenDialog,
        saveShippingError: saveShippingError,
        shippingMethodError: shippingMethodError,
        openAddAddressForm: openAddAddressForm,
        isCheckoutShippingAddressValid: isCheckoutShippingAddressValid,
        isAddAddressDialogOpen: isAddAddressDialogOpen,
        isPickAddressDialogOpen: isPickAddressDialogOpen,
        isAddressDialogOpen: isAddressDialogOpen,
        isShippingLoading: isShippingLoading,
        setIsShippingLoading: setIsShippingLoading,
        addressToEdit: addressToEdit,
        isValidationShippingAddressField: isValidationShippingAddressField,
      }}>
      {children}
    </CheckoutShippingAddressContext.Provider>
  );
}

export const useCheckoutShippingAddressContext = () => {
  const context = useContext(CheckoutShippingAddressContext);

  if (!context)
    throw new Error(
      'CheckoutShippingAddressContext was used outside of the CheckoutShippingAddressProvider',
    );

  return context;
};
