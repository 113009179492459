import React from 'react';

interface Props {
     component: any,
     onChange: (e) => void,
}

const StripeInput = React.forwardRef(( props: Props, inputRef ) => {
     
     const { component: Component, onChange, ...other } = props;
     const elementRef = React.useRef<HTMLDivElement>(null);
     
     React.useImperativeHandle(inputRef, () => ({
       focus: () => {
         if (elementRef && elementRef.current) {
           elementRef.current.focus;
         }
       },
     }));

     const handleOnChange = (e) => {
          onChange && onChange(e);
     };
     
     return (
          <Component
               onReady={element => (elementRef.current = element)}
               onChange={(e) => handleOnChange(e)}
               {...other}
          />
     )
});

export default StripeInput;