import { productSearchIndex } from '@config/algolia';
import { NewProduct, NewVariant } from '@interfaces';

export function getProducts(
  objectIDs: readonly string[],
): Promise<NewProduct[]> {
  return productSearchIndex
    .getObjects(objectIDs)
    .then((value) => {
      if (value) {
        return value.results as NewProduct[];
      }
      return null;
    })
    .catch((e) => {
      return null;
    });
}

export function getProduct(objectId: string): Promise<NewProduct> {
  return productSearchIndex
    .getObject(objectId)
    .then((value) => {
      if (value) {
        return value as NewProduct;
      }
      return null;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
}

export function getProductById(id: string): Promise<NewProduct | null> {
  return productSearchIndex
    .search(``, { filters: 'id:"' + id + '"' })
    .then((data) => {
      if (data && data.hits && data.hits.length) {
        const product: NewProduct = data.hits[0] as unknown as NewProduct;
        product.variants.forEach((variant) => {
          variant.vendor.channel = {
            id: product?.channel_id
          }
          variant.promotion_images = product.promotion_images;
        });
        return product;
      }
      return null;
    });
}
export function getVariant(id: string): Promise<NewVariant | null> {
  return productSearchIndex
    .search(``, { filters: 'variants.id:"' + id + '"' })
    .then((data) => {
      if (data && data.hits && data.hits.length) {
        const product: NewProduct = data.hits[0] as unknown as NewProduct;
        const variant: NewVariant = product.variants.find(
          (value) => value.id == id,
        );
        if (variant) {
          variant.promotion_images = product.promotion_images;
        }
        return variant;
      }
      return null;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
}

type GetAlgoliaVariantsInput = {
  // variantId
  id: string;
  productId: string;
  line: {
    isInclude: boolean;
    note?: string;
    quantity: number
  }
}

/**
 *
 * @param productAndVariantIDs - an object holds product id vs variants ids.
 * Gets the products from Algolia.
 * Iterate over (Algolia) product's variants and check if an id from the variants ids exist.
 * Remove the variant from the products.variants list and keep iterating for the remained
 * @returns
 */
export async function getVariants(productIdAndVariants: Map<string, GetAlgoliaVariantsInput>) {
  try {
    const algoliaProducts: NewProduct[] = await getVariantsByObjectIDs(
      Object.keys(productIdAndVariants),
    );
    return await extractVariantsFromObjects(
      algoliaProducts,
      productIdAndVariants,
    );
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function getVariantsByObjectIDs(ids: string[]) {
  const products: NewProduct[] = await (
    await productSearchIndex.getObjects(ids)
  ).results as NewProduct[];
  products.forEach((product) => {
    if (product)
      product.variants.forEach((variant) => {
        variant.product_promotion_images = product.promotion_images;
        variant.category = {
          name: product.categories[1]?.name
            ? product.categories[1].name
            : product.categories[0]?.name,
          imgSrc: product.categories[1]?.background_image
            ? product.categories[1].background_image
            : product.categories[0]?.background_image,
        };
      });
  });
  return products;
}

export async function extractVariantsFromObjects(
  algoliaObjects:  NewProduct[],
  productIdAndVariants: Map<string, GetAlgoliaVariantsInput>,
) {
  let extractedVariants: NewVariant[] = [];
  for (let product of algoliaObjects) {
    if (!product) continue;
    const productVariants: NewVariant[] = productIdAndVariants[product.id];
    let algoliaObjectsVariants = product.variants;
    const variants = await getVariantsFromSingleObjectBy(
      algoliaObjectsVariants,
      productVariants,
    );
    extractedVariants = extractedVariants.concat(variants);
  }
  return extractedVariants;
}

async function getVariantsFromSingleObjectBy(
  algoliaObjectsVariants: NewVariant[],
  productVariants: NewVariant[],
) {
  const variants = [];
  // For each product variant to retreive to with algolia variant.
  for (let productVariantToRetreive of productVariants) {
    for (let i = 0; i < algoliaObjectsVariants.length; i++) {
      const variant = algoliaObjectsVariants[i];
      if (variant.id === productVariantToRetreive.id) {
        variant.line = productVariantToRetreive.line;
        variants.push(variant);
        algoliaObjectsVariants = algoliaObjectsVariants.filter(
          (algoliaObjectVariant) => algoliaObjectVariant.id !== variant.id,
        );
        break;
      }
    }
  }
  return variants;
}
