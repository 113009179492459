import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { CountryCode, DeliveryTypeEnum } from 'teddly-sdk';
import tw, { styled } from 'twin.macro';
import style from '../../components/style.module.scss';
import { AddressInput } from '@app/user/address';
import { TextField as TextFieldStripe } from '@mui/material';
import { FormSubHeading } from '@components/Atoms/Typography';
import { NewPaymentDetails } from '@interfaces';
import StateSelectDropdown from '../../components/StateSelectDropdown';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';

// import { format } from '@utils/format';

import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import { ids } from '../../../../../../cypress/pages/account-setting/PaymentMethodFormPage.cy';
import { ids as addressIds } from '../../../../../../cypress/pages/account-setting/AddressFormPage.cy';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from '@stripe/react-stripe-js';
import { StripeCardElement, StripeCardNumberElement } from '@stripe/stripe-js';
import StripeInput from '../../utils/stripeInput';
import AutocompleteAddress from '../../components/Address/AutocompleteAddress';
import { getDefaultIphone } from '@utils';
import {
  Switch,
  Dialog,
  Button,
  InputBase,
} from '@teddly/teddly-ui-components';
import styles from '@styles/form.module.scss';
import {
  FIRST_NAME_MAX_LENGTH,
  LAST_NAME_MAX_LENGTH,
} from '@config/validation';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';

enum PaymentFieldNames {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  STREET_ADDRESS1 = 'streetAddress1',
  STREET_ADDRESS2 = 'streetAddress2',
  POSTAL_CODE = 'postalCode',
}

type InputType = NewPaymentDetails & IAddress;

type PaymentDetailsProps = {
  paymentDetails?: InputType;
  onClose?: () => void;
  isOpen?: boolean;
  onSubmit?: (data: {
    card?: StripeCardElement | StripeCardNumberElement;
    cardInputData?: NewPaymentDetails;
    billingAddress?: IAddress;
  }) => void;
  loading: boolean;
};

const emptyPaymentDetails: InputType = {
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  firstName: '',
  lastName: '',
  city: '',
  streetAddress1: '',
  postalCode: '',
  streetAddress2: '',
  country: {
    code: CountryCode.US,
    country: 'United States of America',
  },
  countryArea: '',
};
type ValidStripe = {
  cardNumber: boolean;
  expiryDate: boolean;
  cvv: boolean;
};

export default function StripePaymentDetailsForm({
  paymentDetails,
  onClose,
  isOpen = false,
  onSubmit = () => null,
  loading = false,
}: PaymentDetailsProps) {
  const text = 'Add';
  const { selectedShippingAddress, isCheckoutShippingAddressValid } =
    useCheckoutShippingAddressContext();
  const { checkout } = useCheckoutFlowContext();
  const [isAddressSame, setIsAddressSame] = useState<boolean>(false);
  const {
    register,
    unregister,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, touchedFields, isValid },
  } = useForm<InputType>({ mode: 'onChange' });

  const [countryArea, setCountryArea] = useState(
    emptyPaymentDetails.countryArea,
  );

  const cardNumberRef = useRef();

  // const [isFieldsValids, setFieldsValids] = useState(true);

  //   const [formattedCardNumber, setFormattedCardNumber] = useState('');

  // const [isCardNumberValid, setCardNumberValid] = useState(true);
  // const [isCardExpiryDateValid, setCardExpiryDateValid] = useState(true);

  // const [expiryDate, setExpiryDate] = useState('');

  // const handleExpiryDateChange = (e) => {
  //   const inputValue = e.target.value;
  //   const sanitizedValue = inputValue.replace(/\D/g, ''); // Remove non-digit characters

  //   if (sanitizedValue.length <= 4) {
  //     let formattedValue = sanitizedValue;

  //     if (sanitizedValue.length > 2) {
  //       formattedValue = `${sanitizedValue.slice(
  //         0,
  //         2
  //       )} / ${sanitizedValue.slice(2)}`;
  //     }

  //     if (formattedValue.length === 7) {
  //       const [month, year] = formattedValue.split(' / ');
  //       const yearFromUser = `20${year}`;
  //       const currentYear = new Date().getFullYear();

  //       if (
  //         month.length === 2 &&
  //         year.length === 2 &&
  //         parseInt(month) >= 1 &&
  //         parseInt(month) <= 12 &&
  //         parseInt(yearFromUser) >= currentYear
  //       ) {
  //         setCardExpiryDateValid(true);
  //         // setFieldsValids(isCardExpiryDateValid && isCardNumberValid);
  //         setStripeValidation((prevState) => ({
  //           ...prevState,
  //           expiryDate: true,
  //         }));
  //       } else {
  //         setCardExpiryDateValid(false);
  //         setStripeValidation((prevState) => ({
  //           ...prevState,
  //           expiryDate: false,
  //         }));
  //       }
  //     }

  //     setExpiryDate(formattedValue);
  //   }
  // };

  // const onCardNumberChange = (value: string) => {
  //   setStripeValidation((prevState) => ({
  //     ...prevState,
  //     cardNumber: value.length >= 19,
  //   }));
  // };

  // const onCardCvvChange = (value: string) => {
  //   setStripeValidation((prevState) => ({
  //     ...prevState,
  //     cvv: value.length == 3,
  //   }));
  // };

  const [stripeValidation, setStripeValidation] = useState<ValidStripe>({
    cardNumber: false,
    expiryDate: false,
    cvv: false,
  });
  const elements = useElements();

  const onStripeChange = (e, type: string) => {
    setStripeValidation((prevState) => ({
      ...prevState,
      [type]: e.complete,
    }));
  };

  const isStripeValid = Object.values(stripeValidation).every(
    (item: boolean) => {
      return item == true;
    },
  );

  useEffect(() => {
    if (isAddressSame) {
      unregister('city');
      unregister('streetAddress1');
      unregister('postalCode');
      unregister('streetAddress2');
      unregister('country');
      unregister('countryArea');
      unregister('phone');
    }
  }, [isAddressSame, isCheckoutShippingAddressValid]);

  useEffect(() => {
    if (
      !isAddressSame &&
      isCheckoutShippingAddressValid &&
      checkout.deliveryType == DeliveryTypeEnum.SHIPPING
    ) {
      setIsAddressSame(isCheckoutShippingAddressValid);
    }
  }, [isCheckoutShippingAddressValid]);

  const handleClose = () => {
    reset(emptyPaymentDetails);
    onClose();
  };
  const onSelectAddress = (selectAddressInput: AddressInput) => {
    setValue('postalCode', selectAddressInput.postalCode, {
      shouldValidate: true,
    });
    setValue('streetAddress1', selectAddressInput?.streetAddress1, {
      shouldValidate: true,
    });
    setValue('city', selectAddressInput.city, { shouldValidate: true });
    setCountryArea(selectAddressInput.countryArea);
  };

  const getFormSection = (section: JSX.Element, toLeft?: boolean) => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: toLeft ? 'left' : 'center',
        gap: '1.5rem',
      }}
      // css={{
      //   ...tw`gap-6 grid grid-cols-1 tablet:flex flex-wrap justify-center 	`,
      // }}
    >
      {section}
    </div>
  );

  const Submit = (data: InputType) => {
    const billingAddress = isAddressSame
      ? {
          firstName: selectedShippingAddress.firstName,
          lastName: selectedShippingAddress.lastName,
          city: selectedShippingAddress.city,
          streetAddress1: selectedShippingAddress.streetAddress1,
          postalCode: selectedShippingAddress.postalCode,
          streetAddress2: selectedShippingAddress.streetAddress2,
          phone: selectedShippingAddress?.phone,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea: selectedShippingAddress.countryArea,
        }
      : {
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.city,
          streetAddress1: data.streetAddress1,
          postalCode: data.postalCode,
          streetAddress2: data.streetAddress2,
          phone: data?.phone,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea,
        };

    const number = elements.getElement(PaymentFieldNames.CARD_NUMBER);
    onSubmit({
      card: number,
      billingAddress: billingAddress,
    });

    reset(emptyPaymentDetails);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={`${text} Payment Methods`}
      onClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      footerProps={{
        children: [
          <Button
            data-testid={ids.addCardButton}
            disabled={
              loading ||
              !isValid ||
              (!isAddressSame && !countryArea) ||
              !isStripeValid
            }
            loading={loading}
            type="submit"
            onClick={handleSubmit(Submit)}
            title={`${text} Card`}
          />,
        ],
      }}>
      <form onSubmit={handleSubmit(Submit)}>
        <div
          style={{ maxWidth: '800px' }}
          css={{
            ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center	`,
          }}>
          {getFormSection(
            <>
              <Controller
                name={PaymentFieldNames.FIRST_NAME}
                control={control}
                rules={{
                  maxLength: {
                    value: FIRST_NAME_MAX_LENGTH,
                    message: `First name is more than ${FIRST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: 'First name is required!',
                }}
                defaultValue={paymentDetails?.firstName}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    data-testid={ids.firstNameInput}
                    onChange={onChange}
                    value={value}
                    name={PaymentFieldNames.FIRST_NAME}
                    label="First Name"
                    // css={tw`w-[300px]	tablet:w-[360px] `}
                    footnote={error?.message}
                    errored={error?.message}
                  />
                )}
              />
              <Controller
                name={PaymentFieldNames.LAST_NAME}
                control={control}
                rules={{
                  maxLength: {
                    value: LAST_NAME_MAX_LENGTH,
                    message: `Last name is more then ${LAST_NAME_MAX_LENGTH} Characters`,
                  },
                  required: 'Last name is required!',
                }}
                defaultValue={paymentDetails?.lastName}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <InputBase
                    fullWidth
                    data-testid={ids.lastNameInput}
                    onChange={onChange}
                    value={value}
                    name={PaymentFieldNames.LAST_NAME}
                    label="Last Name"
                    // css={tw`w-[300px]	tablet:w-[360px] `}
                    footnote={error?.message}
                    errored={error?.message}
                  />
                )}
              />
            </>,
          )}
          {getFormSection(
            <>
              <TextFieldStripe
                ref={cardNumberRef}
                fullWidth
                // css={tw`w-[300px]	tablet:w-[360px] `}
                variant="outlined"
                data-testid={ids.cardNumberInput}
                name={PaymentFieldNames.CARD_NUMBER}
                required={true}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardNumberElement,
                    onChange: (e) =>
                      onStripeChange(e, PaymentFieldNames.CARD_NUMBER),
                  },
                }}
                // label={'1234 1234 1234 1234'}
                // hideTopLabel={true}
                // data-testid={ids.cardNumberInput}
                // onChange={(e) => {
                //   const input = e.target.value;
                //   const formattedInput = format(input); // Format the credit card number
                //   setFormattedCardNumber(formattedInput);
                //   input.length < 19 && input.length > 5
                //     ? setCardNumberValid(false)
                //     : setCardNumberValid(true);
                //   onCardNumberChange(input);
                // }}
                // inputComponent={StripeInput}
                // component={CardNumberElement}
                // value={formattedCardNumber}
                // errored={!isCardNumberValid}
              />

              {/* <InputBase */}
              <TextFieldStripe
                name={PaymentFieldNames.EXPIRY_DATE}
                variant="outlined"
                data-testid={ids.expiryDateInput}
                // label={'MM / YY'}
                // hideTopLabel={true}
                css={tw`w-[300px]	tablet:w-[170px] `}
                required={true}
                InputLabelProps={{ shrink: false }}
                // value={expiryDate}
                // onChange={handleExpiryDateChange}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardExpiryElement,
                    onChange: (e) =>
                      onStripeChange(e, PaymentFieldNames.EXPIRY_DATE),
                  },
                }}
                // errored={!isCardExpiryDateValid}
              />
              {/* <InputBase */}
              <TextFieldStripe
                name={PaymentFieldNames.CVV}
                data-testid={ids.cvvInput}
                variant="outlined"
                required={true}
                // label={'CVV'}
                // hideTopLabel={true}
                // maxLength={3}
                // onChange={(e) => onCardCvvChange(e.target.value)}
                css={tw`w-[300px]	tablet:w-[170px] `}
                InputLabelProps={{ shrink: false }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    component: CardCvcElement,
                    onChange: (e) => onStripeChange(e, PaymentFieldNames.CVV),
                  },
                }}
              />
            </>,
          )}
          {isCheckoutShippingAddressValid &&
            checkout.deliveryType == DeliveryTypeEnum.SHIPPING && (
              <div className={styles.line}>
                <Switch
                  data-testid={ids.billingAddressAsShippingToggle}
                  id="isAddressSame"
                  label="Billing address same as shipping"
                  checked={isAddressSame}
                  onChange={() => setIsAddressSame(!isAddressSame)}
                />
              </div>
            )}
          {(!isAddressSame || !isCheckoutShippingAddressValid) && (
            <>
              <div
                css={{
                  ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center`,
                }}>
                <FormSubHeading css={tw`mt-3 uppercase w-full text-center	`}>
                  Billing Info
                </FormSubHeading>
                {getFormSection(
                  <>
                    <PatternFormat
                      format={'1(###) ###-####'}
                      allowEmptyFormatting={true}
                      mask="_"
                      css={tw`w-[300px]	tablet:w-[220px] `}
                      title="Phone"
                      className="maxWidth"
                      onValueChange={(valueObj) => {
                        const { value } = valueObj;
                        setValue('phone', value);
                      }}
                      defaultValue={getDefaultIphone(paymentDetails?.phone)}
                      type="tel"
                      data-testid={addressIds.phoneInput}
                      fullWidth
                      // message={errors?.phone?.message}
                      // error={errors?.phone?.message}
                      {...register('phone', {
                        required: 'Phone is required !',
                        pattern: {
                          value:
                            /\+?\d{1,9}?[-.\s]?\(?\d{1,9}?\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}[-.\s]?\d{1,9}/g,
                          message: 'Please enter valid Phone Number !',
                        },
                      })}
                      customInput={InputBase}
                    />
                    <InputBase
                      label="Search for an address"
                      fullWidth
                      inputComponent={
                        <AutocompleteAddress
                          label="Search for an address"
                          dataTestId={addressIds.searchAddressInput}
                          // css={tw`w-[300px] max-w-[500px]`}
                          // className={style.autocomplete}
                          onSelectAddress={onSelectAddress}
                        />
                      }
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS1}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          data-testid={addressIds.streetInput1}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.STREET_ADDRESS1}
                          label="Street Address"
                          // css={tw`w-[300px]	tablet:w-[470px] `}
                          defaultValue={paymentDetails?.streetAddress1}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS2}
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          onChange={onChange}
                          data-testid={addressIds.apartmentInput}
                          value={value}
                          name={PaymentFieldNames.STREET_ADDRESS2}
                          label="Apt, suite. (optional)"
                          // css={tw`w-[300px] tablet:w-[255px] `}
                          defaultValue={paymentDetails?.streetAddress2}
                          {...register('streetAddress2')}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          value={value}
                          data-testid={addressIds.cityInput}
                          onChange={onChange}
                          disabled={true}
                          label="city"
                          variant="filled"
                          // css={tw`w-[300px] tablet:w-[255px] `}
                          errored={!!error}
                          helperText={error ? error.message : null}
                          type="text"
                        />
                      )}
                    />
                    <InputBase
                      fullWidth
                      // label="state"
                      // placeholder={"state"}
                      className={style.selectInputBase}
                      inputWrapperClassName={style.selectInputWrapperClassName}
                      disabled
                      inputComponent={
                        <StateSelectDropdown
                          // data-testid={addressIds.stateInput}
                          deleteSelectSign={true}
                          disabled={true}
                          value={countryArea}
                          onChange={setCountryArea}
                        />
                      }
                    />
                    <Controller
                      name={PaymentFieldNames.POSTAL_CODE}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          data-testid={addressIds.zipCodeInput}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.POSTAL_CODE}
                          label="Zip Code"
                          // css={tw`w-[300px] tablet:w-[250px] `}
                          defaultValue={paymentDetails?.postalCode}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </Dialog>
  );
}
