import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import useDisableScroll from '@hooks/useDisableScroll';
import {
  Dialog,
  Button,
  InputBase,
  ButtonVariantEnum,
} from '@teddly/teddly-ui-components';
// import styles from '@styles/form.module.scss';
import styles from '@styles/dialog.module.scss';

import classNames from 'classnames';

interface FormDataType {
  password?: string;
  confirmPassword?: string;
}
type Props = {
  resetPassword?: (data: FormDataType) => Promise<void>;
  isOpen?: boolean;
  fieldErrorMessages?: {
    [key in keyof FormDataType]: string;
  };
  onClose?: () => void;
};

const RESET_PASSWORD_FORM_ID = 'reset-password';

export default function ResetPasswordDialog({
  resetPassword,
  isOpen,
  fieldErrorMessages = {
    password: '',
    confirmPassword: '',
  },
  onClose,
}: Props): JSX.Element {
  useDisableScroll(isOpen);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm<FormDataType>();
  const handleValidate = (value) => {
    if (value) {
      return true;
    }
  };
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      await resetPassword(data);
    } catch (e) {
      console.error('Something went wrong!');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Dialog
      isOpen={isOpen}
      css={tw`z-40  `}
      title={'Login to your account'}
      onClose={onClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <form
        id={RESET_PASSWORD_FORM_ID}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.dialogBodySectionsContainer}
      >
        <div
          className={classNames(styles.dialogTitle, styles.dialogBodySection)}
        >
          Enter a new password
        </div>
        <div className={styles.dialogBodySection}>
          <Controller
            name="password"
            control={control}
            defaultValue=""
            rules={{
              required: 'Password is required !',
              validate: (value) => handleValidate(value),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputBase
                type="password"
                label="New Password"
                view={false}
                value={value}
                fullWidth
                onChange={onChange}
                footnote={
                  fieldErrorMessages.password ? (
                    <span style={{ color: '#EF2D2D' }}>
                      {fieldErrorMessages.password}
                    </span>
                  ) : (
                    error?.message
                  )
                }
                error={error?.message}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: 'Re-enter new Password is required !',
              validate: (value) => handleValidate(value),
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <InputBase
                type="password"
                label="Re-enter new Password"
                view={false}
                onChange={onChange}
                value={value}
                fullWidth
                footnote={
                  fieldErrorMessages.confirmPassword ? (
                    <span style={{ color: '#EF2D2D' }}>
                      {fieldErrorMessages.confirmPassword}
                    </span>
                  ) : (
                    error?.message
                  )
                }
                errored={error?.message}
              />
            )}
          />
        </div>
      </form>
      <Dialog.Footer>
        <Button
          variant={ButtonVariantEnum.NEGATIVE}
          disabled={!!loading}
          title={'Cancel'}
          onClick={onClose}
        />
        <Button
          form={RESET_PASSWORD_FORM_ID}
          loading={loading}
          disabled={!!loading}
          type="submit"
          title={loading ? 'Loading...' : 'Reset Password'}
        />
      </Dialog.Footer>
    </Dialog>
  );
}
