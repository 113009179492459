import { useState, useEffect } from 'react';

//* SCREEN SIZES
const DESKTOP = 1920;
const LARGE_TABLETS = 960;

export const scrollToTheTopOfTheElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) element.scrollTop = 0;
};

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  };

  const getIsLargeTablet = () => {
    return hasWindow ? window.innerWidth <= LARGE_TABLETS : null;
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isLargeTablet, setIsLargeTablet] = useState(getIsLargeTablet());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => {
        setWindowDimensions(getWindowDimensions());
        setIsLargeTablet(getIsLargeTablet());
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);

  return { windowDimensions, isLargeTablet };
}
