import { useEffect } from 'react';

import { getTeddlySdkApi } from '@config/teddly-sdk';
import { useNetworkState } from '@hooks/useNetworkState';
import { PaymentSource } from '@interfaces';
import { StoredPaymentSourcesQuery, useAuth } from 'teddly-sdk';
import { StoredPaymentSources_me_storedPaymentSources } from 'teddly-sdk/lib/queries/gqlTypes/StoredPaymentSources';
import { useUserAddressesContext } from '@context/UserAddressContext';

export type StripeCardData =
  StoredPaymentSourcesQuery['me']['storedPaymentSources'][0];

export function useLoadAcceptJS() {
  useEffect(() => {
    const script = document.createElement('script');
    const acceptClientURL = process.env.NEXT_PUBLIC_PAYMENT_CLIENT;
    if (!acceptClientURL) {
      return alert('no accept js client in .env');
    }
    script.src = acceptClientURL;
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // eslint-disable-next-line no-console
      console.log(
        'AuthorizeNetPaymentGateway: script loaded. window.Accept is now available',
      );
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);
}

export function usePaymentSources() {
  const { state, setLoading, setError, setCompleted } =
    useNetworkState<StripeCardData[]>();
  const { userAddresses } = useUserAddressesContext();
  const { user } = useAuth();
  const userId = user ? user.id : null;

  const fetchSource = async () => {
    setLoading();
    const { teddlyApi } = await getTeddlySdkApi();
    const { data, dataError, functionError } =
      await teddlyApi.auth.getUserStoredPaymentSources();
    if (dataError || functionError) {
      setError(
        dataError?.error ||
          functionError?.error ||
          'Something went wrong while fetching user cards',
      );
    } else if (data && data.length) {
      setCompleted(data as StripeCardData[]);
      return data;
    } else {
      setCompleted(null);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchSource();
    }
  }, [userId]);

  return { ...state, refetch: fetchSource };
}
