import { ComponentProps } from 'react';

import { CheckThin } from '@anyvision/anv-icons';
import tw, { styled } from 'twin.macro';

import Button from '@components/ui-lib/button/Button';
import { css } from '@emotion/react';

export const DetailHeading = styled.h4(tw`
  text-dark
  font-medium
  pb-2
  `);

export const ErrorMessage = styled.p(tw`
  text-coral
  my-4
`);

export const StepViewContainer = styled.div<{ isCompleted: boolean }>(
  ({ isCompleted }) => [
    tw`border border-solid border-bbbbbb rounded pt-[28px] pl-[35px] pr-3 pb-3`,
    isCompleted && tw`border-dark bg-gray-hover`,
  ]
);

const TextBtnDark = styled(Button)(
  tw`font-poppins 
  text-dark 
  py-1.5 px-1 text-sm 
  font-semibold 
  capitalize 
  focus:outline-none 
  focus:shadow-none`
);

export function StepChangeBtn(props: ComponentProps<typeof TextBtnDark>) {
  return <TextBtnDark variant="text" {...props} />;
}

export function StepCompleted({ isCompleted }: { isCompleted: boolean }) {
  if (isCompleted)
    return (
      <div tw="flex justify-center items-center">
        <div
          css={[
            tw`bg-[#33D1A7] rounded-full flex justify-center items-center h-[40px] w-[40px]`,
            css`
              @media (min-width: 640px) {
                height: 55px;
                width: 55px; 
              }      
            `,
          ]}
        >
          <CheckThin css={[tw`h-[24px] w-[24px] fill-white`,
            css`
            @media (min-width: 640px) {
              height: 34px;
              width: 34px; 
            }      
          `,
      
        ]} />
        </div>
      </div>
    );

  return (
    <div
      css={tw`bg-[#f6f6f6] rounded-full  flex justify-center items-center h-[55px] w-[55px]`}
    />
  );
}
