import { DialogIDs, Dialog } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: field,
  componenets,
} = utilsData.elementsDataTestIDs;

export class SignUpDialogIDs extends DialogIDs {
  appleSocialLoginButtonID: string;
  googleSocialLoginButtonID: string;
  openAccountSubmitButtonID: string;
  backToLoginButtonID: string;
  emailInputID: string;
  firstNameInputID: string;
  lastNameInputID: string;
  phoneNumberInputID: string;
  addressInputID: string;
  passwordInputID: string;

  constructor() {
    super(componenets.dialogs.signUp);

    this.appleSocialLoginButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.appleSocialLogin,
    );
    this.googleSocialLoginButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.googleSocialLogin,
    );
    this.emailInputID = TestIDFormat.inputField(this.fullPrefix, field.email);
    this.firstNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.firstName,
    );
    this.lastNameInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.lastName,
    );
    this.phoneNumberInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.phoneNumber,
    );
    this.addressInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.address,
    );
    this.passwordInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.password,
    );
    this.openAccountSubmitButtonID = TestIDFormat.button(
      this.fullPrefix,
      `${btn.openAccount}-${btn.submit}`,
    );
    this.backToLoginButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.backToLogin,
    );
  }
}

export class SignUpDialog extends Dialog<SignUpDialogIDs> {
  constructor() {
    super(SignUpDialogIDs);
  }

  get appleSocialLoginButton() {
    return cy.get(
      TestIDFormat.fullPrefix(this.testIDs.appleSocialLoginButtonID),
    );
  }

  get googleSocialLoginButton() {
    return cy.get(
      TestIDFormat.fullPrefix(this.testIDs.googleSocialLoginButtonID),
    );
  }

  get emailInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.emailInputID));
  }

  get firstNameInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.firstNameInputID));
  }

  get lastNameInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.lastNameInputID));
  }

  get phoneNumberInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.phoneNumberInputID));
  }

  get addressInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.addressInputID));
  }

  get passwordInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.passwordInputID));
  }

  get openAccountSubmitButton() {
    return cy.get(
      TestIDFormat.fullPrefix(this.testIDs.openAccountSubmitButtonID),
    );
  }

  get backToLoginButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.backToLoginButtonID));
  }
}
