import React, { useEffect, useState } from 'react';
import { UserInfoBlock, Button } from '@teddly/teddly-ui-components';
import { useForm } from 'react-hook-form';
import tw, { styled } from 'twin.macro';
import InfoOutlined from '@mui/material/Icon';
import { css } from '@emotion/react';
import moment from 'moment';
import { InputBase, Tooltip } from '@teddly/teddly-ui-components';

type PaymentDetailsProps = {
  loading?: boolean;
  onSubmit: (event) => void;
  error?: string;
  birthdate?: string;
};

interface FormDataType {
  day?: number;
  month?: number;
  year?: number;
}

export const RemoveInputArrows = css`
  max-width: 100px;
`;

const Description = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #8d9091;
`;

const tooltipMessage =
  "By continuing, you agree that an adult over the age of 21 with valid driver's license or state ID will be present to accept this order and that the recipient will not be intoxicated. Your personal shopper will ask to see the recipient's ID regardless of age. You agree that alcohol is not returnable and is not being purchased for resale.";

export default function AgeRestrictionForm({
  loading = false,
  onSubmit = () => null,
  birthdate = null,
  error = null,
}: PaymentDetailsProps) {
  const {
    setValue,
    getValues,
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormDataType>();

  const [isValid, setIsValid] = useState({
    day: true,
    month: true,
    year: true,
  });
  const splitBirthdate = birthdate ? birthdate?.split('-') : null;

  useEffect(() => {
    if (splitBirthdate?.length == 3) {
      setValue('month', Number(splitBirthdate[1]), {
        shouldDirty: true,
      });
      setValue('day', Number(splitBirthdate[2]), {
        shouldDirty: true,
      });
      setValue('year', Number(splitBirthdate[0]), {
        shouldDirty: true,
      });
    }
  }, []);

  const handleDayValidate = (value) => {
    return value > 0 && value <= 31;
  };
  const handleMonthValidate = (value) => {
    return value > 0 && value <= 12;
  };
  const handleYearValidate = (value) => {
    return value > moment().year() - 120 && value <= moment().year();
  };
  const limit = (val, max) => {
    if (!val || !max) return '0';
    if (val.length === 1 && val[0] > max[0]) {
      val = `0${val}`;
    }
    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';
      } else if (val > max) {
        val = max;
      }
    }
    return val;
  };
  const disable = () => {
    return (
      !isValid.month ||
      !isValid.day ||
      !isValid.year ||
      !dirtyFields.month ||
      !dirtyFields.day ||
      !dirtyFields.year
    );
  };

  return (
    <UserInfoBlock
      isWithShading
      title={'21+ age requirement'}
      boldTitle
      description={
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '100%',
          }}
          onSubmit={handleSubmit(onSubmit)}>
          <Tooltip
            style={{
              maxWidth: '150px',
            }}
            content={tooltipMessage}>
            <Description>
              {"Enter your date of birth to verify you're 21+"}
            </Description>
          </Tooltip>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              gap: '12px',
              width: '100%',
            }}>
            <InputBase
              type="number"
              placeholder="Month"
              value={getValues('month')}
              css={tw`w-full	tablet:w-[112px] `}
              variant="outlined"
              {...register('month', {
                required: 'Month is required!',
                validate: (value) => handleMonthValidate(value),
              })}
              onChange={(event) => {
                const val = Number(
                  limit(event.target.value.substring(0, 2), '12'),
                );
                setValue('month', val, {
                  shouldDirty: true,
                });
                setIsValid((prev) => {
                  return {
                    ...prev,
                    month: handleMonthValidate(val),
                  };
                });
              }}
              errored={!!errors?.month?.message || !isValid.month}
            />
            <InputBase
              type="number"
              placeholder="Day"
              variant="outlined"
              css={tw`w-full	tablet:w-[184px] `}
              value={getValues('day')}
              {...register('day', {
                required: 'Day is required!',
                validate: (value) => handleDayValidate(value),
              })}
              onChange={(event) => {
                const val = Number(
                  limit(event.target.value.substring(0, 2), '31'),
                );
                setValue('day', val, { shouldDirty: true });
                setIsValid((prev) => {
                  return {
                    ...prev,
                    day: handleDayValidate(val),
                  };
                });
              }}
              errored={!!errors?.day?.message || !isValid.day}
            />
            <InputBase
              type="number"
              placeholder="Year"
              variant="outlined"
              css={tw`w-full	tablet:w-[112px] `}
              value={getValues('year')}
              {...register('year', {
                required: 'Year is required!',
                validate: (value) => handleYearValidate(value),
              })}
              onChange={(event) => {
                const val = Number(
                  limit(
                    event.target.value.substring(0, 4),
                    new Date().getFullYear().toString(),
                  ),
                );

                setValue('year', val, { shouldDirty: true });
                setIsValid((prev) => {
                  return {
                    ...prev,
                    year: handleYearValidate(val),
                  };
                });
              }}
              errored={!!errors?.year?.message || !isValid.year}
            />
          </div>

          <div style={{ width: '100%', display:"flex" }}>
            <Button
              type="submit"
              disabled={disable()}
              size={'medium'}
              title={loading ? 'Loadings...' : 'Continue'}
            />
            <p>{error}</p>
          </div>
        </form>
      }
    />
  );
}
