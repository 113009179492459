import { useNetworkState } from '@hooks/useNetworkState';
import { useRoutesContext } from '@context/RoutesContext';
import { useContext, createContext, ReactNode, useEffect } from 'react';
import { CheckoutTypeEnum } from 'teddly-sdk';
import { useCheckoutFlowContext } from './CheckoutFlowContext';
import { useChannelContext } from '@context/ChannelContext';

const FastDeliveryContext = createContext<{
  isFastDelivery: boolean;
  setIsFastDelivery: (fastDelivery: boolean) => void;
}>(null);

export function FastDeliveryProvider({ children }: { children: ReactNode }) {
  const { router, navigateTo } = useRoutesContext();
  const { setLoading, setCompleted } = useNetworkState();
  const { checkout, updateCheckout } = useCheckoutFlowContext();
  const { selectedChannel } = useChannelContext();

  const { now } = router.query;

  const isFastDelivery =
    (now === 'true' || now === '') && selectedChannel?.isSupportFastDelivery;
  useEffect(() => {
    if (
      isFastDelivery &&
      checkout?.type &&
      checkout?.type != CheckoutTypeEnum.FAST_DELIVERY
    )
      checkoutTypeChange(CheckoutTypeEnum.FAST_DELIVERY);
  }, [isFastDelivery]);

  const checkoutTypeChange = async (newType) => {
    setLoading();
    const { dataError } = await updateCheckout({
      checkoutId: checkout?.id,
      type: newType,
    });
    if (!dataError) {
      setCompleted('order update');
    }
  };

  const setIsFastDelivery = () => {
    if (!isFastDelivery && selectedChannel?.isSupportFastDelivery) {
      navigateTo({
        isReplace: true,
        url: {
          query: { ...router.query, now: true },
        },
      });
    } else {
      delete router.query.now;
      router.push(router);
      if (checkout?.type === CheckoutTypeEnum.FAST_DELIVERY)
        checkoutTypeChange(CheckoutTypeEnum.DEFAULT);
    }
  };

  return (
    <FastDeliveryContext.Provider
      value={{
        isFastDelivery,
        setIsFastDelivery: setIsFastDelivery,
      }}>
      {children}
    </FastDeliveryContext.Provider>
  );
}

export const useFastDeliveryContext = () => {
  const context = useContext(FastDeliveryContext);

  if (!context)
    throw new Error(
      'FastDeliveryContext was used outside of the FastDeliveryProvider',
    );

  return context;
};
