import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/react";
import { getUserDetails } from "./getUserDetails";

export const SentryErrorLink = onError(({ response,graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      Sentry.withScope(function (scope) {
        const stacktrace = extensions?.exception?.stacktrace?.toString();
        scope.setExtra("stacktrace", stacktrace);
        Sentry.captureException(message);
      });
    });
  if (networkError) {
    Sentry.captureException(networkError?.message);
  }
});
