import { NewVariant } from '@interfaces';

interface CartItemsQueueItem {
  [key: string]: NewVariant;
}

export class WaitingCartItemsQueue {
  private static instance: CartItemsQueueItem = {};

  private constructor() {}

  public static getInstance(): WaitingCartItemsQueue {
    return this.instance;
  }
  public static get(variant: NewVariant) {
    if (variant?.id && this.instance) {
      return this.instance[variant.id];
    }
  }
  public static add(variant: NewVariant) {
    if (variant?.id) {
      this.instance[variant?.id] = variant;
    }
  }
  public static isLastVariantAdded(variant: NewVariant): boolean {
    if (variant?.id) {
      const keys = Object.keys(this.instance);
      return keys.indexOf(variant.id) === keys.length - 1;
    }
  }
  public static clear() {
    this.instance = {};
  }
  public static isEmpty(): boolean {
    return !this.instance || Object.keys(this.instance).length === 0;
  }
  public static remove(variant: NewVariant) {
    if (variant?.id && this.instance) {
      delete this.instance[variant.id];
    }
  }
}

export class SendingCartItemsQueue {
  private static instance: CartItemsQueueItem = {};

  private constructor() {}

  public static getInstance(): WaitingCartItemsQueue {
    return this.instance;
  }
  public static get(variant: NewVariant) {
    if (variant?.id && this.instance) {
      return this.instance[variant.id];
    }
  }

  public static add(variants: NewVariant[]) {
    variants?.forEach((variant) => {
      if (variant?.id) {
        this.instance[variant?.id] = variant;
      }
    });
  }
  public static clear() {
    this.instance = {};
  }
  public static isEmpty(): boolean {
    return !this.instance || Object.keys(this.instance).length === 0;
  }
  public static remove(variants: NewVariant[]) {
    if (this.instance) {
      variants?.forEach((variant) => {
        if (variant?.id && this.instance[variant.id]) {
          delete this.instance[variant.id];
        }
      });
    }
  }
}
