import { ComponentProps } from 'react';

import styled from '@emotion/styled';
import { Button as MuiButton } from '@mui/material';
import tw from 'twin.macro';

const StyledButton = styled(MuiButton)(tw`
  px-10
`);

export type ButtonProps = ComponentProps<typeof StyledButton>;

export function Button({ disableElevation = true, ...props }: ButtonProps) {
  return <StyledButton {...props} disableElevation={disableElevation} />;
}

export default Button;
