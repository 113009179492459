import React, { useEffect, useState } from 'react';
import valid from 'card-validator';
import { useForm, Controller } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { AddressInput, CountryCode, DeliveryTypeEnum } from 'teddly-sdk';
import tw from 'twin.macro';
import { FormSubHeading } from '@components/Atoms/Typography';
import { NewPaymentDetails } from '@interfaces';
import StateSelectDropdown from '../../components/StateSelectDropdown';
import { IAddress } from 'teddly-sdk/lib/api/Checkout/types';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';
import { NUMBER_ZIP_CODE } from '../../../../../config/validation/index';
import { getDefaultIphone } from '@utils';
import { ids } from '../../../../../../cypress/pages/account-setting/PaymentMethodFormPage.cy';
import { ids as addressIds } from '../../../../../../cypress/pages/account-setting/AddressFormPage.cy';
import {
  Dialog,
  Button,
  InputBase,
  Switch,
} from '@teddly/teddly-ui-components';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import AutocompleteAddress from '../../components/Address/AutocompleteAddress';
import style from '../../components/style.module.scss';
import { TextField } from '@mui/material';

type InputType = NewPaymentDetails & IAddress;

enum PaymentFieldNames {
  CARD_NUMBER = 'cardNumber',
  EXPIRY_DATE = 'expiryDate',
  CVV = 'cvv',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  STREET_ADDRESS1 = 'streetAddress1',
  STREET_ADDRESS2 = 'streetAddress2',
  POSTAL_CODE = 'postalCode',
}

type PaymentDetailsProps = {
  paymentDetails?: InputType;
  onClose?: () => void;
  isOpen?: boolean;
  onSubmit?: (data: {
    cardInputData: NewPaymentDetails;
    billingAddress: IAddress;
  }) => void;
  loading: boolean;
};

const emptyPaymentDetails: InputType = {
  cardNumber: '',
  cvv: '',
  expiryDate: '',
  firstName: '',
  lastName: '',
  city: '',
  streetAddress1: '',
  postalCode: '',
  streetAddress2: '',
  country: {
    code: CountryCode.US,
    country: 'United States of America',
  },
  countryArea: '',
};

export default function AuthorizeNetPaymentDetailsForm({
  paymentDetails,
  onClose,
  isOpen = false,
  onSubmit = () => null,
  loading = false,
}: PaymentDetailsProps) {
  const text = 'Add';
  const { selectedShippingAddress, isCheckoutShippingAddressValid } =
    useCheckoutShippingAddressContext();
  const [isAddressSame, setIsAddressSame] = useState<boolean>(false);

  const { checkout } = useCheckoutFlowContext();

  const {
    register,
    unregister,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors, touchedFields, isValid },
  } = useForm<InputType>({ mode: 'onChange' });
  const [countryArea, setCountryArea] = useState(
    emptyPaymentDetails.countryArea,
  );

  useEffect(() => {
    if (isAddressSame) {
      unregister('city');
      unregister('streetAddress1');
      unregister('postalCode');
      unregister('streetAddress2');
      unregister('country');
      unregister('countryArea');
    }
  }, [isAddressSame, isCheckoutShippingAddressValid]);
  useEffect(() => {
    if (
      !isAddressSame &&
      isCheckoutShippingAddressValid &&
      checkout.deliveryType == DeliveryTypeEnum.SHIPPING
    ) {
      setIsAddressSame(isCheckoutShippingAddressValid);
    }
  }, [isCheckoutShippingAddressValid]);

  const validateCardNumber = (value) => {
    const valueForValidation = value.replaceAll(' ', '').trim();
    const numberValidation = valid.number(valueForValidation);

    if (valueForValidation.length < 13 || valueForValidation.length > 19) {
      return 'Card number length should be between 13 and 19 digits!';
    }
    if (!numberValidation.isValid) {
      return 'Card number is not valid!';
    }
    return true;
  };

  const handleClose = () => {
    reset(emptyPaymentDetails);
    onClose();
  };

  const Submit = (data: InputType) => {
    const billingAddress = isAddressSame
      ? {
          firstName: selectedShippingAddress.firstName,
          lastName: selectedShippingAddress.lastName,
          city: selectedShippingAddress.city,
          streetAddress1: selectedShippingAddress.streetAddress1,
          postalCode: selectedShippingAddress.postalCode,
          streetAddress2: selectedShippingAddress.streetAddress2,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea: selectedShippingAddress.countryArea,
        }
      : {
          firstName: data.firstName,
          lastName: data.lastName,
          city: data.city,
          streetAddress1: data.streetAddress1,
          postalCode: data.postalCode,
          streetAddress2: data.streetAddress2,
          country: {
            code: CountryCode.US,
            country: 'United States of America',
          },
          countryArea,
        };

    onSubmit({
      cardInputData: {
        cardNumber: data.cardNumber.replaceAll(' ', ''),
        cvv: data.cvv.replaceAll(' ', ''),
        expiryDate: data.expiryDate.replace('/', ''),
        firstName: data.firstName,
        lastName: data.lastName,
      },
      billingAddress: billingAddress,
    });
    reset(emptyPaymentDetails);
  };

  const limit = (val, max) => {
    if (val.length === 1 && val[0] > max[0]) {
      val = `0${val}`;
    }
    if (val.length === 2) {
      if (Number(val) === 0) {
        val = '01';
      } else if (val > max) {
        val = max;
      }
    }
    return val;
  };

  const cardExpiry = (val) => {
    const month = limit(val.substring(0, 2), '12');
    const date = limit(val.substring(2, 4), '31');
    return month + (date.length ? `/${date}` : '');
  };

  const getFormSection = (section: JSX.Element, toLeft?: boolean) => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: toLeft ? 'left' : 'center',
        gap: '1.5rem',
      }}>
      {section}
    </div>
  );

  const handleValuePhoneChange = (valueObj: any) => {
    const { value } = valueObj;
    setValue('phone', value);
  };

  const onSelectAddress = (selectAddressInput: AddressInput) => {
    setValue('postalCode', selectAddressInput?.postalCode, {
      shouldValidate: true,
    });
    setValue('streetAddress1', selectAddressInput?.streetAddress1, {
      shouldValidate: true,
    });
    setValue('city', selectAddressInput?.city, { shouldValidate: true });
    setCountryArea(selectAddressInput?.countryArea);
  };
  return (
    <Dialog
      isOpen={isOpen}
      title={`${text} Payment Methods`}
      onClose={handleClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      footerProps={{
        children: [
          <Button
            disabled={loading || !isValid || (!isAddressSame && !countryArea)}
            type="submit"
            onClick={handleSubmit(Submit)}
            title={`${text} Card`}
          />,
        ],
      }}>
      <form onSubmit={handleSubmit(Submit)}>
        <div
          style={{ maxWidth: '800px' }}
          css={{
            ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center	`,
          }}>
          {getFormSection(
            <>
              <Controller
                name={PaymentFieldNames.FIRST_NAME}
                control={control}
                rules={{
                  required: 'First name is required!',
                }}
                defaultValue={paymentDetails?.firstName}
                render={({ field, fieldState }) => (
                  <InputBase
                    fullWidth
                    data-testid={ids.firstNameInput}
                    {...field}
                    name="firstName"
                    label="First Name"
                    css={tw`w-[300px] tablet:w-[360px] `}
                    message={fieldState.error?.message}
                    error={fieldState.error?.message}
                  />
                )}
              />
              <Controller
                name={PaymentFieldNames.LAST_NAME}
                control={control}
                rules={{
                  required: 'Last name is required!',
                }}
                defaultValue={paymentDetails?.lastName}
                render={({ field, fieldState }) => (
                  <InputBase
                    fullWidth
                    data-testid={ids.lastNameInput}
                    {...field}
                    name="lastName"
                    label="Last Name"
                    css={tw`w-[300px] tablet:w-[360px] `}
                    message={fieldState.error?.message}
                    error={fieldState.error?.message}
                  />
                )}
              />
            </>,
          )}
          {getFormSection(
            <>
              <Controller
                name={PaymentFieldNames.CARD_NUMBER}
                control={control}
                defaultValue={paymentDetails?.cardNumber}
                rules={{
                  required: 'Card number is required !',
                  validate: (value) => validateCardNumber(value),
                }}
                data-testid={ids.cardNumberInput}
                render={({ field }) => (
                  <PatternFormat
                    {...field}
                    format="#### #### #### #### "
                    label={'1234 1234 1234 1234'}
                    css={tw`w-[300px] tablet:w-[360px] `}
                    title="Card Number"
                    customInput={TextField}
                  />
                )}
              />

              <Controller
                defaultValue={paymentDetails?.expiryDate}
                name={PaymentFieldNames.EXPIRY_DATE}
                control={control}
                rules={{
                  required: 'Expiry date is required !',
                }}
                render={({ field }) => (
                  <PatternFormat
                    {...field}
                    format="##/##"
                    label={'MM/YY'}
                    title="Expiry"
                    css={tw`w-[300px]	tablet:w-[200px] `}
                    customInput={TextField}
                  />
                )}
              />
              <Controller
                name={PaymentFieldNames.CVV}
                control={control}
                defaultValue={paymentDetails?.cvv}
                rules={{
                  required: 'CVV is required !',
                }}
                render={({ field }) => (
                  <PatternFormat
                    {...field}
                    format="####"
                    title="CVV"
                    label="CVV"
                    css={tw`w-[300px]	tablet:w-[200px] `}
                    customInput={TextField}
                  />
                )}
              />
            </>,
          )}

          {isCheckoutShippingAddressValid &&
            checkout?.deliveryType == DeliveryTypeEnum.SHIPPING && (
              <Switch
                id="isAddressSame"
                label="Billing address same as shipping"
                checked={isAddressSame}
                onChange={() => setIsAddressSame(!isAddressSame)}
              />
            )}
          {(!isAddressSame || !isCheckoutShippingAddressValid) && (
            <>
              <div
                css={{
                  ...tw`gap-6 grid grid-cols-1 tablet:flex  flex-wrap justify-center`,
                }}>
                <FormSubHeading css={tw`mt-3 uppercase w-full text-center	`}>
                  Billing Info
                </FormSubHeading>
                {getFormSection(
                  <>
                    <PatternFormat
                      format={'1(###) ###-####'}
                      allowEmptyFormatting={true}
                      mask="_"
                      css={tw`w-[300px]	tablet:w-[220px] `}
                      title="Phone"
                      className="maxWidth"
                      onValueChange={handleValuePhoneChange}
                      defaultValue={getDefaultIphone(paymentDetails?.phone)}
                      type="tel"
                      data-testid={addressIds.phoneInput}
                      {...register('phone', {
                        required: 'Phone is required !',
                        pattern: {
                          value:
                            /\+?\d{1,9}?[-.\s]?\(?\d{1,9}?\)?[-.\s]?\d{1,9}[-.\s]?\d{1,9}[-.\s]?\d{1,9}/g,
                          message: 'Please enter valid Phone Number !',
                        },
                      })}
                      customInput={InputBase}
                    />
                    <InputBase
                      label="Search for an address"
                      fullWidth
                      inputComponent={
                        <AutocompleteAddress
                          label="Search for an address"
                          dataTestId={addressIds.searchAddressInput}
                          onSelectAddress={onSelectAddress}
                        />
                      }
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS1}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          data-testid={addressIds.streetInput1}
                          onChange={onChange}
                          value={value}
                          disabled={true}
                          name={PaymentFieldNames.STREET_ADDRESS1}
                          label="Street Address"
                          defaultValue={paymentDetails?.streetAddress1}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                    <Controller
                      name={PaymentFieldNames.STREET_ADDRESS2}
                      control={control}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          onChange={onChange}
                          data-testid={addressIds.apartmentInput}
                          value={value}
                          label="Apt, suite. (optional)"
                          defaultValue={paymentDetails?.streetAddress2}
                          {...register('streetAddress2')}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
                {getFormSection(
                  <>
                    <Controller
                      name="city"
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          value={value}
                          data-testid={addressIds.cityInput}
                          onChange={onChange}
                          disabled={true}
                          label="city"
                          variant="filled"
                          errored={!!error}
                          helperText={error ? error.message : null}
                          type="text"
                        />
                      )}
                    />
                    <InputBase
                      fullWidth
                      className={style.selectInputBase}
                      inputWrapperClassName={style.selectInputWrapperClassName}
                      disabled
                      inputComponent={
                        <StateSelectDropdown
                          data-testid={addressIds.stateInput}
                          deleteSelectSign={true}
                          disabled={true}
                          value={countryArea}
                          onChange={setCountryArea}
                        />
                      }
                    />
                    <Controller
                      name={PaymentFieldNames.POSTAL_CODE}
                      control={control}
                      rules={{ required: true }}
                      defaultValue=""
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          fullWidth
                          data-testid={addressIds.zipCodeInput}
                          onChange={onChange}
                          value={value}
                          name={PaymentFieldNames.POSTAL_CODE}
                          label="Zip Code"
                          defaultValue={paymentDetails?.postalCode}
                          message={error?.message}
                          errored={error?.message}
                        />
                      )}
                    />
                  </>,
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </Dialog>
  );
}
