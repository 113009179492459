/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import utilsData from '../../fixtures/dataTestIds';

export const ids = {
  snackbar: 'snackbar',
  searchInput: 'searchInput',
  splashScreen: 'splash-screen',
  progressBar: 'progress-bar',
  whatsappButton: 'whatsapp-button',
};

export default class PageLayoutPage {
  get searchInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.searchInput));
  }

  get snackbar() {
    return cy.get(Utils.formatDataTestIdSelector(ids.snackbar), {
      timeout: utilsData.timeouts.long,
    });
  }

  get splashScreen() {
    return cy.get(Utils.formatDataTestIdSelector(ids.splashScreen), {
      timeout: utilsData.timeouts.xlong,
    });
  }

  get progressBar() {
    return cy.get(Utils.formatDataTestIdSelector(ids.progressBar), {
      timeout: utilsData.timeouts.xlong,
    });
  }

  progressBarWait() {
    Utils.customWait(utilsData.timeouts.minor);
    return this.progressBar.should('not.exist');
  }

  splashScreenWait() {
    Utils.customWait(utilsData.timeouts.minor);
    return this.splashScreen.should('not.exist');
  }
  typeSearch(search: string) {
    return this.searchInput.type(search, { force: true });
  }

  clickEnterKeyOnSearchInput() {
    return this.searchInput.trigger('keypress', { keyCode: 13 });
  }

  assertSnackbar(expectedMessage: string) {
    return this.snackbar.should('be.visible').contains(expectedMessage, {
      matchCase: false,
    });
  }

  awaitSnackbar() {
    return this.snackbar.should('not.be.visible');
  }
}
