import { PaymentGatewayType } from '@app/payment/types';
import { getAvailablePaymentGateway } from '@utils/paymentGateways';
import { useEffect, useState } from 'react';
import AuthorizeNetAddPaymentCard from '../components/PaymentCard/AuthorizeNetAddPayment';
import { AuthorizeNetAuthData, StripeAuthData } from '../context/checkout-flow';
import StripeAddPaymentCard from '../components/PaymentCard/StripeAddPayment';
import { useCheckoutPaymentContext } from '@context/CheckoutPaymentContext';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';

export function usePaymentGateway() {
  const { authData, handleBillingAddress, handleCardAdded } =
    useCheckoutPaymentContext();
  const { checkout } = useCheckoutFlowContext();
  const [paymentGateway, setPaymentGateway] =
    useState<PaymentGatewayType>(null);

  useEffect(() => {
    if (!paymentGateway && checkout?.availablePaymentGateways) {
      const paymentGateway = getAvailablePaymentGateway(
        checkout?.availablePaymentGateways,
      );
      setPaymentGateway(paymentGateway);
    }
  }, [checkout]);

  const renderPaymentForm = () => {
    if (!authData) return;
    switch (paymentGateway) {
      case PaymentGatewayType.STRIPE:
        return (
          <StripeAddPaymentCard
            onBillingAddress={handleBillingAddress}
            onAddPaymentCard={handleCardAdded}
            authData={authData as StripeAuthData}
          />
        );
      case PaymentGatewayType.AUTHORIZE:
        return (
          <AuthorizeNetAddPaymentCard
            onBillingAddress={handleBillingAddress}
            onAddPaymentCard={handleCardAdded}
            authData={authData as AuthorizeNetAuthData}
          />
        );
      default:
        break;
    }
  };

  return {
    paymentGateway,
    renderPaymentForm,
  };
}
