/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import { BasePageObjectModel } from '../../BasePageObjectModel';

export const ids = {
  // accountActionsButton: 'account-actions',
  // forgotPasswordButton: 'forgot-password',
  dialog: 'forgot-password-dialog',
  heading: 'forgot-password-heading',
  subTitle: 'forgot-password-sub-title',
  emailInput: 'forgot-password-email-input',
  submitButton: 'forgot-password-submit-button',
  cancelButton: 'forgot-password-cancel-button',
  snackbar: 'forgot-password-snackbar',
};

export default class ForgotPasswordDialogPage extends BasePageObjectModel {
  url: string;
  title: string;

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get dialog() {
    return cy.get(Utils.formatDataTestIdSelector(ids.dialog));
  }

  get heading() {
    return cy.get(Utils.formatDataTestIdSelector(ids.heading));
  }

  get emailInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.emailInput));
  }

  get submitButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.submitButton));
  }

  get cancelButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cancelButton));
  }

  // get forgotPasswordButton() {
  //   return cy.get(Utils.formatDataTestIdSelector(ids.forgotPasswordButton));
  // }

  get errorMessage() {
    return cy.get('span');
  }

  get subTitle() {
    return cy.get(Utils.formatDataTestIdSelector(ids.subTitle));
  }

  assertHeadingText(expectedText) {
    return this.heading.should('contain', expectedText);
  }

  typeEmail(email) {
    return this.emailInput.type(email, { force: true });
  }

  clickSubmitButton() {
    return this.submitButton.click({ force: true });
  }
  // clickForgotPasswordButton() {
  //   return this.forgotPasswordButton.click({ force: true });
  // }

  clickCancelButton() {
    return this.cancelButton.click({ force: true });
  }

  assertErrorMessage(field, expectedErrorMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedErrorMessage);
  }

  assertFootnoteMessage(field, expectedMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedMessage);
  }

  assertSubTitleText(expectedText) {
    return this.subTitle.should('contain', expectedText);
  }

  assertSubmitButtonText(expectedText: string) {
    return this.submitButton
      .should('be.visible')
      .should('contain', expectedText);
  }

  assertCancelButtonText(expectedText: string) {
    return this.cancelButton
      .should('be.visible')
      .should('contain', expectedText);
  }
}
