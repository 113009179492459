import { useState, useRef, useEffect } from 'react';
import tw from 'twin.macro';
import { useNetworkState } from '@hooks/useNetworkState';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { InputBase, Button } from '@teddly/teddly-ui-components';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { ids } from '../../../../../cypress/pages/checkout/CheckoutPage.cy';
import DeleteOutline from '@mui/material/Icon';

const GiftCardInput = ({
  status,
  onApply,
  onCancel,
  isHaveGiftCard,
}: {
  status: 'idle' | 'loading' | 'completed' | 'error';
  onApply: (giftCardText: string) => void;
  onCancel: () => void;
  isHaveGiftCard: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const [value, setValue] = useState('');

  return (
    <div>
      <div>
        <InputBase
          fullWidth
          data-testid={ids.giftCardInput}
          disabled={status === 'loading'}
          ref={inputRef}
          placeholder="Paste here"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          trailingTitle={'Apply'}
          trailingTitleOnClick={() => {
            if (value) onApply(value || '');
          }}
        />
      </div>
    </div>
  );
};

export default function GiftCard() {
  const { addPromoCode, removeGiftCard, checkout, loaded } =
    useCheckoutFlowContext();
  const [isOpen, setIsOpen] = useState(true);
  const { state, setCompleted, setError, setIdle } = useNetworkState<{
    last4CodeChars: string;
    id: string;
  }>();
  const isHaveGiftCard = checkout && checkout.giftCards?.length > 0;
  const { addSnackbar, closeSnackbar, setLoading } = usePageLayoutContext();
  const [isUpdete, setIsUpdete] = useState(false);
  const success = state.status === 'completed';

  useEffect(() => {
    if (isHaveGiftCard) {
      setIsOpen(true);
      setCompleted({
        last4CodeChars: checkout.giftCards[0].last4CodeChars,
        id: checkout.giftCards[0].id,
      });
    }
  }, [isHaveGiftCard]);

  useEffect(() => {
    if ((isUpdete && success) || state.status === 'error')
      addSnackbar({
        id: success ? 'completed' : 'error',
        onClose: () => closeSnackbar(success ? 'completed' : 'error'),
        message: success
          ? `Gift card Applied: ${state.result.last4CodeChars}`
          : state.error,
        variant: success ? SnackbarVariant.SUCCESS : SnackbarVariant.ERROR,
      });
  }, [state.status, isUpdete]);

  const handleApplyGiftCard = (giftCardCode: string) => {
    setLoading(true);
    addPromoCode(giftCardCode)
      .then(({ data, dataError, functionError }) => {
        if (dataError || functionError) {
          setError(dataError.error || functionError.error);
        } else {
          if (data?.giftCards.length > 0) {
            setCompleted({
              last4CodeChars: data.giftCards[0].last4CodeChars,
              id: data.giftCards[0].id,
            });
            setIsUpdete(true);
          } else {
            setError(
              'There is no gift card... but there is a coupon with this number...',
            );
          }
        }
      })
      .catch((e) => {
        setError(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveGiftCard = async () => {
    if (state.result) {
      setLoading(true);
      removeGiftCard(state.result.id);
      setCompleted(null);
      addSnackbar({
        id: 'delSnackbar',
        onClose: () => closeSnackbar('delSnackbar'),
        message: `Gift card Deleted`,
        variant: SnackbarVariant.SUCCESS,
      });
    } else {
      setIsOpen(false);
      setIdle();
    }
    setLoading(false);
  };

  return (
    <>
      {loaded && (
        <div>
          {!isOpen && (
            <Button fullWidth variant="text" onClick={() => setIsOpen(true)}>
              Apply
            </Button>
          )}
        </div>
      )}
      {isOpen && !state.result && (
        <div>
          <GiftCardInput
            status={state.status}
            onApply={handleApplyGiftCard}
            onCancel={handleRemoveGiftCard}
            isHaveGiftCard={isHaveGiftCard}
          />
        </div>
      )}
      {isOpen && state.result && (
        <div css={tw`mt-4 grid grid-cols-2`}>
          <p css={tw`font-poppins uppercase text-sm flex items-center`}>
            <span css={tw`flex flex-1 ml-3.5 gap-3`}>
              <span css={tw`inline-block text-gray-aaa`}>
                Gift card Applied:
              </span>{' '}
              <span css={tw`text-aqua-marine font-semibold`}>
                {state.result.last4CodeChars}
              </span>
            </span>
            {isHaveGiftCard && (
              <button
                data-testid={ids.giftCardDeleteButton}
                onClick={handleRemoveGiftCard}
                type="button"
                css={tw`ml-2 text-coral uppercase`}>
                <DeleteOutline></DeleteOutline>
              </button>
            )}
          </p>
        </div>
      )}
    </>
  );
}
