import { VendorDetails } from '@interfaces';
import { useRouter } from 'next/router';
import { useContext, createContext, ReactNode, useState } from 'react';

const VendorContext = createContext<{
  vendor: VendorDetails;
  setVendor: (vendor: VendorDetails) => void;
}>(null);

export function VendorProvider({ children }: { children: ReactNode }) {
  const { vendorSlug } = useRouter().query;
  const [vendor, setVendor] = useState<VendorDetails>(null);

  return (
    <VendorContext.Provider
      value={{
        vendor: vendorSlug ? vendor : null,
        setVendor: setVendor,
      }}>
      {children}
    </VendorContext.Provider>
  );
}

export const useVendorContext = () => {
  const context = useContext(VendorContext);

  if (!context)
    throw new Error('VendorContext was used outside of the VendorProvider');

  return context;
};
