import { useEffect, useState } from 'react';
import { Chip, List, UserInfoBlock } from '@teddly/teddly-ui-components';
import { TimesThin } from '@anyvision/anv-icons';
import chipStyles from '../../Chip.module.scss';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useCheckoutPageContext } from '../../../../../context/CheckoutPageContext';
import { TipTypeEnum } from 'teddly-sdk';
import OtherTip from './OtherTip';

type TipProps = {
  title: string;
  otherTipInputDataTestId: string;
  otherTipButtonDataTestId: string;
  tipButtonDataTestId: string;
  otherTipSaveButtonDataTestId: string;
};

export default function Tip({
  title,
  otherTipInputDataTestId,
  otherTipButtonDataTestId,
  tipButtonDataTestId,
  otherTipSaveButtonDataTestId,
}: TipProps): JSX.Element {
  const [selected, setSelected] = useState<string>();
  const { checkout, updateCheckout } = useCheckoutFlowContext();
  const { setIsLoading } = useCheckoutPageContext();
  const [tipOther, setTipOther] = useState('');
  const variants: number[] = [5, 10, 15, 20, 25];
  const [isShowOtherOption, setIsShowOtherOption] = useState(false);

  const onSelectionClear = async () => {
    const data = await onTipUnselected();
    setSelected(null);
  };

  const onTipSelected = async (type: TipTypeEnum, tip: number) => {
    setIsLoading(true);
    try {
      const data = await updateCheckout({
        checkoutId: checkout?.id,
        note: checkout?.note,
        itemSubstitution: checkout?.itemSubstitution,
        toivelingItems: checkout?.toivelingItems,
        tip: Math.abs(tip),
        tipType: type,
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onTipUnselected = async () => {
    setIsLoading(true);
    return await updateCheckout({
      checkoutId: checkout?.id,
      note: checkout?.note,
      itemSubstitution: checkout?.itemSubstitution,
      toivelingItems: checkout?.toivelingItems,
      tip: 0,
      tipType: TipTypeEnum.PERCENT,
    }).then((data) => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (checkout?.tipPrice) {
      if (
        checkout?.tipPrice?.type == TipTypeEnum.PERCENT &&
        checkout?.tipPrice?.tip != selected
      ) {
        setSelected(checkout?.tipPrice?.tip);
      } else if (
        checkout?.tipPrice?.type == TipTypeEnum.AMOUNT &&
        checkout?.tipPrice?.tip >= 0
      ) {
        setSelected(null);
        setTipOther(checkout.tipPrice.tip);
      }
    }
  }, [checkout?.tipPrice]);
  const [loadingVariant, setLoadingVariant] = useState(null);

  const onSelectedTip = async (selectedVariant: string, variant: string) => {
    setLoadingVariant(variant);
    if (selected == variant) {
      await onSelectionClear();
    } else {
      setIsShowOtherOption(false);

      setSelected(variant);
      await onTipSelected(TipTypeEnum.PERCENT, Number(variant));
    }
    setLoadingVariant(null);
    setTipOther('');
  };

  const onSelectedTipOther = (tip: string) => {
    onTipSelected(TipTypeEnum.AMOUNT, Number(tip));
  };

  const onChangeTipOther = (tip) => {
    setTipOther(tip);
  };

  return (
    <UserInfoBlock
      isWithShading
      boldTitle
      title={title}
      description={
        <List isRowDirection={true}>
          {variants.map((variant, index) => {
            const isLoading = loadingVariant == variant;
            return (
              <List.Item key={index}>
                <Chip
                  data-testid={tipButtonDataTestId + index}
                  key={variant}
                  loading={isLoading}
                  label={`${variant}%`}
                  leadingIcon={
                    selected == variant.toString() ? (
                      <TimesThin height={30} width={30} />
                    ) : null
                  }
                  onClick={() => onSelectedTip(selected, variant.toString())}
                  className={
                    selected == variant.toString() && !isLoading
                      ? chipStyles.selectedType
                      : chipStyles.microMargin // this is when not clicked
                  }
                />
              </List.Item>
            );
          })}
          <List.Item>
            <OtherTip
              data-testid={tipButtonDataTestId}
              otherTipInputDataTestId={otherTipInputDataTestId}
              otherTipButtonDataTestId={otherTipButtonDataTestId}
              otherTipSaveButton={otherTipSaveButtonDataTestId}
              // otherTipSaveButtonDataTestId={otherTipSaveButtonDataTestId}
              onSelectedTipOther={onSelectedTipOther}
              onChangeTipOther={onChangeTipOther}
              title="Other"
              tipOther={tipOther}
              isShowOtherOption={isShowOtherOption}
              setIsShowOtherOption={setIsShowOtherOption}
            />
          </List.Item>
        </List>
      }
    />
  );
}
