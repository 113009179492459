//Internal providers
import { CheckoutFlowProvider } from './CheckoutFlowContext';
import { CheckoutPageProvider } from './CheckoutPageContext';
import { CartProvider } from './CartContext';
import { PageLayoutContextProvider } from './PageLayoutContext';
import { UserAddressProvider } from './UserAddressContext';
import { CheckoutShippingAddressProvider } from './CheckoutShippingAddressContext';
import { RoutesProvider } from './RoutesContext';
import { CheckoutPaymentProvider } from './CheckoutPaymentContext';
import { DeliveryProvider } from './DeliveryContext';
import { HomePageProvider } from './HomePageContext';
import { ChannelProvider } from './ChannelContext';
import { FastDeliveryProvider } from './FastDeliveryContext';
import { VendorProvider } from './VendorContext';

//External providers
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from 'react-query';
import { FirebaseProvider } from './FirebaseContext'; //NIU?
import { TeddlySdkProvider } from '@config/teddly-sdk/TeddlySdkProvider';
import { ReactNode } from 'react';
import { CartFlowProvider } from './CartFlowContext';

const queryClient = new QueryClient();
const googleClientID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID!;

const AppProviders = ({ children }: { children: ReactNode }) => (
  <GoogleOAuthProvider clientId={googleClientID}>
    <QueryClientProvider client={queryClient}>
      <TeddlySdkProvider>
        <ChannelProvider>
          <PageLayoutContextProvider>
            <RoutesProvider>
              <HomePageProvider>
                <FirebaseProvider>
                  <CheckoutPageProvider>
                    <CartFlowProvider>
                      <CheckoutFlowProvider>
                        <FastDeliveryProvider>
                          <UserAddressProvider>
                            <CheckoutShippingAddressProvider>
                              <DeliveryProvider>
                                <VendorProvider>
                                  <CheckoutPaymentProvider>
                                    <CartProvider>{children}</CartProvider>
                                  </CheckoutPaymentProvider>
                                </VendorProvider>
                              </DeliveryProvider>
                            </CheckoutShippingAddressProvider>
                          </UserAddressProvider>
                        </FastDeliveryProvider>
                      </CheckoutFlowProvider>
                    </CartFlowProvider>
                  </CheckoutPageProvider>
                </FirebaseProvider>
              </HomePageProvider>
            </RoutesProvider>
          </PageLayoutContextProvider>
        </ChannelProvider>
      </TeddlySdkProvider>
    </QueryClientProvider>
  </GoogleOAuthProvider>
);

export default AppProviders;
