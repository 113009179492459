import { useState } from 'react';
import { useNetworkState } from '@hooks/useNetworkState';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import {
  Edit,
  Chip,
  ItemNote,
  Snackbar,
  Button,
  ButtonVariantEnum,
  ButtonColorEnum,
  Plus,
} from '@teddly/teddly-ui-components';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { useTranslation } from 'next-i18next';

export default function OrderNote({ ...props }) {
  const { state, setLoading, setCompleted, setError, setIdle } =
    useNetworkState();
  const [isOpen, setIsOpen] = useState(false);
  const { checkout, updateCheckout } = useCheckoutFlowContext();
  const { addSnackbar, closeSnackbar } = usePageLayoutContext();
  const { t } = useTranslation('common', { keyPrefix: 'checkout' });

  const onSaveNote = async (note) => {
    setLoading();
    const { dataError } = await updateCheckout({
      checkoutId: checkout?.id,
      note,
      itemSubstitution: checkout?.itemSubstitution,
      toivelingItems: checkout?.toivelingItems,
    });
    if (!dataError) {
      setCompleted('order update');
      addSnackbar({
        id: 'completed',
        onClose: () => closeSnackbar('completed'),
        message: t('snackbar-note-success'),
        variant: SnackbarVariant.SUCCESS,
      });
      setIsOpen(false);
    } else {
      addSnackbar({
        id: 'error',
        onClose: () => closeSnackbar('error'),
        message: state.error || t('snackbar-note-error'),
        variant: SnackbarVariant.ERROR,
      });
    }
  };

  return (
    <>
      <Button
        data-testid={props.noteDataTestId}
        // style={{ fill: 'none', width: '100px' }}
        label={t('note')}
        title={t('note')}
        variant={ButtonVariantEnum.OUTLINE}
        icon={<Plus />}
        checked={checkout?.note}
        // trailingIcon={<Edit />}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <ItemNote
        loading={state.status === 'loading'}
        note={checkout?.note}
        isOpen={isOpen}
        onSave={(v) => onSaveNote(v)}
        onClose={() => setIsOpen(false)}
        name={'Order note'}
      />
    </>
  );
}
