import { useState } from 'react';

import { useRouter } from 'next/router';
import { useSetPassword } from 'teddly-sdk';
import ResetPasswordDialog from '@components/Organisms/ResetPasswordDialog';

import {
  PageDialog,
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';

export default function ResetPasswordFormController() {
  const {
    openDialog: openDialog,
    closeDialog,
    openLoginDialog,
    addSnackbar,
    closeSnackbar,
  } = usePageLayoutContext();
  const { query } = useRouter();
  const { email, token } = query as { email: string; token: string };

  const [setPassword] = useSetPassword();

  const [fieldErrorMessages, setFieldErrorMessages] = useState<
    Record<string, string>
    >({});
  
  const handleResetPassword = async ({ password, confirmPassword }) => {
    if (password !== confirmPassword) {
      setFieldErrorMessages({
        confirmPassword: "Password doesn't match",
      });
    } else if (password && email && token) {
      try {
        const res = await setPassword({ email, password, token });
        if (!res) {
          addSnackbar({
            id: 'error',
            onClose: () => closeSnackbar('error'),
            message: 'Invalid or expired token...',
            variant: SnackbarVariant.ERROR,
          });
        } else if (res) {
          addSnackbar({
            id: 'completed',
            onClose: () => closeSnackbar('completed'),
            message: `New password is set!`,
            variant: SnackbarVariant.SUCCESS,
          });
          openLoginDialog();
        }
      } catch (e) {
        addSnackbar({
          id: 'error',
          onClose: () => closeSnackbar('error'),
          message: 'Invalid or expired token...',
          variant: SnackbarVariant.ERROR,
        });
      }
    }
  };

  return (
    <>
      <ResetPasswordDialog
        isOpen={openDialog === PageDialog.resetPassword}
        fieldErrorMessages={fieldErrorMessages}
        resetPassword={handleResetPassword}
        onClose={closeDialog}
      />
    </>
  );
}
