import { ApolloLink } from '@apollo/client';
import { getUserDetails } from './getUserDetails';
import * as Sentry from '@sentry/nextjs';
export const SentryResponseLink = new ApolloLink((operation, forward) => {
  //Send all request to sentry after response return
  return forward(operation).map((response) => {
    const data = response;
    if (data) {
      const errorsRes = flattenJSON(data, {}, 'errors');
      const errValues = Object.values(errorsRes);

      if (!errValues || errValues?.length == 0) {
        return response;
      }

      const err = errValues[0];
      Sentry.withScope(function (scope) {
        const input = operation?.variables;
        const error = err[0];
        const operationName = scope.setExtra(
          'operationName',
          operation?.operationName
        );
        scope.setExtra('field', error?.field);
        scope.setExtra('code', error?.code);
        scope.setExtra('input', JSON.stringify(input));
        Sentry.captureMessage("Error: "  + error?.message);
      });
    }
    return response;
  });
});
const flattenJSON = (obj = {}, res = {}, field: string) => {
  for (var key in obj) {
    if (key && key.toLowerCase().includes(field) && obj[key]?.length !== 0) {
      res[key] = obj[key];
      return res;
    } else {
      if (typeof obj[key] === 'object') {
        flattenJSON(obj[key], res, field);
      }
    }
  }
  return res;
};
