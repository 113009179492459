import { ICheckoutModel } from 'teddly-sdk/lib/helpers';

import { getTeddlySdkApi } from '@config/teddly-sdk';

import {
  CREATE_PAYMNET_SOURCE,
  DELETE_PAYMNET_SOURCE,
} from './fragments';
import { PaymentGatewayType, PaymentSourceInput } from './types';

export async function createPaymentSource(
  paymentGatewayType: PaymentGatewayType,
  input: PaymentSourceInput
): Promise<{ errors: Error[]; success: boolean }> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  try {
    const {
      data: {
        createPaymentSource: { paymentSourceErrors, success },
      },
      errors,
    } = await teddlyApolloClient.mutate({
      mutation: CREATE_PAYMNET_SOURCE,
      variables: { paymentGatewayType, paymentSourceInput: input },
    });
    if (paymentSourceErrors && paymentSourceErrors.length > 0) {
      throw paymentSourceErrors[0];
    } else if (errors?.length > 0) {
      throw errors[0];
    }

    return {
      errors: paymentSourceErrors,
      success: success,
    };
  } catch (e) {
    return { errors: [e], success: false };
  }
}

export async function deletePaymentSource(
  paymentGateway: PaymentGatewayType,
  paymentMethodId: string
): Promise<{ errors: Error[]; data: boolean }> {
  const { teddlyApolloClient } = await getTeddlySdkApi();
  try {
    const { data: paymentDelete, errors: paymentDeleteErrors } =
      await teddlyApolloClient.mutate({
        mutation: DELETE_PAYMNET_SOURCE,
        variables: {
          paymentGateway,
          paymentMethodId,
        },
      });
    if (paymentDeleteErrors && paymentDeleteErrors.length > 0) {
      throw paymentDeleteErrors[0];
    } else if (paymentDelete?.errors && paymentDelete.errors.length) {
      throw paymentDelete.errors[0];
    }

    return {
      errors: null,
      data: paymentDelete,
    };
  } catch (e) {
    return { errors: [e], data: null };
  }
}
