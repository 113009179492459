import styled from '@emotion/styled';
import { formatPrice } from '@utils';

const RowLabel = styled.span`
  z-index: 10;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 500;
  display: flex;
  gap: 12px;
`;
const RowValue = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #222127;
  padding: 0.5rem;
  background-color: #ffffff;
`;
type propsType = {
  label: string;
  value;
  minus?: boolean;
  show?: boolean;
  dataTestId?: string;
  markAsPositive?: boolean;
  icon?: JSX.Element
};

export function RowWithValue({
  label,
  value,
  minus = false,
  show = true,
  dataTestId = null,
  markAsPositive = false,
  icon = null
}: propsType) {
  return (
    show && (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}>
        <RowLabel>
          {icon}
          {label}
        </RowLabel>
        <RowValue
          style={markAsPositive ? { color: '#33d1a7' } : null}
          data-testid={
            dataTestId
          }>{`${minus ? '-' : ''}${formatPrice(value)}`}</RowValue>
      </div>
    )
  );
}
