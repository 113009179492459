import { useEffect, useState } from 'react';

import { useAuth } from 'teddly-sdk';

import { usePageLayoutContext } from '@context/PageLayoutContext';

import { SideNavBar, ShoppingBag } from '@teddly/teddly-ui-components';

import GroceryIcon from '../../../../public/assets/SideBarIcons/shopping_cart.svg';
import HouseholdIcon from '../../../../public/assets/SideBarIcons/cleaning.svg';
import PharmacyIcon from '../../../../public/assets/SideBarIcons/local_pharmacy.svg';
import KidsAndToysIcon from '../../../../public/assets/SideBarIcons/toys.svg';
import ClothesAndShoesIcon from '../../../../public/assets/SideBarIcons/checkroom.svg';
import JudaicaIcon from '../../../../public/assets/SideBarIcons/auto_stories.svg';
import BrowseStoresIcon from '../../../../public/assets/SideBarIcons/storefront.svg';
import LocalProsIcon from '../../../../public/assets/SideBarIcons/event_available.svg';
import RestaurantsIcon from '../../../../public/assets/SideBarIcons/ramen_dining.svg';
import MyOrdersIcon from '../../../../public/assets/SideBarIcons/local_mall.svg';
import ShoppingListIcon from '../../../../public/assets/SideBarIcons/summarize.svg';
import AccountSettingsIcon from '../../../../public/assets/SideBarIcons/settings_heart.svg';

// import {
//   HomeIcon,
//   BookLocalProsIcon,
//   GroceryIcon,
//   HouseholdIcon,
//   JudaicaIcon,
//   PharmacyIcon,
//   ClothesAndToysIcon,
// } from '../../../../public/assets/categories';
import useWindowDimensions from '@hooks/useWindowDimension';
import { useRoutesContext } from '@context/RoutesContext';
import { ids } from '../../../../cypress/pages/navbars/SideNavbarPage.cy';
import {
  getRootCategoriesBaseFragments,
  getRootCategoriesSlugs,
  getVendorSubCategoriesData,
} from '@services/categories/api';
import { useChannelContext } from '@context/ChannelContext';

const rootCategories = [
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6NjE4',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Grocery.png',
    name: 'Grocery',
    slug: 'grocery',
    vendors_count: 15,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6ODEw',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Household__Electronics.png',
    name: 'Household',
    slug: 'household',
    vendors_count: 13,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6OTI1',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Judaica.png',
    name: 'Judaica',
    slug: 'judaica',
    vendors_count: 10,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6OTk1',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Pharmacy_Health__Beauty.png',
    name: 'Pharmacy',
    slug: 'pharmacy',
    vendors_count: 9,
  },
  {
    categories: [],
    id: 'Q2F0ZWdvcnk6MTA0Ng==',
    imgSrc:
      'https://nyc3.digitaloceanspaces.com/teddly-dev-space/category-backgrounds/Clothes__Toys.png',
    name: 'Toys & Clothes',
    slug: 'clothes-and-toys',
    vendors_count: 11,
  },
];

// const IconsBySlug = {
//   home: <HomeIcon />,
//   grocery: <GroceryIcon />,
//   household: <HouseholdIcon />,
//   judaica: <JudaicaIcon />,
//   'clothes-and-toys': <ClothesAndToysIcon />,
//   pharmacy: <PharmacyIcon />,
//   'book-local-pros': <BookLocalProsIcon />,
//   stores: <ShoppingBag width={'32px'} height={'32px'} strokeWidth={1.5} />,
// };

const iconsMap = {
  grocery: <GroceryIcon />,
  household: <HouseholdIcon />,
  // home: <HomeIcon />,
  pharmacy: <PharmacyIcon />,
  'kids-and-toys': <KidsAndToysIcon />,
  'clothes-and-shoes': <ClothesAndShoesIcon />,
  judaica: <JudaicaIcon />,
  'browse-stores': <BrowseStoresIcon />,
  'local-prod': <LocalProsIcon />,
  restaurants: <RestaurantsIcon />,
  'order-history': <MyOrdersIcon />,
  'shopping-lists': <ShoppingListIcon />,
  'my-account': <AccountSettingsIcon />,
};

function SideBar() {
  const { router, navigateTo, pages, isCurrentPage, routes } =
    useRoutesContext();
  const { isLargeTablet } = useWindowDimensions();
  const { isChannelSelected } = useChannelContext();
  const [rootCategoriesSlugs, setRootCategoriesSlugs] = useState<string[]>([]);

  const { authenticated } = useAuth();
  const {
    isSidebarNavOpen: isSideBarOpen,
    closeSidebarNav,
    setLoading,
  } = usePageLayoutContext();

  const getAndSetCategories = async () => {
    const response = await getRootCategoriesSlugs();
    setRootCategoriesSlugs(response);
  };

  useEffect(() => {
    if (isChannelSelected) {
      getAndSetCategories();
      // getRootCategoriesSlugs().then((categoriesSlugs) => {
      //   setRootCategoriesSlugs(categoriesSlugs);
      // });
    }
  }, [isChannelSelected]);
  useEffect(() => {}, [isLargeTablet]);

  const optionsMenu = [
    {
      name: 'My Orders',
      slug: pages.ORDER_HISTORY,
    },
    {
      name: 'Shopping List',
      slug: pages.SHOPPING_LISTS,
    },
    {
      name: 'Account Settings',
      slug: pages.MY_ACCOUNT,
    },
  ];
  // const GeneralMenus = [
  //   {
  //     name: 'Return Policy',
  //     route: '/return-policy',
  //   },
  //   {
  //     name: 'Privacy Policy',
  //     route: '/terms-privacy',
  //   },
  //   {
  //     name: 'Contact Us',
  //     route: `https://api.whatsapp.com/send/?phone=${
  //       selectedChannel?.phone
  //         ? selectedChannel?.phone
  //         : process.env.NEXT_PUBLIC_WHATSAPP_PHONE
  //     }`,
  //     // '&text=',
  //   },
  // ];

  type sideBarItem = {
    name: string;
    onClick?: () => void;
    selected?: boolean;
    icon: JSX.Element;
    isSoon?: boolean;
  };

  const categoriesMenuExtension: sideBarItem[] = [
    {
      name: 'Browse stores',
      icon: iconsMap['browse-stores'],
      isSoon: false,
      onClick: () =>
        navigateTo({
          route: pages.STORES_LIST,
          options: { scroll: true },
          showLoader: true,
        }),
      selected: isCurrentPage(pages.STORES_LIST),
    },
    {
      name: 'Local Pros',
      icon: iconsMap['local-prod'],
      isSoon: true,
    },
    {
      name: 'Restaurants',
      icon: iconsMap['restaurants'],
      isSoon: true,
    },
  ];

  let categories: sideBarItem[] = rootCategories
    .filter(
      (category) =>
        rootCategoriesSlugs?.find((slug) => slug === category?.slug) &&
        Object.keys(iconsMap).find((iconName) => iconName === category.slug),
    )
    .map((category) => {
      const link = `/${category?.slug}`;
      return {
        name: category.name,
        isSoon: false,
        icon: iconsMap[category.slug],
        onClick: () => {
          setLoading(true);
          navigateTo({
            route: link,
            options: { scroll: true },
          });
        },
        selected: router?.query?.categorySlug?.includes(category.slug),
      };
    });
  categories = categories.concat(categoriesMenuExtension);
  // const optionsMenus = optionsMenu;
  const options: sideBarItem[] = optionsMenu.map((menu) => {
    const slugAsRouts = routes[menu?.slug];
    return {
      name: menu.name,
      icon: iconsMap[slugAsRouts.substring(1, slugAsRouts.length)],
      onClick: () =>
        navigateTo({
          route: menu.slug,
          options: { scroll: true },
          showLoader: true,
        }),
      isSoon: false,
      selected: router?.pathname.includes(slugAsRouts),
    };
  });
  return (
    <>
      {isChannelSelected && (
        <SideNavBar
          dataTestId={ids.sideNavbar}
          isOpen={isSideBarOpen}
          onClose={closeSidebarNav}
          onOutsideClick={closeSidebarNav}
          categories={categories}
          categoriesDataTestId={ids.categories}
          optionsDataTestId={ids.options}
          optionsTitle={authenticated ? 'my teddly' : 'teddly'}
          options={authenticated ? options : []}
        />
      )}
    </>
  );
}

export default SideBar;
