const utilsData = {
  timeouts: {
    minor: 500,
    short: 5000,
    medium: 15000,
    long: 60000,
    xlong: 240000,
  },

  // for uniformity and typos
  elementsDataTestIDs: {
    buttons: {
      // ***specific***
      // landing page
      zipCodeSearch: 'zip-code-search',
      signIn: 'sign-in',
      logIn: 'log-in',
      // header
      accountActions: 'account-actions',
      pickDeliveryTimeSlot: 'pick-delivery-time-slot',
      cart: 'cart',
      // login dialog
      appleSocialLogin: 'apple-social-login',
      googleSocialLogin: 'google-social-login',
      forgotPassword: 'forgot-password',
      openAccount: 'open-account',
      backToLogin: 'back-to-login',
      // delivery schedule menu
      changeAddress: 'change-address',
      changeDeliveryTime: 'change-delivery-time',
      selectDeliveryDay: 'select-delivry-day',
      selectTimeSlot: 'select-time-slot',
      // account settings page
      signOut: 'sign-out',
      addCard: 'add-card',
      addAddress: 'add-address',
      resetPassword: 'reset-password',
      deleteAccount: 'delete-account',
      trackOrder: 'track-order',
      keepShopping: 'keep-shopping',

      tip: 'tip',
      addToShoppingList: 'add-to-shopping-list',
      noteToVendor: 'note-to-vendor',

      // ***general***
      search: 'search',
      close: 'close',
      submit: 'submit',
      cancel: 'cancel',
      expand: 'expand',
      edit: 'edit',
      save: 'save',
      delete: 'delete',
      apply: 'apply',
      addNew: 'add-new',
      toggle: 'toggle',
      chip: 'chip',
      clear: 'clear',

      // item details dialog/card
      addUnit: 'add-unit',
      subtractUnit: 'subtract-unit',
      // variationCollapsible: 'variation-collapsible-dropdown',
      arrowNext: 'arrow-next',
      arrowBack: 'arrow-back',
      loadMore: 'load-more',
    },

    inputFields: {
      zipCode: 'zip-code',
      search: 'search',
      email: 'email',
      password: 'pasword',
      currentPassword: 'current-pasword',
      newPassword: 'new-pasword',
      confirmNewPassword: 'confirm-new-pasword',
      firstName: 'first-name',
      lastName: 'last-name',
      phoneNumber: 'phone-number',
      address: 'address',
      addressSearch: 'address-search',
      streetAddress: 'street-address',
      apartment: 'apartment',
      city: 'city',
      state: 'state',
      note: 'note',
      cardNumber: 'car-number',
      expiration: 'expiration-date',
      cvv: 'cvv',
      name: 'name',
    },

    namedElements: {
      header: 'header',
      title: 'title',
      body: 'body',
      footer: 'footer',
      snackbar: 'snackbar',
      logo: 'logo',

      // item details:
      vendorLogo: 'vendor-logo',
      image: 'image',
      name: 'name',
      price: 'price',
      priceUnit: 'price-unit',
      addedUnits: 'added-units',

      // order confirmation:
      orderNumber: 'order-number',
      subtotal: 'subtotal',
      tax: 'tax',
      total: 'total',
      deliveryAddress: 'delivery-address',
      deliveryTime: 'delivery-time',
    },

    componenets: {
      loaders: { splashScreen: 'splash-screen', progressBar: 'progress-bar' }, // done

      dialogs: {
        title: 'dialog', // parent Class done
        signIn: 'sign-in', // done
        signUp: 'sign-up', // done
        forgotPassword: 'forgot-password', // done
        filterSearchResults: 'filter-search-results', // done
        messageToVendor: 'message-to-vendor', // done
        addPaymentMethod: 'add-payment-method', // done
        addDeliveryAddress: 'add-delivery-address', // done
        pickDeliveryAddress: 'pick-delivery-address', // done
        resetPassword: 'reset-password', // done
        orderSummary: 'order-summary',
        checkoutNote: 'checkout-note', // done
        orderConfirmation: 'order-confirmation', // done
        createShoppingList: 'create-shopping-list', // done
        confirmDeleteShoppingList: 'confirm-delete-shopping-list', // done
        orderAgain: 'order-again', // order again cart
        editOrder: 'edit-order', // while order in progress there are few dialogs, 1 asking if you want to edit order in progress 2 edit order cart mode, 3 exit edit mode
        selectFromShoppingList: 'select-from-shopping-list', // waiting for item details collapsible?
        cart: 'cart', // waiting for item details collapsible?
        itemDescription: 'item-description', // ? or independent
      },

      cards: {
        title: 'card', // done
        paymentMethod: 'payment-method', // done
        deliveryAddress: 'delivery-address', // done
        deliverySchedule: 'delivery-schedule', // move to dialog or independent ?
        timeSlot: 'time-slot', // done
        itemDetails: 'item-details', // done, perhaps move to dialog or independent ?
      },

      collapsible: {
        title: 'collapsible', // Class done
        accountSettings: {
          // title: 'account-info', not sure it is necessary
          personalInfo: 'personal-info', // done
          paymentMethod: 'payment-method', // done
          deliveryAddress: 'delivery-address', // done
          loginAndSecurity: 'login-and-security', // done
        },
        addToShoppingList: 'add-to-shopping-list', // dropdown?
        filterOptions: {
          sortBy: 'sort-by', // done
          brand: 'brand', // done
          vendor: 'vendor', // done
          collections: 'collections', // is it still in the UI?
        },
        items: 'items',
        orderBreakdown: {
          // title: 'order-breakdown',
          orderSummary: 'order-summary',
          addCoupon: 'add-coupon',
          addGiftCard: 'add-gift-card',
          returnPolicy: 'returm-policy',
        },
      },
      navigation: {
        header: 'header', // done
        sideNavBar: 'side-navigaion-bar', // done
      },
      pages: {
        title: 'page',
        landing: 'landing', // done
        home: 'home', // done
        accountSettings: 'account-settings',
        shoppingLists: 'shopping-lists',
        myOrders: 'my-orders',
        checkout: 'checkout',
        categories: {
          title: 'categories',
          grocery: 'grocery',
          houseHold: 'houseHold',
          judaica: 'judaica',
          pharmacy: 'pharmacy',
          stores: 'stores',
          pros: 'pros',
          restaurants: 'restaurants',
        },
      },
    },
  },

  pagePathNames: {
    homePage: '/',
    groceryPage: '/grocery',
    housholdPage: '/household',
    pharmacyPage: '/pharmacy',
    judaicaPage: '/judaica',
    clothesAndToysPage: '/clothes-and-toys',
    storesPage: '/stores',
    myPopularItemsPage: '/my-popular-items',
    myShoppingListsPage: '/shopping-lists',
    myAccountSettingPage: '/my-account',
    returnPolicyPage: '/return-policy',
    privacyPolicyPage: '/terms-privacy',
    checkoutPage: '/checkout',
    orderHistoryPage: '/order-history',
    orderDetailsPage: '/order-details',
  },
};

export default utilsData;
