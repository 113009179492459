/**
 * TODO:
 * 1. create data-testids map (ids)
 * 2. Create getter for all of the required elements
 * 3. Create basic actions - click (button) input (type/select) assert (varifying text fields)
 */

import Utils from '../../utils/utils.cy';
import utilsData from '../../fixtures/dataTestIds';
import { BasePageObjectModel } from '../../BasePageObjectModel';

export const ids = {
  chip: 'address-form-chip', // not sure if needed, but there is already data test Id not sure where it comes from
  firstNameInput: 'delivery-first-name-input',
  lastNameInput: 'delivery-last-name-input',
  phoneInput: 'delivery-phone-input',
  searchAddressInput: 'search-address-input',
  streetInput1: 'street-input1',
  streetInput2: 'street-input2', //not existing
  apartmentInput: 'apartment-input',
  cityInput: 'city-input',
  zipCodeInput: 'zip-code-input',
  stateInput: 'state-input',
  noteInput: 'note-input',
  makeDefaultToggle: 'make-default-toggle',
  submitButton: 'submit-button',
  cancelButton: 'cancel-button',
  snackbar: 'address-form-dialog-esnackbar',
};

export default class AddressFormPage extends BasePageObjectModel {
  url: string;
  title: string;

  constructor() {
    super('/index.php?route=extension/maza/blog/home', 'Blog');
  }

  get firstNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.firstNameInput));
  }
  get firstSuggetion() {
    return cy.get('.pac-item');
  }

  get noteInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.noteInput));
  }
  get lastNameInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.lastNameInput));
  }

  get snackbar() {
    return cy.get(Utils.formatDataTestIdSelector(ids.snackbar));
  }
  get makeDefaultToggle() {
    return cy.get(Utils.formatDataTestIdSelector(ids.makeDefaultToggle));
  }

  get phoneInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.phoneInput));
  }

  get searchAddressInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.searchAddressInput));
  }
  get cancelButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cancelButton));
  }
  get submitButton() {
    return cy.get(Utils.formatDataTestIdSelector(ids.submitButton), {
      timeout: utilsData.timeouts.long,
    });
  }
  get streetInput1() {
    return cy.get(Utils.formatDataTestIdSelector(ids.streetInput1));
  }
  get streetInput2() {
    return cy.get(Utils.formatDataTestIdSelector(ids.streetInput2));
  }
  get apartmentInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.apartmentInput));
  }
  get cityInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.cityInput));
  }
  get stateInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.stateInput));
  }
  get zipCodeInput() {
    return cy.get(Utils.formatDataTestIdSelector(ids.zipCodeInput));
  }

  get dialogCloseIcon() {
    return cy
      .get(Utils.formatDataTestIdSelector('dialog'))
      .find(Utils.formatDataTestIdSelector('dialog-header-close-icon'));
  }

  typePhoneNumber(phoneNumber) {
    return this.phoneInput.type(phoneNumber, { force: true });
  }

  typeFirstNameInput(firstNameInput) {
    return this.firstNameInput.type(firstNameInput, { force: true });
  }
  typeLastNameInput(lastNameInput) {
    return this.lastNameInput.type(lastNameInput, { force: true });
  }
  typeSearchInput(searchAddressInput) {
    return this.searchAddressInput.type(searchAddressInput, { force: true });
  }
  typeNoteInput(noteInput) {
    return this.noteInput.type(noteInput, { force: true });
  }
  typeApartmentInput(apartmentInput) {
    return this.apartmentInput.type(apartmentInput, { force: true });
  }
  typeStreetInput1(street1) {
    return this.streetInput1.type(street1, { force: true });
  }
  typeStreetInput2(street2) {
    return this.streetInput2.type(street2, { force: true });
  }
  typeCityInput(city) {
    return this.cityInput.type(city, { force: true });
  }

  typeStateInput(state) {
    return this.stateInput.type(state, { force: true });
  }
  typeZipCodeInput(zipCode) {
    return this.zipCodeInput.type(zipCode, { force: true });
  }

  clickEnterKeyOnSearchInput() {
    return this.searchAddressInput
      .find('input')
      .trigger('keypress', { keyCode: 13 });
  }

  clickMakeDefaultToggle() {
    return this.makeDefaultToggle.click({ force: true, multiple: true });
  }
  clickFirstSuggestion() {
    return this.firstSuggetion.click({ force: true, multiple: true });
  }
  clickSubmitButton() {
    return this.submitButton.click({ force: true, multiple: true });
  }
  clickCancelButton() {
    return this.cancelButton.click({ force: true, multiple: true });
  }
  clickSearchInput() {
    return this.searchAddressInput.click({ force: true, multiple: true });
  }

  assertErrorMessage(field, expectedErrorMessage) {
    return field.parents(':lt(2)').next('label').contains(expectedErrorMessage);
  }

  assertSnackbar(expectedMessage: string) {
    return this.snackbar.should('be.visible').contains(expectedMessage, {
      matchCase: false,
    });
  }

  assertSubmitButton(expectedState: 'enabled' | 'disabled') {
    Utils.customWait(utilsData.timeouts.minor);
    this.submitButton
      .should('have.attr', 'class')
      .and('not.contain', 'loading');
    return this.submitButton.should(
      expectedState === 'enabled' ? 'be.enabled' : 'be.disabled',
    );
  }
}
