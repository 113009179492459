import React, {
  useReducer,
  useEffect,
  useRef,
  useState,
  useContext,
  ReactNode,
  createContext,
} from 'react';
import { CheckoutTypeEnum, useAuth } from 'teddly-sdk';
import useLocalStorageState from 'use-local-storage-state';
import { getVariant, getVariants } from '@app/products/api';
import useSafeDispatch from '@hooks/useSafeDispatch';
import { WaitingCartItemsQueue, SendingCartItemsQueue } from './cart/CartQueue';
import cartReducer, {
  CartAction,
  CartState,
  initialState,
} from './cart/cartReducer';
import {
  SnackbarVariant,
  usePageLayoutContext,
} from '@context/PageLayoutContext';
import { ICheckoutModel, ICheckoutModelLine } from 'teddly-sdk/lib/helpers';
import { Item } from 'teddly-sdk/lib/api/Cart';
import { useCheckoutFlowContext } from '@context/CheckoutFlowContext';
import { useNetworkState } from '@hooks/useNetworkState';
import { cloneDeep } from '@apollo/client/utilities';
import { useChannelContext } from '@context/ChannelContext';
import { NewVariant } from '@interfaces';
import { CartActions, LinesQueueProps, CartError } from './types/ContextTypes';
import { useCartFlowContext } from './CartFlowContext';

const CartStateContext = createContext<
  | {
      changeCheckoutType: (
        newType: CheckoutTypeEnum,
        editingOrder?: any,
      ) => Promise<void>;
      fastDeliveryCheckoutCount: number;
      onEmptyCartClick: () => Promise<any> | void;
      defaultCheckoutCount: number;
      currentCheckoutItemsCount: number;
      variants: NewVariant[];
      total: number;
      subTotalPrice: number;
      shippingPrice: number;
      additionalAmount: number;
      discountPrice: number;
      taxPrice: number;
      tip: number;
      giftCards: {
        giftCardsUsageAmount:
          | ICheckoutModel['giftCardsUsageAmount']
          | null
          | undefined;
        cards: ICheckoutModel['giftCards'];
      };
      addCartVariant: (
        variant: NewVariant,
        value: number,
      ) => Promise<any> | void;
      addCartVariantsOnOrderAgain: (
        variants: NewVariant[],
      ) => Promise<any> | void;
      addHistoryVariantsCount: (totalHistoryVariants: number | null) => void;
      removeCartVariant: (variant: NewVariant, deleteItem?: boolean) => void;
      getCartVariant: (variantId: string) => NewVariant | undefined;
      emptyCartState: () => void;
      emptyCart: () => void;
      // lastVariantAdded: NewVariant | undefined;
      orderHistoryCount: number | null;
      isCalculatePrice: boolean;
      updateLinesInLocal: (resultsLines: ICheckoutModelLine[]) => void;
      runUpdateCheckoutInLocalCart: (
        lines: ICheckoutModelLine[] | undefined,
      ) => void;
      isCheckoutTypeUpdating: boolean;
      setIsCheckoutTypeUpdating: (value: boolean) => void;
      refetchCheckout: (checkout: ICheckoutModel) => Promise<void>;
    }
  | undefined
>(undefined);

let isSomethingInRequestList: boolean = false;

export function CartProvider({ children }: { children: ReactNode }) {
  const synced = useRef(false);
  const {
    loaded,
    items: itemsCheckout,
    totalPrice,
    subtotalPrice,
    shippingPrice,
    discount,
    additionalPrice,
    taxPrice,
    tip,
    giftCards,
    addItem,
    addItemsBulk,
    updateItem,
    updateItems,
    removeAllItem,
    removeItem,
    editingOrder,
    isCartChannelConflict,
  } = useCartFlowContext();

  const { updateCheckout, getCheckout, checkout } = useCheckoutFlowContext();
  const { selectedChannel } = useChannelContext();

  const { setCompleted, setLoading } = useNetworkState();
  const [isCheckoutTypeUpdating, setIsCheckoutTypeUpdating] = useState(false);

  const getOnlyFastDeliveryLocalVariants = (list: NewVariant[]) =>
    list?.filter((variant) => variant?.is_fast_delivery);

  const getFilteredVariants = (variantsList: NewVariant[]) => {
    if (checkout?.type === CheckoutTypeEnum.FAST_DELIVERY) {
      return getOnlyFastDeliveryLocalVariants(variantsList);
    }
    return variantsList;
  };

  const refetchCheckout = async (checkout: ICheckoutModel): Promise<void> => {
    return await getCheckout(true, 'default-channel', checkout);
  };

  const cartLocalStorageName = 'loaded-cart-variants';

  const getLocalStorageVariants = (): CartState['variants'] => {
    return JSON.parse(localStorage.getItem(cartLocalStorageName))?.variants;
  };

  const [localCartState, setCartVariants] = useLocalStorageState<CartState>(
    cartLocalStorageName,
    { defaultValue: initialState },
    // initialState
  );

  const {
    openLoginDialog,
    addSnackbar,
    closeSnackbar,
    openCartConflictDialog,
  } = usePageLayoutContext();
  const [cartState, dispatch] = useReducer(cartReducer, localCartState);
  const safeDispatch: React.Dispatch<CartAction> = useSafeDispatch(dispatch);

  const [listRequestsQueueState, setListRequestQueueState] = useState<
    LinesQueueProps[]
  >([]);

  const { user, authenticated } = useAuth();
  const [triggerRequest, setTriggerRequest] = useState<boolean>(false);
  const [multiDevicesOpen, setMultiDevicesOpen] = useState(false);
  const [requestHandlerIsBusy, setRequestHandlerIsBusy] =
    useState<boolean>(false);

  const requestHandler = async () => {
    const listReq: LinesQueueProps[] = [...listRequestsQueueState];
    if (listReq.length === 0) {
      setRequestHandlerIsBusy(false);
      setTriggerRequest(false);
      return;
    }
    let dataResult;
    for (let i = 0; i < listReq.length; i++) {
      isSomethingInRequestList = true;
      const itemRequest = listReq[i];
      let listSameProduct: LinesQueueProps[] = [];

      if (itemRequest.isHandled === true) {
        continue;
      }
      switch (itemRequest.typeAction) {
        case CartActions.ADD:
          dataResult = await addItem(
            itemRequest.variant.id,
            itemRequest.variant.line.quantity,
            itemRequest.variant.line.note,
            itemRequest.variant.isInclude || true,
            editingOrder?.id || null,
          );
          if (dataResult.length) {
            safeDispatch({
              type: 'REMOVE_VARIANT',
              variantId: itemRequest.variant.id,
            });
            handledError(dataResult);
          }
          break;
        case CartActions.UPDATE:
          listSameProduct = getListSameProducts(listReq, itemRequest);
          dataResult = await updateItem(
            itemRequest.variant.id,
            itemRequest.variant.line.quantity,
            itemRequest.variant.line.note,
            itemRequest.variant.isInclude,
            itemRequest.variant?.line?.order?.id || editingOrder?.id || null,
          );
          updateHandledProduct(listReq, listSameProduct);
          if (dataResult.length) {
            handledError(dataResult);
          }
          break;
        case CartActions.REMOVE:
          dataResult = await removeItem(
            itemRequest.variant.id,
            itemRequest.variant?.line?.order?.id,
          );
          if (dataResult.length) {
            handledError(dataResult);
          }
          break;
        default:
          break;
      }
      listReq[i].isHandled = true;
      //run if return lines and is last req
      if (dataResult?.lines && listReq.length == i + 1) {
        checkIfCheckoutItemMatchToLocalCartItem(dataResult?.lines);
      }
    }

    const listRequestToRemove = [...listRequestsQueueState];
    listReq.forEach((item) => {
      listRequestToRemove.splice(listRequestToRemove.indexOf(item), 1);
    });
    setListRequestQueueState(listRequestToRemove);
    setRequestHandlerIsBusy(false);
    setTriggerRequest(false);
    isSomethingInRequestList = listRequestToRemove?.length > 0;
    updateLinesInLocal(dataResult?.lines);
  };

  const getListSameProducts = (
    listReq: LinesQueueProps[],
    itemRequest: LinesQueueProps,
  ) => {
    const listSameProduct = listReq.filter(
      (item) =>
        item.variant.id === itemRequest.variant.id &&
        item.typeAction === itemRequest.typeAction,
    );
    return listSameProduct;
  };

  const updateHandledProduct = (
    listReq: LinesQueueProps[],
    listSameProduct: LinesQueueProps[],
  ) => {
    listSameProduct.forEach((item) => {
      const finderIndex = listReq.findIndex((f) => f.reqId === item.reqId);
      listReq[finderIndex].isHandled = true;
    });
  };

  const handledError = (error: CartError[] | any) => {
    if (error?.length > 0 && error[0]?.field === 'checkoutId') {
      reload();
    } else {
      const snackbarId = Date.now().toString();
      addSnackbar({
        id: snackbarId,
        onClose: () => closeSnackbar(snackbarId),
        message: 'Sorry Something went wrong ' + error[0]?.message,
        variant: SnackbarVariant.ERROR,
      });
    }
  };

  const reload = () => {
    setMultiDevicesOpen(true);
    addSnackbar({
      isOpen: multiDevicesOpen,
      onClose: () => setMultiDevicesOpen(false),
      message: 'Checkout has been completed on another device... logging out.',
      variant: SnackbarVariant.ERROR,
    });
    localStorage.removeItem('data_checkout');
    localStorage.removeItem('data_payment');
    safeDispatch({ type: 'EMPTY_CART' });
    location.reload();
  };

  useEffect(() => {
    if (!requestHandlerIsBusy) {
      const timer1 = setTimeout(async () => {
        setRequestHandlerIsBusy(true);
        try {
          await requestHandler();
        } catch (e) {
          const snackbarId = Date.now().toString();
          addSnackbar({
            id: snackbarId,
            onClose: () => closeSnackbar(snackbarId),
            message: 'Sorry Something went wrong ',
            variant: SnackbarVariant.ERROR,
          });
        }
      }, 1500);

      return () => {
        clearTimeout(timer1);
      };
    }
  }, [triggerRequest]);

  const updateVariantsLineQuantityFromResult = (
    lines: ICheckoutModelLine[],
    variants: NewVariant[],
  ) => {
    for (const line of lines) {
      for (const variant of variants) {
        if (variant.id === line.variant.id) {
          variant.line.note = line.note;
          variant.line.quantity = line.quantity;
          variant.line.isInclude = line.isInclude;
        }
      }
    }
  };

  const addCartVariantsOnOrderAgain = async (variants: NewVariant[]) => {
    const items = variants.map((variant, index) => {
      return {
        variantId: variant.id,
        quantity: variant.line.quantity,
        note: variant.line.note,
        isInclude: variant?.line?.isInclude,
        orderId: editingOrder?.id ? editingOrder?.id : null,
      } as unknown as Item;
    });

    const result: any = await addItemsBulk(items);

    if (
      result?.message ||
      (result[0] != undefined && result[0]['__typename'] == 'CheckoutError')
    ) {
      return result[0] != undefined ? result[0]['message'] : result.message;
    } else {
      updateVariantsLineQuantityFromResult(result.lines, variants);
      safeDispatch({
        type: 'ADD_VARIANTS',
        variants: variants,
      });
    }
  };

  const includedVariantsArrayToMap = (
    variants: NewVariant[],
    resultsLines: ICheckoutModelLine[],
  ): { [key: string]: NewVariant } => {
    const map: { [key: string]: NewVariant } = {};
    const linesIds: string[] = resultsLines?.map((line) => line?.variant?.id);
    const filteredVariants: NewVariant[] = variants?.filter((variant) =>
      linesIds?.find((lineId) => lineId === variant?.id),
    );
    filteredVariants?.map(
      (variant) =>
        (map[variant?.id] = variants?.find((v) => v?.id === variant?.id)),
    );
    return map;
  };

  const updateLinesInLocal = (
    resultsLines: ICheckoutModelLine[],
    updateEvenInLoading: boolean = false,
  ) => {
    if (!isSomethingInRequestList) {
      updateLinesInLocalOnThereNoPending(resultsLines, updateEvenInLoading);
    }
  };

  const isGetVariantsRequired = (resultsLines: ICheckoutModelLine[]): boolean =>
    isCheckoutTypeUpdating ||
    resultsLines?.some((line) => !cartState?.variants[line?.id]?.id);

  const updateLinesInLocalOnThereNoPending = async (
    resultsLines: ICheckoutModelLine[],
    updateEvenInLoading: boolean = false,
  ) => {
    const filteredLines: ICheckoutModelLine[] = resultsLines?.filter(
      (line) => line.isInclude,
    );
    const variants: NewVariant[] = isGetVariantsRequired(filteredLines)
      ? await getVariantsFromAlgoliaAndMap(filteredLines)
      : null;
    const newVariantsMap: { [key: string]: NewVariant } = variants
      ? includedVariantsArrayToMap(variants, filteredLines)
      : {};
    filteredLines?.map((resultsLine: ICheckoutModelLine) => {
      newVariantsMap[resultsLine?.variant?.id] = addVariantToLocal(
        resultsLine,
        newVariantsMap,
      );
    });

    const newCartState: CartState = {
      ...cartState,
      variants: newVariantsMap,
    };

    // if (!loaded || updateEvenInLoading) {
    safeDispatch({
      type: 'UPDATE_VARIANTS_IN_LOCAL',
      newState: newCartState,
    });
    // }
  };

  const addVariantToLocal = (
    resultsLine: ICheckoutModelLine,
    newVariants: object,
  ) => {
    const variant: NewVariant = newVariants[resultsLine?.variant?.id]
      ? { ...newVariants[resultsLine?.variant?.id] }
      : cartState?.variants[resultsLine?.variant?.id]
        ? { ...cartState?.variants[resultsLine?.variant?.id] }
        : null;
    if (variant) {
      return {
        ...variant,
        in_stock: resultsLine?.variant?.isInStock,
        line: {
          ...cartState?.variants[resultsLine?.variant?.id]?.line,
          isInclude: resultsLine?.isInclude,
          order: resultsLine?.order || null,
          quantity: resultsLine?.quantity,
          note: resultsLine?.note,
        },
      } as NewVariant;
    }
  };

  const findVariantInLineResults = (
    data: ICheckoutModel,
    variant: NewVariant,
    orderId?: string | null,
  ) => {
    return data?.lines?.find(
      (line) => line?.variant?.id === variant?.id && line?.order?.id == orderId,
    );
  };

  const getVariantNotExceptedMessage = (variant: NewVariant): string => {
    if (variant?.name) {
      return `The product ${variant.name} is not currently available for purchase`;
    }
    return 'An error occurred, the product is not available for sale';
  };

  const getVariantQuantityNotExceptedMessage = (
    variant: NewVariant,
  ): string => {
    if (variant?.name) {
      return `The product ${variant.name} is not currently available for purchase in the requested quantity`;
    }
    return 'The product is not currently available for purchase in the requested quantity';
  };

  const checkIfRemoveVariantExcepted = (
    resultsLine: ICheckoutModelLine,
    variant: NewVariant,
    requestVariants: NewVariant[],
  ) => {
    if (resultsLine && isFromCurrentRequest(variant, requestVariants)) {
      const snackbarId = 'productNotRemoved';
      addSnackbar({
        id: snackbarId,
        onClose: () => closeSnackbar(snackbarId),
        message: 'An error occurred removing the product',
        variant: isFromCurrentRequest(variant, requestVariants)
          ? SnackbarVariant.ERROR
          : SnackbarVariant.WARNING,
      });
    }
  };

  const showAddVariantExceptedMessage = (
    variant: NewVariant,
    requestVariants: NewVariant[],
  ) => {
    if (isFromCurrentRequest(variant, requestVariants)) {
      const snackbarId = 'productNotAdded';
      addSnackbar({
        id: snackbarId,
        onClose: () => closeSnackbar(snackbarId),
        message: getVariantNotExceptedMessage(variant),
        variant: SnackbarVariant.ERROR,
      });
    }
  };

  const showRemoveVariantExceptedMessage = (
    variant: NewVariant,
    requestVariants: NewVariant[],
  ) => {
    if (isFromCurrentRequest(variant, requestVariants)) {
      const snackbarId = 'productNotAdded';
      addSnackbar({
        id: snackbarId,
        onClose: () => closeSnackbar(snackbarId),
        message: getVariantQuantityNotExceptedMessage(variant),
        variant: isFromCurrentRequest(variant, requestVariants)
          ? SnackbarVariant.ERROR
          : SnackbarVariant.WARNING,
      });
    }
  };

  const checkIfAddVariantExcepted = (
    resultsLine: ICheckoutModelLine,
    variant: NewVariant,
    requestVariants: NewVariant[],
  ) => {
    if (!resultsLine) {
      ///add variant not excepted
      safeDispatch({ type: 'REMOVE_VARIANT', variantId: variant.id });
      showAddVariantExceptedMessage(variant, requestVariants);
    } else if (resultsLine?.quantity != variant?.line?.quantity) {
      ///change quantity not excepted
      safeDispatch({
        type: 'ADD_VARIANT',
        variant: {
          ...variant,
          line: {
            ...variant.line,
            quantity: resultsLine?.quantity,
          },
        },
        editingOrder: getVariantOrderId(variant),
      });
      showRemoveVariantExceptedMessage(variant, requestVariants);
    }
  };

  const getVariantOrderId = (variant: NewVariant) =>
    variant?.line?.order?.id || editingOrder?.id || null;

  const isFromCurrentRequest = (
    variant: NewVariant,
    requestVariants: NewVariant[],
  ) => {
    return requestVariants?.find((v) => v.id === variant?.id);
  };

  const sendCartLineRequest = async (requestVariants: NewVariant[]) => {
    const linesForSend = requestVariants?.map((variant) => ({
      variantId: variant?.id,
      quantity: variant.line.quantity,
      note: variant.line.note,
      isInclude: variant?.line?.isInclude,
      orderId: getVariantOrderId(variant),
    }));
    const { data: dataResult, error: errors } = await updateItems(linesForSend);
    const variantsToRemoveFomSendingQueue = requestVariants.filter(
      (v) => v?.line?.quantity === SendingCartItemsQueue.get(v)?.line?.quantity,
    );
    SendingCartItemsQueue.remove(variantsToRemoveFomSendingQueue);
    if (SendingCartItemsQueue.isEmpty() && WaitingCartItemsQueue.isEmpty()) {
      // const sendingVariants = cloneDeep(SendingCartItemsQueue.getInstance());
      const localVariants = getLocalStorageVariants();
      const localVariantsWithoutSending = Object.values(localVariants);
      // const localVariantsWithoutSending = Object.values(localVariants)?.filter(
      //   (localVariant) =>
      //     !(localVariant?.id in sendingVariants) &&
      //     localVariant?.line?.isInclude,
      // );
      localVariantsWithoutSending?.forEach((variant) => {
        const resultsLine = findVariantInLineResults(
          dataResult as ICheckoutModel,
          variant,
          getVariantOrderId(variant),
        );
        const isAddOrUpdate = variant?.line?.quantity > 0;
        if (isAddOrUpdate) {
          checkIfAddVariantExcepted(resultsLine, variant, requestVariants);
        } else {
          checkIfRemoveVariantExcepted(resultsLine, variant, requestVariants);
        }
      });
    }
  };

  const addCartVariant = async (variant: NewVariant, value: number) => {
    if (isCartChannelConflict) {
      return openCartConflictDialog();
    }
    if (!authenticated) {
      variant.line = {};
      return openLoginDialog();
    }
    if (!checkout?.id) {
      const snackbarId = 'checkoutNotLoaded';
      return addSnackbar({
        variant: SnackbarVariant.WARNING,
        id: snackbarId,
        onClose: () => closeSnackbar(snackbarId),
        message: `Just a moment, we are receiving your checkout details`,
      });
    }
    variant.line.quantity = value;
    variant.line.isInclude = true;
    variant.line.order = checkout?.editingOrder?.id
      ? {
          id: checkout.editingOrder.id,
          number: checkout.editingOrder?.number,
        }
      : null;
    const quantity = variant?.line?.quantity;
    WaitingCartItemsQueue.add(variant);
    SendingCartItemsQueue.add([variant]);
    if (variant?.line?.quantity > 0) {
      safeDispatch({
        type: 'ADD_VARIANT',
        variant: variant,
        editingOrder: editingOrder,
      });
    } else {
      safeDispatch({
        type: 'REMOVE_VARIANT',
        variantId: variant?.id,
      });
    }
    setTimeout(() => {
      const value = WaitingCartItemsQueue.get(variant);
      if (
        value &&
        value?.line?.quantity === quantity &&
        WaitingCartItemsQueue.isLastVariantAdded(variant)
      ) {
        const allVariantsToAddAsMap = cloneDeep(
          WaitingCartItemsQueue.getInstance(),
        );
        const allVariantsToAdd = Object.values(allVariantsToAddAsMap);
        WaitingCartItemsQueue.clear();
        SendingCartItemsQueue.add(allVariantsToAdd);
        sendCartLineRequest(allVariantsToAdd);
      }
    }, 1200);
  };

  const removeCartVariant = (variant: NewVariant) => {
    variant.line.quantity = 0;
    addCartVariant(variant, 0);
  };

  const addHistoryVariantsCount = (totalHistoryVariants: number | null) => {
    // safeDispatch({
    //   type: 'ORDER_HISTORY_COUNT',
    //   totalHistoryVariants: totalHistoryVariants,
    // });
  };

  const emptyCartState = () => {
    safeDispatch({ type: 'EMPTY_CART' });
    synced.current = false;
  };

  const emptyCart = async () => {
    await removeAllItem().then(() => {
      localStorage.removeItem('data_checkout');
      localStorage.removeItem('data_payment');
    });
    safeDispatch({ type: 'EMPTY_CART' });
  };

  const [variants, setVariants] = useState(
    cartState?.variants ? Object.values(cartState.variants) : [],
  );
  useEffect(() => {
    setVariants(cartState?.variants ? Object.values(cartState.variants) : []);
  }, [cartState?.variants, itemsCheckout]);

  const getCartVariant = (variantID: string) => {
    return variants?.find((v) => v?.id == variantID);
  };

  const allDefaultCheckoutLines = (): ICheckoutModelLine[] | NewVariant[] => {
    if (checkout?.type === CheckoutTypeEnum.DEFAULT) {
      return localCurrentCheckoutLines();
    }
    return itemsCheckout?.filter((item) => !item?.order?.id);
  };
  const allFastDeliveryCheckoutLines = ():
    | ICheckoutModelLine[]
    | NewVariant[] => {
    const results = getOnlyFastDeliveryLocalVariants(
      checkout?.type === CheckoutTypeEnum.FAST_DELIVERY
        ? localCurrentCheckoutLines()
        : Object?.values(cartState?.variants),
    );
    return results;
  };
  const localCurrentCheckoutLines = (): NewVariant[] =>
    Object?.values(cartState?.variants)?.filter(
      (variant) => variant?.line?.isInclude,
    );
  const defaultCheckoutCount = !user ? 0 : allDefaultCheckoutLines()?.length;
  // const curren
  // const getCurrentCheckoutItemsCount = () => {
  //   switch (checkout?.type) {
  //     case CheckoutTypeEnum.EDIT_ORDER:
  //       const lines = checkout?.editingOrder
  //       return checkout?.;

  //     default:
  //       return defaultCheckoutCount;
  //   }
  // };

  const getCurrentCheckoutItemsCount = () => {
    if (checkout?.type == CheckoutTypeEnum.DEFAULT) {
      return defaultCheckoutCount;
    }
    if (checkout) {
      return variants?.filter((v) => v?.line?.isInclude)?.length;
    }
  };
  const currentCheckoutItemsCount = getCurrentCheckoutItemsCount();
  const fastDeliveryCheckoutCount = !user
    ? 0
    : allFastDeliveryCheckoutLines()?.length;

  // getFilteredVariants
  // const fastDeliveryCheckoutCount = !user
  //   ? 0
  //   : allDefaultCheckoutLines()?.filter((line) => line?.variant?.isFastDelivery)
  //       ?.length;

  const isCalculatePrice =
    !WaitingCartItemsQueue.isEmpty() ||
    typeof currentCheckoutItemsCount !== 'number' ||
    !SendingCartItemsQueue.isEmpty();
  // (Object.keys(cartState?.variants)?.length > 0 &&
  //   (!totalPrice || totalPrice?.gross?.amount <= 0));

  //Update the cart with lines (using when return from complete order)
  const runUpdateCheckoutInLocalCart = async (
    lines: ICheckoutModelLine[] | undefined,
  ) => {
    if (!lines || lines.length === 0) return;
    await lines.forEach(async (item, index) => {
      await updateCheckoutItemToCart(item);
    });
  };
  const updateCheckoutItemToCart = async (
    checkoutLine: ICheckoutModelLine,
    variants?: NewVariant[],
  ) => {
    const cartLine = getCartVariant(checkoutLine.variant.id);
    if (
      cartLine?.line?.quantity != checkoutLine?.quantity ||
      cartLine?.line?.order?.id != checkoutLine?.order?.id
    ) {
      if (cartLine && cartLine?.id) {
        safeDispatch({
          type: 'ADD_VARIANT',
          variant: cartLine,
          editingOrder: editingOrder,
        });
        // safeDispatch({ type: 'CLEAR_LAST_VARIANT_ADDED' });
      } else {
        const foundVariant = variants?.find(
          (v) => v?.id === checkoutLine?.variant?.id,
        );
        const variant: NewVariant =
          foundVariant || (await getVariant(checkoutLine.variant.id));
        if (variant) {
          variant.line = {
            quantity: checkoutLine.quantity,
            note: checkoutLine.note,
            isInclude: checkoutLine.isInclude,
            order: {
              id: checkoutLine.order?.id,
              number: checkoutLine.order?.number,
            },
          };
          // if (!loaded) {
          safeDispatch({
            type: 'ADD_VARIANT',
            variant: variant,
            editingOrder: editingOrder,
          });
          // }
          // safeDispatch({ type: 'CLEAR_LAST_VARIANT_ADDED' });
        } else {
          emptyCart();
        }
      }
    }
    // safeDispatch({
    //   type: 'ORDER_HISTORY_COUNT',
    //   totalHistoryVariants: null,
    // });
  };

  const linesToNewVariants = (lines: ICheckoutModelLine[]): NewVariant[] => {
    lines = lines?.filter((line) => line?.variant);
    return lines?.map((line) => {
      const variant = line.variant;
      return {
        id: variant.id,
        productId: line.variant.product?.id,
        line: {
          quantity: line?.quantity,
          isInclude: (line as any)?.isInclude,
          note: line?.note,
        },
      } as unknown as NewVariant;
    });
  };
  const convertArrayToMap = (array, key) => {
    const initialValue = {};
    // Iterate over the array and group all the object with the same key.
    const reduce = array.reduce((obj, item) => {
      const itemInReducedList = obj[item[key]];
      if (itemInReducedList) {
        itemInReducedList.push(item);
      }
      const element = itemInReducedList ? itemInReducedList : [item];
      return {
        ...obj,
        [item[key]]: element,
      };
    }, initialValue);
    return reduce;
  };

  const getVariantsFromAlgoliaAndMap = async (
    lines: ICheckoutModelLine[],
  ): Promise<NewVariant[]> => {
    const toVariantTypeFromResults: NewVariant[] = linesToNewVariants(lines);
    const productIdAndVariants = convertArrayToMap(
      toVariantTypeFromResults,
      'productId',
    );
    return await getVariants(productIdAndVariants);
  };

  const onEmptyCartClick = async () => {
    if (selectedChannel?.id) {
      try {
        const response = await updateCheckout({
          checkoutId: checkout?.id,
          channel: selectedChannel.id,
        });
        updateLinesInLocal(response.data?.lines, true);
      } catch (e) {
        addSnackbar({
          variant: SnackbarVariant.ERROR,
        });
      }
    }
  };

  const changeCheckoutType = async (
    newType: CheckoutTypeEnum,
    editingOrder?: any,
  ) => {
    setLoading();
    setIsCheckoutTypeUpdating(true);
    const { dataError, data } = await updateCheckout({
      checkoutId: checkout?.id,
      type: newType,
      editingOrder: editingOrder?.id ? editingOrder?.id : undefined,
    });
    if (!dataError) {
      setCompleted('order update');
    }
    updateLinesInLocal(data?.lines, true);
    setIsCheckoutTypeUpdating(false);
  };

  useEffect(() => {
    if (!loaded || !itemsCheckout || !itemsCheckout.length) return;
    if (synced.current) return;

    (async () => {
      if (itemsCheckout.length == 0) {
        emptyCart();
      }
      // const productsAndVariantsMap = {}
      // itemsCheckout.forEach(v => {
      //   if(v?.variant?.product?.id){
      //     productsAndVariantsMap[v.variant.product.id] = v.variant?.id
      //   }
      // })
      const toVariantTypeFromResults: NewVariant[] =
        linesToNewVariants(itemsCheckout);
      const productIdAndVariants = convertArrayToMap(
        toVariantTypeFromResults,
        'productId',
      );
      const variants = await getVariants(productIdAndVariants);
      await itemsCheckout.forEach(async (item, index) => {
        if (item?.isInclude) {
          await updateCheckoutItemToCart(item, variants);
          if (index === itemsCheckout.length - 1) synced.current = true;
        }
      });
    })();
  }, [loaded, itemsCheckout?.length, synced?.current, user]);
  useEffect(() => {
    if (!user) {
      emptyCart();
    } else {
      updateLinesInLocal(itemsCheckout);
    }
  }, [user]);

  useEffect(() => {
    setCartVariants(cartState);
  }, [cartState, setCartVariants]);

  const getProductThatNotExistInLocalAndUpdateLocal = async (
    variantId: string,
    quantity: number,
    note: string,
    isInclude: boolean,
    orderId?: string,
  ) => {
    // let variant_id = convertBase64ToString(idCheckout);
    const variant: NewVariant = await getVariant(variantId);
    if (variant) {
      variant.line = {
        note: note,
        quantity: quantity,
        isInclude: isInclude,
        order: { id: orderId },
      };

      safeDispatch({
        type: 'ADD_VARIANT',
        variant: variant,
        editingOrder: editingOrder,
      });
    }
    return variant;
  };

  const checkIfCheckoutItemMatchToLocalCartItem = async (
    checkoutLines: ICheckoutModelLine[],
  ) => {
    if (!variants || !checkoutLines) return [];

    return checkoutLines
      ?.filter((line) => {})
      .forEach(async (checkoutI) => {
        const findInCartItems = variants.find(
          (cartI) => cartI.id === checkoutI.variant.id,
        );
        if (!findInCartItems) {
          getProductThatNotExistInLocalAndUpdateLocal(
            checkoutI.variant.id,
            checkoutI.quantity,
            checkoutI.note,
            checkoutI.isInclude,
            checkoutI.order?.id,
          );
        }
      });
  };

  const updateMatchCartItemsPerCheckoutCart = (
    variants: NewVariant[],
    itemsCheckoutLine: ICheckoutModelLine[],
  ) => {
    if (!variants || !itemsCheckoutLine) return [];
    const cartItemsForCart = variants.filter((variant) => {
      if (variant.line.quantity <= 0) {
        return false;
      }
      if (isCalculatePrice) {
        return true;
      }
      const findInCheckout = itemsCheckoutLine.find(
        (itemC: ICheckoutModelLine) => itemC.variant.id === variant.id,
      );
      return !!findInCheckout;
    });

    return getFilteredVariants(cartItemsForCart);
  };

  return (
    <CartStateContext.Provider
      value={{
        variants: updateMatchCartItemsPerCheckoutCart(variants, itemsCheckout),
        defaultCheckoutCount: defaultCheckoutCount,
        fastDeliveryCheckoutCount,
        total: totalPrice?.gross?.amount || 0,
        subTotalPrice: subtotalPrice?.net?.amount || 0,
        shippingPrice: shippingPrice?.net.amount || 0,
        currentCheckoutItemsCount: currentCheckoutItemsCount,
        discountPrice: discount?.amount || 0,
        additionalAmount: additionalPrice?.gross?.amount || 0,
        taxPrice: taxPrice?.amount,
        tip,
        giftCards,
        addCartVariant,
        addCartVariantsOnOrderAgain,
        removeCartVariant: removeCartVariant,
        addHistoryVariantsCount,
        emptyCartState,
        emptyCart,
        getCartVariant,
        // lastVariantAdded: cartState?.lastVariantAdded,
        orderHistoryCount: cartState?.orderHistoryCount,
        isCalculatePrice: isCalculatePrice,
        runUpdateCheckoutInLocalCart: runUpdateCheckoutInLocalCart,
        updateLinesInLocal,
        changeCheckoutType,
        isCheckoutTypeUpdating,
        setIsCheckoutTypeUpdating,
        refetchCheckout,
        onEmptyCartClick: onEmptyCartClick,
      }}>
      {children}
    </CartStateContext.Provider>
  );
}

export const useCartContext = () => {
  const context = useContext(CartStateContext);

  if (!context)
    throw new Error('CartContext was used outside of the CartProvider');

  return context;
};
