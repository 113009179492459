import { useCartContext } from '@context/CartContext';
import EmptyCartButtonIcon from 'public/assets/remove_shopping_cart.svg';
import styles from '../components/style.module.scss';
import { useCheckoutShippingAddressContext } from '@context/CheckoutShippingAddressContext';

import { Dialog, Button, ButtonVariantEnum } from '@teddly/teddly-ui-components';
import { useState } from 'react';

export default function CartConflictDialog({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
  const { onEmptyCartClick } = useCartContext();
  const [isLoading, setIsLoading] = useState(false);
  const { openPickAddressForm } = useCheckoutShippingAddressContext();

  const handleOnCgangeAddressClick = () => {
    onClose();
    openPickAddressForm();
  };

  const handleOnEmptyCartClick = async () => {
    setIsLoading(true);
    await onEmptyCartClick();
    setIsLoading(false);
    onClose();
  };

  const content = (
    <div className={styles.cartConflictDilaogContent}>
      {'Items in this cart cannot be delivered to the selected address — please empty the cart to continue.'}
    </div>
  );
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      footerProps={{
        children: [
          <Button
            title="change delivery address"
            variant={ButtonVariantEnum.NEGATIVE}
            onClick={handleOnCgangeAddressClick}
          />,
          <Button
            variant={ButtonVariantEnum.FILL}
            title={'Empty Cart'}
            onClick={handleOnEmptyCartClick}
            iconClassName={styles.emptyCartIcon}
            icon={<EmptyCartButtonIcon />}
            loading={isLoading}
          />,
        ],
      }}>
      <Dialog.Header variant={'warning'} title={'Cart conflict'} showTitleIcon />
      <Dialog.Body>{content}</Dialog.Body>
    </Dialog>
  );
}
