import SignInDialogPage from '../../cypress/pages/authentication/SignInDialogPage.cy';
import index from '../../cypress/fixtures';
import utilsData from '../../cypress/fixtures/dataTestIds';
import LocalStorageUtils from './localStorage.cy';
import PageLayoutPage from '../../cypress/pages/page-layout/PageLayoutPage.cy';
import SideNavbarPage from '@cypress/pages/navbars/SideNavbarPage.cy';
import { LandingPage } from '../components/pages/Landing.cy';
import HeaderPage from '@cypress/pages/page-layout/HeaderPage.cy';

export default class Utils {
  static customWait(fixture: number) {
    cy.wait(fixture);
  }

  static formatDataTestIdSelector(id: string) {
    return `[data-testid="${id}"]`;
  }

  // perhaps not useful...
  static assertDataTestIds(object: { [key: string]: string }) {
    return Object.keys(object).forEach((key) => {
      cy.get(Utils.formatDataTestIdSelector(object[key])).should('be.visible');
    });
  }

  static isButtonDisabled(button) {
    button.should('be.disabled');
  }

  static isButtonEnabled(button) {
    button.should('be.enabled');
  }

  static shouldNotBeEmptyString(str: string | null) {
    return expect(str).to.not.be.empty;
  }

  static shouldBeEmptyString(str: string) {
    return expect(str).to.equal('');
  }

  static shouldBeNull(element) {
    return expect(element).to.null;
  }

  static awaitButtonLoader(element) {
    return element.should('have.attr', 'class').and('not.contain', 'loading');
  }

  /* GraphQL interception methods:
Old method is intercepting and awating in the same function, as of Cypress best practices it is recommended to intercept in the beggining and await when required or expected, hence the new functions
1. Original method by Osher-retailin intercept->await->then. - need to replace where it is used
2. New Intercept only - Primary
3. case Intercept only if channel is not empty - not in use
4. Await only + then optional - Primary
*/

  // 1. Original method by Osher-retailin.
  // Intercept, Await + .then individual request - switch all to multiple request then remove this one
  static awaitGraphqlRequest(operationName: string, then: () => void) {
    cy.intercept('POST', '**/graphql/', (req) => {
      req.body.forEach((b) => {
        if (b.operationName == operationName) {
          req.alias = operationName;
        }
      });
    });
    cy.wait(`@${operationName}`, {
      timeout: utilsData.timeouts.long,
    }).then(then);
  }

  // 2. Intercept Multiple requests
  static interceptGqlRequests(operationNames: string[]) {
    cy.intercept('POST', '**/graphql/', (req) => {
      operationNames.forEach((operationName) => {
        req.body.forEach((b) => {
          if (b.operationName === operationName) {
            req.alias = operationName;
          }
        });
      });
    });
  }

  // 3. Intercept Graphql request only if asserted channel in header - currently not in use
  static interceptGrqlheaderAndChannel(operationName: string) {
    cy.intercept('POST', '**/graphql/', (req) => {
      if (req.headers.channel && req.headers.channel !== '') {
        req.alias = operationName;
      }
    });
  }

  // 4. Await multiple intercepted requests + .then optional
  static awaitInterceptedGql(operationNames: string[], then?: () => void) {
    const waits = operationNames.map((operationName) =>
      cy.wait(`@${operationName}`, { timeout: utilsData.timeouts.xlong }),
    );
    Promise.all(waits).then(then);
  }

  // Navigation Utils

  static openTeddly() {
    const pageLayoutPage = new PageLayoutPage();

    cy.visit('/');
    pageLayoutPage.splashScreenWait();
  }

  static channelSelector(fixture: string) {
    const landingPage = new LandingPage();
    const pageLayoutPage = new PageLayoutPage();
    const sideNavbarPage = new SideNavbarPage();

    Utils.interceptGqlRequests(['ChannelByPostalCode']);
    cy.fixture(fixture).then(({ zipCode }) => {
      landingPage.zipCodeInputField.type(zipCode, { force: true });
      landingPage.zipCodeSearchButton.click({ force: true });
    });
    Utils.awaitInterceptedGql(['ChannelByPostalCode'], () => {
      Utils.shouldNotBeEmptyString(
        LocalStorageUtils.getLocalStorageValueBy('channel') ?? '',
      );
    });
    pageLayoutPage.splashScreenWait();
    sideNavbarPage.assertSideNavBar();
  }

  static loginUsingSignInForum(channelFixture: string, accountFixture: string) {
    const pageLayoutPage = new PageLayoutPage();
    const headerPage = new HeaderPage();
    const signInDialogPage = new SignInDialogPage();

    Utils.interceptGqlRequests(['TokenAuth']);
    Utils.openTeddly();
    Utils.channelSelector(channelFixture);
    headerPage.clickAccountActionsButton();

    cy.fixture(accountFixture).then(({ email, password }) => {
      signInDialogPage.typeEmail(email);
      signInDialogPage.typePassword(password);
    });
    signInDialogPage.clickSubmitButton();
    Utils.awaitInterceptedGql(['TokenAuth']);
    pageLayoutPage.assertSnackbar('Welcome Back!');
    pageLayoutPage.progressBarWait();
    //  fix this data test id for sign in dialog
    signInDialogPage.dialog.should('not.exist').then(() => {
      headerPage.assertAccountActions('account');
      Utils.shouldNotBeEmptyString(
        LocalStorageUtils.getLocalStorageValueBy('token') ?? '',
      );
    });
    pageLayoutPage.awaitSnackbar();
  }

  static loginUsingLocalStorage() {
    cy.fixture(index.loginStates.emptyCart).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStorageMyNameUser() {
    cy.fixture(index.loginStates.emptyOrders).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }

  static loginUsingLocalStoragCheckoutUser() {
    cy.fixture(index.loginStates.addressUser).then((state) => {
      console.info(state);
      Object.keys(state).forEach((key) => {
        const value = state[key];
        LocalStorageUtils.setLocalStorageItem(key, value);
      });
    });
  }
}
