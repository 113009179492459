import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import { Page, PageIDs } from './Page.cy';

const {
  buttons: btn,
  inputFields: field,
  componenets,
} = utilsData.elementsDataTestIDs;

export class LandingPageIDs extends PageIDs {
  zipCodeInputFieldID: string;
  zipCodeSearchButtonID: string;
  signInButtonID: string;

  constructor() {
    super(componenets.pages.landing);

    this.zipCodeInputFieldID = TestIDFormat.inputField(
      this.fullPrefix,
      field.zipCode,
    );
    this.zipCodeSearchButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.zipCodeSearch,
    );
    this.signInButtonID = TestIDFormat.button(this.fullPrefix, btn.signIn);
  }
}

export class LandingPage extends Page<LandingPageIDs> {
  constructor() {
    super(LandingPageIDs);
  }

  get zipCodeInputField() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.zipCodeInputFieldID));
  }

  get zipCodeSearchButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.zipCodeSearchButtonID));
  }

  get signInButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.signInButtonID));
  }
}
