import { Chip } from '@teddly/teddly-ui-components';
import { TimesThin } from '@anyvision/anv-icons';
import chipStyles from '../../Chip.module.scss';
import tipStyles from './Tip.module.scss';
import AttachMoneyIcon from '@mui/material/Icon';
import tw, { css } from 'twin.macro';
import { InputAdornment, TextField } from '@mui/material';
import { RemoveInputArrows } from '../../forms/AgeRestriction/AddBirthdateForm';

type OtherTipProps = {
  title: string;
  onSelectedTipOther: (tip: string) => void;
  onChangeTipOther: (tip: string) => void;
  tipOther: string;
  isShowOtherOption: boolean;
  setIsShowOtherOption: (show: boolean) => void;
  otherTipInputDataTestId: string;
  otherTipButtonDataTestId: string;
  otherTipSaveButton: string;
};

export default function OtherTip({
  title,
  onSelectedTipOther,
  onChangeTipOther,
  tipOther,
  isShowOtherOption,
  setIsShowOtherOption,
  otherTipInputDataTestId,
  otherTipButtonDataTestId,
  otherTipSaveButton,
}: OtherTipProps): JSX.Element {
  const onClickOther = () => {
    setIsShowOtherOption(true);
    if (tipOther) {
      onSelectedTipOther('0');
      setIsShowOtherOption(false);
    }
  };
  const getLabel = () => {
    return tipOther ? `${tipOther}$` : title;
  };
  return (
    <div>
      {!isShowOtherOption && (
        <Chip
          key={title}
          label={getLabel()}
          leadingIcon={tipOther ? <TimesThin height={30} width={30} /> : null}
          onClick={onClickOther}
          className={
            tipOther ? chipStyles.selectedType : chipStyles.microMargin // this is when not clicked
          }
        />
      )}
      {isShowOtherOption && (
        <div>
          <TextField
            data-testid={otherTipInputDataTestId}
            type="number"
            css={RemoveInputArrows}
            className="maxWidth"
            InputProps={{
              inputProps: {
                min: 0,
              },
              className: tipStyles.otherInput,
              endAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            placeholder="Type"
            value={tipOther}
            defaultValue={tipOther}
            onChange={(ev) => {
              setIsShowOtherOption(true);
              onChangeTipOther(ev.target.value);
            }}
          />
          {Number(tipOther) > 0 && (
            <Chip
              data-testid={otherTipSaveButton}
              key={'save'}
              label={'save'}
              onClick={(ev) => {
                onSelectedTipOther(tipOther);
                setIsShowOtherOption(false);
              }}
              className={chipStyles.microMargin}
            />
          )}
        </div>
      )}
    </div>
  );
}
