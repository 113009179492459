import { useState } from 'react';

import { formatGraphQLError } from '@utils';
import * as Sentry from '@sentry/react';
import loggers from '@utils/loggers';
type NetworkState<T = unknown> = {
  status: 'idle' | 'loading' | 'completed' | 'error';
  result: T;
  error: string;
};

export function useNetworkState<T>(): {
  state: NetworkState<T>;
  setLoading: () => void;
  setCompleted: (result: T) => void;
  setError: (error: string) => void;
  setIdle: () => void;
} {
  const [state, setState] = useState<NetworkState<T>>({
    error: '',
    result: null,
    status: 'idle',
  });

  const setLoading = () =>
    setState((prevState) => ({ ...prevState, status: 'loading' }));

  const setCompleted = (result: T) =>
    setState((prevState) => ({ ...prevState, status: 'completed', result }));

  const setError = (error: string | Record<string, any>) => {
    // loggers(formatGraphQLError(error), LoggerType.ERROR);
    loggers.error(formatGraphQLError(error));
    setState((prevState) => ({
      ...prevState,
      status: 'error',
      error: formatGraphQLError(error),
    }));
  };

  const setIdle = () =>
    setState((prevState) => ({ ...prevState, status: 'idle' }));

  return { state, setLoading, setCompleted, setError, setIdle };
}
