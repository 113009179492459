import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';
import TestObjectBase from '../TestObjectBase';

const { namedElements: elm, componenets } = utilsData.elementsDataTestIDs;

export class PageIDs {
  // Elements
  snackbarID: string;

  protected fullPrefix: string;

  constructor(prefix: string) {
    this.fullPrefix = `${prefix}-${componenets.pages.title}`;
    this.snackbarID = TestIDFormat.element(this.fullPrefix, elm.header);
  }
}

export class Page<T extends PageIDs> extends TestObjectBase<T> {
  constructor(PageIDs) {
    super(PageIDs);
  }

  get snackbar() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.snackbarID));
  }
}
