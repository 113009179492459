import { useAuth } from 'teddly-sdk';
import useWindowDimensions, {
  scrollToTheTopOfTheElement,
} from '@hooks/useWindowDimension';

import DeliveryInHeader from '@components/Pages/Checkout/components/Delivery/DeliveryInHeader';
import { useCartContext } from '@context/CartContext';
import { SearchBar } from '@components/Molecules/Search';
import {
  BottomNavBar,
  Header as HeaderComponent,
  IconButton,
  ArrowLeft,
  Dialog,
  SearchFieldVariantEnum,
  Button,
  ButtonSizeEnum,
  ButtonVariantEnum,
  User,
  X,
  Menu,
} from '@teddly/teddly-ui-components';

import { HeaderStatesProps, useRoutesContext } from '@context/RoutesContext';
import React, { useRef, useState } from 'react';

import { ids } from '../../../../cypress/pages/navbars/navbarPage.cy';
import { ids as pageLayoutIds } from '../../../../cypress/pages/page-layout/PageLayoutPage.cy';
import { useHomePageContext } from '@context/HomePageContext';
import { usePageLayoutContext } from '@context/PageLayoutContext';
import { useChannelContext } from '@context/ChannelContext';
import { useCartFlowContext } from '@context/CartFlowContext';
import { LandingPageIDs } from '@cypress/components/pages/Landing.cy';
import { HeaderIDs } from '@cypress/components/navigation/Header.cy';

export default function Header() {
  const landingPageIDs = new LandingPageIDs();
  const headerIDs = new HeaderIDs();
  const {
    isCurrentPage,
    pages,
    navigateTo,
    back,
    router,
    getCurrentPageHeaderStates,
  } = useRoutesContext();
  const { authenticated, user } = useAuth();
  const { defaultCheckoutCount, currentCheckoutItemsCount } = useCartContext();
  const {
    dispatch,
    openLoginDialog,
    isSidebarNavOpen,
    openSidebarNav,
    isPageSidebarNavOpen,
    closeSidebarNav,
    setLoading,
    isLoading,
    showOrderAgainButton,
  } = usePageLayoutContext();
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const { isLargeTablet } = useWindowDimensions();
  const { editingOrder } = useCartFlowContext();

  const headerStatesProps: HeaderStatesProps = getCurrentPageHeaderStates();

  const { isSearchFieldVisible, setIsSearchFieldVisible } =
    useHomePageContext();

  const onClickCart = () => dispatch({ type: 'TOGGLE_CART', value: true });
  const accountButtonHandler = () => navigateTo({ route: pages.MY_ACCOUNT });
  const loginButtonHandler = () => openLoginDialog();
  const backwardButtonHandler = async () => await back();

  const renderRightButtonOnMobile = () => {
    return (
      isSidebarNavOpen && <IconButton onClick={closeSidebarNav} icon={<X />} />
    );
  };
  const isWithBodySearchField =
    isCurrentPage(pages.HOME) || isCurrentPage(pages.SEARCH);

  React.useEffect(() => {
    const isBodySearchFieldVisible =
      !headerStatesProps.searchField || isWithBodySearchField;
    setIsSearchFieldVisible(isBodySearchFieldVisible);
  }, [isWithBodySearchField]);


  const navigateToPersonalShoppingInfo = (page: string) => {
    if (!authenticated) {
      openLoginDialog();
    } else {
      setLoading(true);
      navigateTo({ route: page });
    }
  };

  const { isChannelSelected } = useChannelContext();

  const scrollToTopPage = () => scrollToTheTopOfTheElement('pageBodyContainer');

  const isOrderDetailsOnMobile =
    (isCurrentPage(pages.ORDER_DETAILS) ||
      isCurrentPage(pages.SHOPPING_LIST_DETAILS)) &&
    isLargeTablet;
  const isCheckoutDetailsOnMobile =
    isCurrentPage(pages.CHECKOUT) && isLargeTablet;

  const isShowBottomNav =
    isLargeTablet &&
    (!isOrderDetailsOnMobile || !showOrderAgainButton) &&
    !isCheckoutDetailsOnMobile;
  const ref = useRef();
  const getSearchField = () => {
    if (headerStatesProps.searchField) {
      return (
        <SearchBar
          key={'inHeader'}
          isInHeader
          isWithStrip={false}
          searchInputTestId={headerIDs.searchInputFieldID}
          searchButtonTestId={headerIDs.searchButtonID}
          variant={SearchFieldVariantEnum.DENSE}
        />
      );
    }
    return null;
  };
  return (
    <div
      ref={ref}
      style={{ height: 'inherit' }}
      onClick={(event) => {
        if (
          [
            event.target,
            (event.target as any).parentElement,
            (event.target as any).parentElement.parentElement,
          ].includes(ref.current)
        ) {
          scrollToTopPage();
        }
      }}>
      <Dialog
        title="search"
        isOpen={isSearchModalOpen && isLargeTablet}
        onClose={() => setIsSearchModalOpen(false)}>
        <Dialog.Body>
          <SearchBar
            onClick={() => setIsSearchModalOpen(false)}
            variant={SearchFieldVariantEnum.DENSE}
            isSearchPage
          />
        </Dialog.Body>
      </Dialog>
      <BottomNavBar
        onHomeClick={() => {
          if (isCurrentPage(pages.HOME)) {
            scrollToTopPage();
          } else {
            navigateTo({
              route: pages.HOME,
              options: { scroll: true },
            });
          }
        }}
        isEditOrder={!!editingOrder}
        onMenuClick={openSidebarNav}
        onLoginClick={openLoginDialog}
        onOrdersClick={() =>
          navigateToPersonalShoppingInfo(pages.ORDER_HISTORY)
        }
        onListsClick={() =>
          navigateToPersonalShoppingInfo(pages.SHOPPING_LISTS)
        }
        isMenuSelected={isPageSidebarNavOpen}
        isHomeSelected={isCurrentPage(pages.HOME)}
        isOrdersSelected={isCurrentPage(pages.ORDER_HISTORY)}
        isListsSelected={isCurrentPage(pages.SHOPPING_LISTS)}
        isLoggedIn={authenticated}
        onCartClick={onClickCart}
        show={isShowBottomNav}
        cartItemsQuantity={currentCheckoutItemsCount}
      />
      <HeaderComponent
        loginButtonTestId={isChannelSelected ? headerIDs.accountActionsButtonID : landingPageIDs.signInButtonID}
        progressBarDataTestId={headerIDs.progressBarID}
        arrowBackTestId={headerIDs.arrowBackButtonID}
        cartButtonTestId={headerIDs.cartButtonID}
        logoTestId={headerIDs.logoID}
        isEditOrder={!!editingOrder}
        onBackClick={backwardButtonHandler}
        onLoginClick={openLoginDialog}
        showLoginButton={isLargeTablet && !authenticated}
        onAccountClick={() => navigateTo({ route: pages.MY_ACCOUNT })}
        isLoggedIn={authenticated}
        loading={isLoading}
        onMenuClick={openSidebarNav}
        showHeaderSearch={!isSearchFieldVisible && isChannelSelected}
        showMenu={isChannelSelected}
        showLogo={headerStatesProps.logo}
        isWithNavbar={false}
        onCartClick={onClickCart}
        cartItemsQuantity={currentCheckoutItemsCount}
        onLogoClick={() => navigateTo({ route: pages.HOME })}
        rightButtonOnMobile={renderRightButtonOnMobile()}
        onSearchButtonClick={() => setIsSearchModalOpen(true)}
        searchField={getSearchField()}
        showCartButton={headerStatesProps.cartButton && isChannelSelected}
        showBackButton={headerStatesProps.backward}
        deliveryTimeButton={
          <DeliveryInHeader show={headerStatesProps.deliveryInHeader} />
        }
      />
    </div>
  );
}
