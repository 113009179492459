/** @type {import('next-i18next').UserConfig} */

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: ['en', 'he'],
    localeDetection: false, //Change to true when we full support others languages
  },
  ns: ['common'],
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  localePath: typeof window === 'undefined' ? require('path').resolve('./public/locales') : './public/locales',

  //This props below is for shown the missing key without all the debug boilerplate
  debug: false, //process.env.NODE_ENV === 'development', Turn to true on dev as needed.
  serializeConfig: false,
  saveMissing: true,
  missingKeyHandler: (lng, ns, key) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Missing key handler: language:${lng}, ns:${ns}, key:${key}`);
    } else {
      //Production logger
    }
  },
};
