import { DialogIDs, Dialog } from './Dialog.cy';
import { TestIDFormat } from '../../utils/utilsNew.cy';
import utilsData from '../../fixtures/dataTestIds';

const {
  buttons: btn,
  inputFields: field,
  componenets,
} = utilsData.elementsDataTestIDs;

export class SignInDialogIDs extends DialogIDs {
  appleSocialLoginButtonID: string;
  googleSocialLoginButtonID: string;
  forgotPasswordButtonID: string;
  loginSubmitButtonID: string;
  openAccountButtonID: string;
  emailInputID: string;
  passwordInputID: string;

  constructor() {
    super(componenets.dialogs.signIn);

    this.appleSocialLoginButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.appleSocialLogin,
    );
    this.googleSocialLoginButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.googleSocialLogin,
    );
    this.forgotPasswordButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.forgotPassword,
    );

    this.emailInputID = TestIDFormat.inputField(this.fullPrefix, field.email);
    this.passwordInputID = TestIDFormat.inputField(
      this.fullPrefix,
      field.password,
    );
    this.loginSubmitButtonID = TestIDFormat.button(this.fullPrefix, btn.submit);
    this.openAccountButtonID = TestIDFormat.button(
      this.fullPrefix,
      btn.openAccount,
    );
  }
}

export class SignInDialog extends Dialog<SignInDialogIDs> {
  constructor() {
    super(SignInDialogIDs);
  }

  get appleSocialLoginButton() {
    return cy.get(
      TestIDFormat.fullPrefix(this.testIDs.appleSocialLoginButtonID),
    );
  }

  get googleSocialLoginButton() {
    return cy.get(
      TestIDFormat.fullPrefix(this.testIDs.googleSocialLoginButtonID),
    );
  }

  get forgotPasswordButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.forgotPasswordButtonID));
  }

  get emailInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.emailInputID));
  }

  get passwordInput() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.passwordInputID));
  }

  get loginSubmitButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.loginSubmitButtonID));
  }

  get openAccountButton() {
    return cy.get(TestIDFormat.fullPrefix(this.testIDs.openAccountButtonID));
  }
}
