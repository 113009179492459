import { useApolloClient } from '@apollo/client';
import { getTeddlySdkApi } from '@config/teddly-sdk';
import React, { useEffect, useState } from 'react';
import { ChannelQuery, useAuth } from 'teddly-sdk';
import { SaleorAPI } from 'teddly-sdk/lib/api';
import { LocalStorageHandler, LocalStorageItems } from 'teddly-sdk/lib/helpers';

export type ChannelType = ChannelQuery['channel'];

const ChannelContext = React.createContext<{
  isChannelSelected: boolean;
  selectedChannel: ChannelType;
  isInitDataLoading: boolean;
  selectChannelByPostalCode: (
    postalCode: string,
  ) => Promise<ChannelType | null>;
}>(null);

export function ChannelProvider({ children }: { children: React.ReactNode }) {
  const { reFetchObservableQueries } = useApolloClient();

  const {
    selectChannelByPostalCode: getChannelByPostalCodeQuery,
    authenticated,
    channelDetails,
    signOut,
  } = useAuth();
  const forceUpdate = React.useReducer(() => ({}), {})[1];
  const [isInitDataLoading, setIsInitDataLoading] = useState(false);
  const initAllData = async () => {
    setIsInitDataLoading(true);
    await reFetchObservableQueries();
    setIsInitDataLoading(false);
  };
  const [urlChannel, setUrlChannel] = useState<ChannelType | null>(null);
  const localStringifyChannel = localStorage.getItem(LocalStorageItems.CHANNEL);

  const getLocalStringifyChannel = () =>
    localStorage.getItem(LocalStorageItems.CHANNEL);

  const parseStringifyChannel = (stringify: string): ChannelType | null => {
    return stringify ? (JSON.parse(stringify) as ChannelType) : null;
  };
  const selectedChannelForState = urlChannel
    ? urlChannel
    : localStringifyChannel
      ? parseStringifyChannel(localStringifyChannel)
      : null;

  const [selectedChannel, setSelectedChannel] = useState<ChannelType>(
    selectedChannelForState,
  );
  const [teddlyApiPointer, setTeddlyApiPointer] = useState<SaleorAPI | null>(
    null,
  );

  const initTeddlyApiPointer = async () => {
    const { teddlyApi } = await getTeddlySdkApi();
    setTeddlyApiPointer(teddlyApi);
  };

  const getEventsPointer = () => {
    if (teddlyApiPointer) {
      const pointer = (teddlyApiPointer.cart as any)
        .localStorageHandler as LocalStorageHandler;
      const events = pointer.events;
      return events;
    }
  };

  useEffect(() => {
    initTeddlyApiPointer();
  }, []);

  useEffect(() => {
    const handleChannelChange = async (channel: ChannelType) => {
      setSelectedChannel({ ...channel } as ChannelType);
      await initAllData();
    };
    if (teddlyApiPointer) {
      getEventsPointer().on('channelChanged', handleChannelChange);
    }

    return () => {
      if (teddlyApiPointer) {
        getEventsPointer().off('channelChanged', handleChannelChange);
      }
    };
  }, [teddlyApiPointer]);
  const selectChannelByPostalCode = async (
    postalCode: string,
  ): Promise<ChannelType | null> => {
    try {
      const response = await getChannelByPostalCodeQuery(postalCode);
      forceUpdate();
      if (
        response?.data &&
        parseStringifyChannel(getLocalStringifyChannel())?.id ===
          response?.data?.id
      ) {
        // client.reFetchObservableQueries();
        return response.data as ChannelType;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const getAndSetChannelDetails = async (
    id: string,
    saveInLocal: boolean = true,
  ) => {
    const details = await channelDetails({ id: id, saveInLocal: saveInLocal });
    return details;
  };

  const getChannelIdFromUrl = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const channelFromUrl = params.get('channel');
    return channelFromUrl;
  };

  const getCurrentChannelDetails = async () => {
    const urlChannel = getChannelIdFromUrl();
    if (
      selectedChannel?.id &&
      (!urlChannel || urlChannel == selectedChannel.id)
    ) {
      getAndSetChannelDetails(selectedChannel?.id, true);
    }
  };

  const getSaveChannelInLocal = (): boolean => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const saveChannelInLocal = params.get('selected');
    return saveChannelInLocal == 'true';
  };

  const changeChannelByUrl = async (channelId: string) => {
    if (authenticated) {
      await signOut();
    }
    const saveInLocal = getSaveChannelInLocal();
    const res = await getAndSetChannelDetails(channelId, saveInLocal);
    setUrlChannel(res.data);
  };

  React.useEffect(() => {
    const channelFromIrl = getChannelIdFromUrl();
    if (
      channelFromIrl &&
      (!selectedChannel || selectedChannel?.id !== channelFromIrl)
    ) {
      changeChannelByUrl(channelFromIrl);
    } else {
      setUrlChannel(null);
    }
  }, [window.location.search]);

  React.useEffect(() => {
    getCurrentChannelDetails();
  }, []);

  return (
    <ChannelContext.Provider
      value={{
        isInitDataLoading: isInitDataLoading,
        isChannelSelected: !!selectedChannel?.id,
        selectedChannel: selectedChannel,
        selectChannelByPostalCode,
      }}>
      {children}
    </ChannelContext.Provider>
  );
}

export const useChannelContext = () => {
  const context = React.useContext(ChannelContext);

  if (!context)
    throw new Error('ChannelContext was used outside of the ChannelProvider');

  return context;
};
