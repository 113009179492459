import {
  useContext,
  createContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import {
  useAuth,
  useShoppingListCreate,
  useShoppingListLineBulkCreate,
} from 'teddly-sdk';
import { getTeddlySdkApi } from '@config/teddly-sdk';
import { ShoppingListsAndQuantityByVariantId_shoppingListsAndLineByVariantId } from 'teddly-sdk/lib/queries/gqlTypes/ShoppingListsAndQuantityByVariantId';
import { ProductEvents, sendConvertedEvent } from '@config/algolia/utils';
import { usePageLayoutContext } from '@context/PageLayoutContext';

interface ICreateList {
  status: boolean;
  message: string;
}

const ShoppingListsContext = createContext<{
  shoppingListsData: ShoppingListsAndQuantityByVariantId_shoppingListsAndLineByVariantId[];
  setShoppingListsData: (
    data: ShoppingListsAndQuantityByVariantId_shoppingListsAndLineByVariantId[],
  ) => void;
  ShoppingListsByVariantHandler: (variantId: string) => void;
  addList: (listName: string, quantity?: number) => void;
  isSuccess: ICreateList;
  setIsSuccess: (obj: ICreateList) => void;
  IsError: string;
  setIsError: (errorMessage: string) => void;
}>(null);

export function ShoppingListsProvider({ children }: { children: ReactNode }) {
  const [shoppingListLineBulkCreate, shoppingListLineBulkCreateOpt] =
    useShoppingListLineBulkCreate();
  const [shoppingListCreate, shoppingListCreateOpt] = useShoppingListCreate();
  const [shoppingListsData, setShoppingListsData] = useState<
    ShoppingListsAndQuantityByVariantId_shoppingListsAndLineByVariantId[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(
    !!shoppingListLineBulkCreateOpt?.loading ||
      !!shoppingListCreateOpt?.loading,
  );

  useEffect(() => {
    const loading =
      shoppingListLineBulkCreateOpt?.loading || shoppingListCreateOpt?.loading;
    if (loading !== isLoading) {
      setIsLoading(loading);
      const newLists = shoppingListsData?.map((list) => {
        const newList = {
          ...list,
          disabled: isLoading,
        };
        // (list as any)?.disabled =  isLoading;
        return newList;
        // (list as any)?.disabled =  isLoading
      });
      setShoppingListsData(newLists);
    }
  }, [shoppingListLineBulkCreateOpt?.loading, shoppingListCreateOpt?.loading]);

  const { queryId, resultsIndexName, currentProduct } = usePageLayoutContext();
  const { user, authenticated } = useAuth();
  const [variant, setVariant] = useState<string>();
  const [IsError, setIsError] = useState<string>();
  const [isSuccess, setIsSuccess] = useState({ status: false, message: '' });

  const addList = async (listName: string, quantity?: number) => {
    try {
      const { data }: any = await shoppingListCreate({
        input: {
          name: listName,
          lines: [{ variant: variant, quantity: quantity || 1 }],
        },
      });
      if (data?.shoppingListErrors?.length === 0)
        setIsSuccess({ status: true, message: 'List successfully created' });
      else throw data.shoppingListErrors[0];

      ShoppingListsByVariantHandler(variant);
    } catch (e) {
      setIsError(e);
    }
  };

  const ShoppingListsByVariantHandler = async (variantId: string) => {
    if (variant !== variantId) setVariant(variantId);
    const { teddlyApolloClient, teddlyApi } = await getTeddlySdkApi();
    try {
      if (authenticated) {
        const res =
          await teddlyApi.products.getShoppingListsAndQuantityByVariantId(
            variantId,
          );
        setShoppingListsData(getShoppingListsData(res?.data, variantId));
      }
    } catch (err) {}
  };

  const onChange = async (
    shoppingListId: string,
    variantId: string,
    value: number,
  ) => {
    await shoppingListLineBulkCreate({
      shoppingListId: shoppingListId,
      replace: true,
      lines: [{ variant: variantId, quantity: value }],
    });
    ShoppingListsByVariantHandler(variantId);
  };

  const getShoppingListsData = (data: any, variantId: string) => {
    const lists = data?.map((list) => ({
      id: list.shoppingList.id,
      name: list.shoppingList.name,
      value: list.line ? list.line.quantity : 0,
      disabled: isLoading,
      onChange: (value) => {
        if (value === 1 && !list.line) {
          sendConvertedEvent({
            objectID: currentProduct?.id,
            userId: user?.id,
            eventName: ProductEvents.ADDED_TO_SHOPPING_LIST,
            indexName: resultsIndexName,
            queryID: queryId,
          });
        }
        onChange(list.shoppingList.id, variantId, value);
      },
    }));
    return lists;
  };

  return (
    <ShoppingListsContext.Provider
      value={{
        shoppingListsData: shoppingListsData ? shoppingListsData : null,
        setShoppingListsData: setShoppingListsData,
        ShoppingListsByVariantHandler: ShoppingListsByVariantHandler,
        addList: addList,
        isSuccess: isSuccess,
        setIsSuccess: setIsSuccess,
        IsError: IsError,
        setIsError: setIsError,
      }}>
      {children}
    </ShoppingListsContext.Provider>
  );
}

export const useShoppingListsContext = () => {
  const context = useContext(ShoppingListsContext);

  if (!context)
    throw new Error(
      'ShoppingListsContext was used outside of the ShoppingListsProvider',
    );

  return context;
};
