import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import useDisableScroll from '@hooks/useDisableScroll';
import { Dialog, Button, InputBase } from '@teddly/teddly-ui-components';
import styles from '@styles/form.module.scss';

type Props = {
  isOpen?: boolean;
  text: string;
  loading: boolean;
  onClose?: () => void;
};

export default function AccountDeleteDialog({
  isOpen,
  text,
  loading,
  onClose,
}: Props): JSX.Element {
  useDisableScroll(isOpen);
  return (
    <Dialog
      isOpen={isOpen}
      title={'Delete Account'}
      onClose={onClose}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <div
        className="img"
        style={{ backgroundImage: `url(/assets/images/teddly.jpg)` }}
      />
      <div className="modal-content">
        <h1 style={{ margin: 0 }}>
          {loading ? 'Deleting Your Account...' : text}
        </h1>
      </div>
      <Dialog.Footer></Dialog.Footer>
    </Dialog>
  );
}
